import { AppStateContext } from "AppStateProvider";
import { i8nText } from "components/common/Util";
import React, { ChangeEvent, ReactElement, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Chord } from "types/common";
import { ChordFinderElement } from "./ChordFinderElement";

const StyledDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  .top-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;

    .input {
      display: inline-block;
      padding: 0px 4px;
      white-space: pre;

      label {
        margin-left: 0.5rem;
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;
        color: ${props => props.theme.fgColor1};
      }
      input {
        margin-left: 0.5rem;
        font-size: 0.75rem;
        border: none;
      }
    }
    .total {
      color: ${props => props.theme.fgColor1};
      display: block;
      font-size: 0.75rem;
      margin-left: 0.7rem;
      display: inline-block;
      white-space: pre;
    }
  }
  .chords {
    display: flex;
    height: 64px;
    @media (max-width: ${props => props.theme.bp.mini}) {
      height: 32px;
    }
  }
`;

export const ChordFinderByName = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const [searchChord, setSearchChord] = useState<string>("");
  const [filteredChords, setFilteredChords] = useState<Array<Chord>>([]);

  useEffect(() => {
    const chordFoundArray: Array<Chord> = [];
    if (searchChord === "") {
      setFilteredChords([]);
    } else {
      if (appContext.chordList !== null) {
        appContext.chordList.forEach((chord: Chord) => {
          if (chord.priority === 1 && chord.name.includes(searchChord)) {
            chordFoundArray.push(chord);
          }
        });

        setFilteredChords(chordFoundArray);
      }
    }
  }, [searchChord, appContext.chordList]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchChord(e.target.value);
  };

  return (
    <StyledDiv>
      <div className="top-row">
        <div className="input">
          <label htmlFor="search">{i8nText(appContext.language, "chordFinder.searchByName")}</label>
          <input
            type="text"
            name="search"
            id="search"
            size={8}
            required={false}
            //autoFocus={false}
            spellCheck={false}
            defaultValue={searchChord}
            onChange={onChangeHandler}
          />
        </div>
        <div className="total">
          {`${filteredChords.length} ${i8nText(appContext.language, "chordFinder.chordsFound")}`}
        </div>
      </div>
      <div className="chords">
        {filteredChords.map((chord, index) => (
          <ChordFinderElement
            key={index}
            chordName={chord.name}
            chordFileName={chord.fileName}
            chordNotes={chord.string}
            transposition={appContext.transposition}
          />
        ))}
      </div>
    </StyledDiv>
  );
};
