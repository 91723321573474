import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { Loader } from "components/common/Loader";
import { MessageBox } from "components/common/MessageBox";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import emojiNerd from "resources/emojis/nerd.svg";
import StayAwake from "stayawake.js";
import styled, { createGlobalStyle } from "styled-components";
import {
  FullSongInfo, URL_SEARCH_PARAM_PERFORMER,
  URL_SEARCH_PARAM_TITLE,
  URL_SEARCH_PARAM_USER_ID
} from "types/common";
import { BpmPlayer } from "./BpmPlayer";
import { Song } from "./Song";

const SelectSongMessageGlobalStyle = createGlobalStyle`
  #selectSongMessage{
    .messagebox{
      .select-song-message{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .messagebox-image{
          width: 6rem;
          height: 6rem;
          img{
            width: 6rem;
            height: 6rem;
            display:block;
          }
        }
        .messagebox-text{
          padding-left: 1rem;
        }
      }
    }
  }
`;

const StyledDiv = styled.div`
  position: relative;
  .messagebox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .messagebox-image {
      width: 6rem;
      height: 6rem;
      img {
        width: 6rem;
        height: 6rem;
        display: block;
      }
    }
    .messagebox-text {
      padding-left: 1rem;
    }
  }
`;

export const SongViewerPage = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [metronomeActive, setMetronomeActive] = useState(false);
  const [calculatingMaxTab, setCalculatingMaxTab] = useState(false);
  const [autoFitFinished, setAutoFitFinished] = useState(false);
  const [songData, setSongData] = useState<FullSongInfo>();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [stayAwakeEnabled, setStayAwakeEnabled] = useState(false);

  const title = new URLSearchParams(appContext.location.search).get(URL_SEARCH_PARAM_TITLE);
  const performer = new URLSearchParams(appContext.location.search).get(URL_SEARCH_PARAM_PERFORMER);
  const userId = new URLSearchParams(appContext.location.search).get(URL_SEARCH_PARAM_USER_ID);

  useEffect(() => {
    const enableStayAwake = () => {
      if (!stayAwakeEnabled) {
        StayAwake.init();
        StayAwake.enable();
        console.log("StayAwake enabled!");
        document.removeEventListener("click", enableStayAwake);
        setStayAwakeEnabled(true);
      }
    };

    if (!stayAwakeEnabled) {
      document.addEventListener("click", enableStayAwake);
    }
    return () => {
      document.removeEventListener("click", enableStayAwake);
    };
  }, [stayAwakeEnabled]);

  useEffect(() => {
    const saveSongIdInLocalStorage = (title: string, performer: string, userId: string) => {
      window.localStorage.setItem(Config.LOCALSTORAGE__LAST_LOADED_SONG__TITLE, title);
      window.localStorage.setItem(Config.LOCALSTORAGE__LAST_LOADED_SONG__PERFORMER, performer);
      window.localStorage.setItem(Config.LOCALSTORAGE__LAST_LOADED_SONG__USERID, userId);
    };

    if (!isNil(title) && !isNil(performer) && !isNil(userId)) {
      const url = `${Config.SONG_SERVICE_URL}?v=${Config.APP_VERSION}&performer=${encodeURIComponent(
        performer,
      )}&title=${encodeURIComponent(title)}&userId=${userId}`;

      setRequestInProgress(true);

      axios({
        url: url,
        method: "GET",
        timeout: Config.REQUEST_TIMEOUT,
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN),
        },
      })
        .then(res => {
          appContext.setTransposition(res.data.capo);
          if (title && performer && userId) {
            saveSongIdInLocalStorage(title, performer, userId);
          }
          setSongData(res.data);
        })
        .catch(error => {
          console.log("Request error response:", error.response);
          setServerError(true);
        })
        .finally(() => {
          setRequestInProgress(false);
        });
    } else {
      setServerError(true);
    }
    // eslint-disable-next-line
  }, [performer, title, userId]);

  return (
    <StyledDiv className="song-viewer-page">
      {requestInProgress ? (
        <Loader visible={true} />
      ) : (
        <>
          {serverError ? (
            <MessageBox id="selectSongMessage" visible={true}>
              <SelectSongMessageGlobalStyle />
              <div className="select-song-message">
                <div className="messagebox-image">
                  <img alt="info" src={emojiNerd} />
                </div>
                <div className="messagebox-text">{i8nText(appContext.language, "message.selectSongFromList")}</div>
              </div>
            </MessageBox>
          ) : (
            !isNil(songData) && (
              <>
                <Song
                  songData={songData}
                  setSongData={setSongData}
                  columns={appContext.numberOfColumnsInSong}
                  fontsize={appContext.viewerFontSize}
                  lineheight={appContext.viewerLineHeight}
                  letterspacing={appContext.viewerLetterSpacing}
                  calculatingMaxTab={calculatingMaxTab}
                  setCalculatingMaxTab={setCalculatingMaxTab}
                  autoFitFinished={autoFitFinished}
                  setAutoFitFinished={setAutoFitFinished}
                  autoFitEnabled={appContext.autoFitEnabled}
                />
                <BpmPlayer
                  metronomeSize={appContext.metronomeSize}
                  metronomeOpacity={appContext.metronomeOpacity}
                  active={metronomeActive}
                  setMetronomeActive={setMetronomeActive}
                  speed={songData && songData.bpm > 0 ? Math.round(60000 / songData.bpm) : 0}
                  duration={songData ? songData.duration : 0}
                />
              </>
            )
          )}
        </>
      )}
    </StyledDiv>
  );
};
