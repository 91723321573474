import React, { ReactElement, useContext } from "react";
import { playChord } from "components/common/Util";
import styled from "styled-components";
import cursorFingerSpeaker from "resources/cursors/cursor_finger_speaker.cur";
import { AppStateContext } from "AppStateProvider";
import { ChordString } from "types/common";

const StyledDiv = styled.div`
  cursor: pointer;
  cursor: url(${cursorFingerSpeaker}), auto;
  img {
    opacity: 0.9;
    display: block;
    width: 64px;
    height: 64px;

    @media (max-width: ${props => props.theme.bp.mini}) {
      width: 32px;
      height: 32px;
    }
  }
`;

type Props = {
  chordName: string;
  chordFileName: string;
  chordNotes: ChordString;
  transposition: number;
};

export const ChordFinderElement = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const onClickHandler = () => {
    if (appContext.soundEnabled) playChord(props.chordNotes, props.transposition);
  };

  return (
    <StyledDiv onClick={onClickHandler}>
      <img
        alt={props.chordFileName}
        src={`${process.env.PUBLIC_URL}/img/chords/${props.chordName[0]}/chord_tw_${props.chordFileName}.webp`}
      />
    </StyledDiv>
  );
};
