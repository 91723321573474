import { isNil } from "lodash";
import React, { ReactElement, useState } from "react";
//https://github.com/markusenglund/react-switch
import Switch from "react-switch";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

type Props = {
  setting: boolean;
  localStorageKey?: string;
  setSetting: (value: boolean) => void;
  text: string;
  disabled?: boolean;
};

const parseBoolean = (val: unknown): boolean | undefined => {
  if (val === true || val === "true") {
    return true;
  } else if (val === false || val === "false") {
    return false;
  }
  console.warn("SettingsSwitch.tsx ~ parseBoolean ~ Couldn't parse value: ", val);
  return undefined;
};

export const SettingsSwitch = (props: Props): ReactElement => {
  const [selectedOption, setSelectedOption] = useState<boolean>(parseBoolean(props.setting) ?? false);

  const onChangeHandler = (checked: boolean) => {
    const myBool = parseBoolean(checked);
    if (!isNil(props.localStorageKey) && !isNil(myBool)) {
      window.localStorage.setItem(props.localStorageKey, myBool.toString());
    }
    setSelectedOption(checked);
    props.setSetting(checked);
  };

  return (
    <StyledDiv>
      <label>
        <span>{props.text}</span>
        <Switch onChange={onChangeHandler} checked={selectedOption} disabled={props.disabled} />
      </label>
    </StyledDiv>
  );
};
