import React, { ReactElement, useState } from "react";
import fretboardImg from "resources/images/fretboard.webp";
import styled from "styled-components";
import { ChordString } from "types/common";
import { ChordFinder } from "./ChordFinder";
import { ChordFinderByName } from "./ChordFinderByName";
import { GuitarFretboardString } from "./GuitarFretboardString";
import { PlayChordButton } from "./PlayChordButton";

const StyledDiv = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${fretboardImg}),
    linear-gradient(
      to bottom,
      rgba(183, 222, 237, 1) 0%,
      rgba(113, 206, 239, 1) 50%,
      rgba(33, 180, 226, 1) 51%,
      rgba(183, 222, 237, 1) 100%
    );
  position: relative;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 10px;
  border: 1px solid darkgray;
  width: 100%;

  > img {
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }

  .table {
    position: absolute;
    left: 14.6%;
    top: 37.1%;
    width: 82%;
    height: 20.5%;
  }
`;

export const GuitarFretboard = (): ReactElement => {
  //0-12 pressed fret = ◉
  //-1 string = ❌ don't play this string
  //null no pressed fret in this string

  //const [stringState, setStringState] = useState([null,-1,0,1,2,3]);
  //const [stringState, setStringState] = useState([-1,-1,0,2,3,2]);
  const [stringState, setStringState] = useState<ChordString>([3, 2, 0, 0, 3, 3]);

  const changeStringStateHandler = (string: number, state: number | null) => {
    const newState = [];
    newState[0] = stringState[0];
    newState[1] = stringState[1];
    newState[2] = stringState[2];
    newState[3] = stringState[3];
    newState[4] = stringState[4];
    newState[5] = stringState[5];
    newState[5 - (string - 1)] = state;
    setStringState(newState);
  };

  const changeChordHandler = (chordArray: ChordString) => {
    const newState = [];
    newState[0] = chordArray[0];
    newState[1] = chordArray[1];
    newState[2] = chordArray[2];
    newState[3] = chordArray[3];
    newState[4] = chordArray[4];
    newState[5] = chordArray[5];
    setStringState(newState);
  };

  return (
    <StyledDiv className="guitar-fretboard">
      <img alt="fretboard" src={fretboardImg} />
      <div className="table">
        <GuitarFretboardString
          string={1}
          stringState={stringState[5]}
          changeStringStateHandler={changeStringStateHandler}
        />
        <GuitarFretboardString
          string={2}
          stringState={stringState[4]}
          changeStringStateHandler={changeStringStateHandler}
        />
        <GuitarFretboardString
          string={3}
          stringState={stringState[3]}
          changeStringStateHandler={changeStringStateHandler}
        />
        <GuitarFretboardString
          string={4}
          stringState={stringState[2]}
          changeStringStateHandler={changeStringStateHandler}
        />
        <GuitarFretboardString
          string={5}
          stringState={stringState[1]}
          changeStringStateHandler={changeStringStateHandler}
        />
        <GuitarFretboardString
          string={6}
          stringState={stringState[0]}
          changeStringStateHandler={changeStringStateHandler}
        />
      </div>
      <ChordFinder stringState={stringState} changeChordHandler={changeChordHandler} />
      <ChordFinderByName /* changeChordHandler={changeChordHandler} */ />
      <PlayChordButton chord={stringState} />
    </StyledDiv>
  );
};
