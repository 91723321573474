import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { ImagePreload } from "components/common/ImagePreload";
import { i8nText } from "components/common/Util";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useState } from "react";
import iconSpinner from "resources/icons/animated_spinner_white.svg";
import styled from "styled-components";

const SpinnerDiv = styled.div`
  width: 100%;
  height: 2.75rem;
  box-sizing: border-box;
  background-color: rgb(245, 245, 255);
  border-radius: 12px;
  position: relative;
  text-align: center;
  margin: .2rem;
  background-color: ${props => props.theme.bgColor2};
  color: ${props => props.theme.fgColor1};
  border: 2px solid ${props => props.theme.bgColor2};
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 2rem;
  }
`;

const StyledDiv = styled.div`
  color: ${props => props.theme.fgColor1};
  text-align: center;
  max-width: ${props => props.theme.bp.mini};
  margin: 0 auto;

  .form-wrapper{
    width: 100%;
    text-align: center;

    .body{
      form{
        margin: 0 auto;
        width: 18rem;
        max-width: 100%;

        input{
          display: block;
          width: 100%;
          padding: .7rem 0.5rem;
          box-sizing: border-box;
          background-color: rgb(245, 245, 255);
          border-radius: 12px;
          position: relative;
          text-align: center;
          margin: .2rem;
          min-height: initial;
          text-align: left;
          border: 2px solid lightgray;
          &:hover{
            border-color: gray;
          }
        }

        input[type="submit"]{
          text-align: center;
          background-color: ${props => props.theme.bgColor2};
          color: ${props => props.theme.fgColor1};
          font-weight: 700;
          font-size: 1rem;
          border: 2px solid ${props => props.theme.bgColor2};
          cursor: pointer;
          &:hover{
            color: ${props => props.theme.fgColor2};
          }
        }
      }
    }
  }
`;

export const ForgottenPasswordPage = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [email, setEmail] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(false);

  const changePassword = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.SEND_RESET_PASSWORD_EMAIL_SERVICE_URL}/${email}`,
      method: "GET",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => {
      console.log("changePassword -> res", res);
      setRequestInProgress(false);
      window.location.href = Config.PAGE_ROUTE__EMAILSENT;
    })
    .catch((error) => {
      console.log("Request error response:", error.response);
      setRequestInProgress(false);
    })
  }

  const handleEmailNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>)=> {
    event.preventDefault();
    if (!requestInProgress){
      changePassword();
    }
  }
  return (
    <StyledDiv>

      <ImagePreload src={iconSpinner}/>

      <h1>{i8nText(appContext.language, "login.forgottenPassword")}</h1>
      <p>{i8nText(appContext.language, "login.forgottenPasswordMessage")}</p>
      <p>{i8nText(appContext.language, "login.forgottenPasswordNote")}</p>

      <div className="form-wrapper">
        <div className="body">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              autoComplete="email"
              id="email"
              name="email"
              placeholder={i8nText(appContext.language, "login.enterEmail")}
              value={email}
              minLength={6}
              maxLength={40}
              required
              tabIndex={2}
              onChange={handleEmailNameChange}
              spellCheck={false}
            />

            {requestInProgress ? (
              <SpinnerDiv>
                <img alt="spinner" src={iconSpinner}/>
              </SpinnerDiv>
            ):(
              <input
                type="submit"
                value={i8nText(appContext.language, "login.sendEmail")}
                tabIndex={4}
              />
            )}
          </form>
        </div>
      </div>

    </StyledDiv>
  );
}