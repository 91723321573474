
// Web Share API
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share

import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import shareIcon from "resources/icons/icon_share.svg";
import styled from "styled-components";

const StyledDiv = styled.div`
  .share-button{
    cursor: pointer;
    .share-button-icon{
      mask: url(${shareIcon});
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      background: ${props => props.theme.fgColor1};
      mask-size: cover;
      &:hover{
        background: ${props => props.theme.fgColor2};
      }
    }
  }
`;

type Props = {
  performer: string;
  title: string;
  userId: number;
}

export const ShareButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const existsWebShareAPI = "share" in navigator ? true : false;

  const getSongURL = (performer: string, title: string, userId: number) => {
    return `${Config.PAGE_ROUTE__SONGVIEWER}?performer=${encodeURIComponent(performer)}&title=${encodeURIComponent(title)}&userid=${userId}`;
  }


  const onClickHandler = () => {
    if (navigator.share) {
      navigator.share({
        title: `${i8nText(appContext.language, "webShare.title")}`,
        text: `${i8nText(appContext.language, "webShare.text")}`,
        url: getSongURL(props.performer, props.title, props.userId)
      }).then(() => {
        console.log("Thanks for sharing!");
      })
      .catch(console.error);
    } else {
      //shareDialog.classList.add('is-open');
      console.log("Web Share API missing!");
    }
  }

  return (
    <StyledDiv>
      {!existsWebShareAPI ? "":(
        <div className="share-button" onClick={onClickHandler}>
          <div className="share-button-icon"></div>
        </div>
      )}
    </StyledDiv>
  )
}
