import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { playChord } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import icon from "resources/icons/icon_play_next_chord.svg";
import styled from "styled-components";
import { ChordString } from "types/common";

const StyledDiv = styled.div`
  display: inline-block;
  position: absolute;
  top: calc(50% - 20px);
  left: 20px;
  @media (max-width: ${props => props.theme.bp.mobile}){
    left: 0;
  }
`;

type Props = {
  chord: ChordString;
}

export const PlayChordButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const onClickHandler = () => {
    if (appContext.soundEnabled)
      playChord(props.chord, appContext.transposition);
  }

  return (
    <StyledDiv onClick={onClickHandler}>
      <ButtonIconText {...props} icon={icon} primary/>
    </StyledDiv>
  );
}
