import React, { ReactElement } from "react";
import imageNotebookSpiral from "resources/images/notebook-spiral-small-shadow.webp";
import styled from "styled-components";

const StyledDiv = styled.div`
  //28x64
  width: 100%;
  height: 64px;
  background-size: 28px 64px;
  background-repeat-y: no-repeat;
  background-repeat-x: repeat;
  background-image: url(${imageNotebookSpiral});
  background-position: left;
`;

export const NotebookSpiralSeparator = (): ReactElement => {
  return <StyledDiv />;
};
