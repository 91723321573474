import { AppStateContext } from "AppStateProvider";
import { getChord, i8nText, playChord } from "components/common/Util";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import cursorSpeaker from "resources/cursors/cursor_speaker.cur";
import styled from "styled-components";
import { Chord } from "types/common";

const StyledDiv = styled.div`
  width: 64px;
  height: 96px;
  position: relative;
  display: table-cell;

  img {
    cursor: url(${cursorSpeaker}), auto;
  }

  button {
    position: absolute;
    left: 12px;
    bottom: 15px;
    width: 41px;

    padding: 2px 0;
    border-width: 1px;
    border: 1px solid #333;
    text-align: center;
    cursor: pointer;
    border: none;
    font-size: 0.6rem;
    text-transform: uppercase;
  }
`;

type Props = {
  chordName: string;
  currentChord: string;
  tab: string;
  setAddChord: (val: boolean) => void;
  setCurrentChord: (chord: string) => void;
};

export const ChordButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [chord, setChord] = useState<Chord>();

  useEffect(() => {
    const myChord = getChord(props.chordName, appContext.chordList);
    if (myChord) setChord(myChord);
  }, [props.chordName, appContext.chordList]);

  const onClickImageHandler = (): void => {
    if (appContext.soundEnabled && chord) {
      playChord(chord.string, appContext.transposition);
    }
  };

  const onClickAddButtonHandler = () => {
    props.setCurrentChord(props.chordName);
    props.setAddChord(true);
  };

  return (
    <>
      {chord && (
        <StyledDiv>
          <img
            alt={chord.fileName}
            src={`${process.env.PUBLIC_URL}/img/chords/${chord.fileName[0]}/chord_tw_${chord.fileName}.webp`}
            onClick={onClickImageHandler}
          />
          <button type="button" onClick={onClickAddButtonHandler}>
            {i8nText(appContext.language, "chordsUsed.add")}
          </button>
        </StyledDiv>
      )}
    </>
  );
};
