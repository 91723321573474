import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import icon from "resources/icons/icon_new_song.svg";
import styled from "styled-components";
import { EditedSongWithSetters, EditorErrors, EditorErrorsWithSetters } from "types/common";

const StyledDiv = styled.div`
  display: inline-block;
`;

type Props = EditedSongWithSetters & EditorErrors & EditorErrorsWithSetters;

export const NewSongButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const onClickHandler = (/* e: MouseEvent<HTMLElement> */) => {
    props.setTitle("");
    props.setPerformer("");
    props.setTuning("E A D G B E");
    props.setCapo(0);
    appContext.setTransposition(0);
    props.setOriginalCapo(0);
    props.setComments("");
    props.setLinks("");
    props.setTab("");
    props.setBpm(0);
    props.setDuration(0);

    props.setTitleError(false);
    props.setPerformerError(false);
    props.setTuningError(false);
    props.setCapoError(false);
    props.setOriginalCapoError(false);
  };

  return (
    <StyledDiv onClick={onClickHandler}>
      <ButtonIconText
        {...props}
        text={i8nText(appContext.language, "editorButton.newSong")}
        icon={icon}
        primary
        fixed
      />
    </StyledDiv>
  );
};
