import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { ImagePreload } from "components/common/ImagePreload";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useState } from "react";
import heartEmpty from "resources/icons/heart_empty.svg";
import heartRed from "resources/icons/heart_red.svg";
import styled from "styled-components";
import { FavoriteRequest, HttpMethod, SongInfo, SongList } from "types/common";

const StyledDiv = styled.div<Props>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;

  background-image: url("${props => (props.songInfo.favorite ? heartRed : heartEmpty)}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: ${props => props.theme.bp.mobile}) {
    width: 20px;
    height: 26.6px;
  }
`;

type Props = {
  songInfo: SongInfo;
  setSongInfo?: (songInfo: SongInfo) => void;
  songList?: SongList;
  setSongList?: (songList: SongList) => void;
};

export const FavoriteSongButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const refreshSongList = (setAsFavorite: boolean) => {
    if (!isNil(props.songList) && !isNil(props.setSongList))
      for (let i = 0; i < props.songList.length; i++) {
        const element = props.songList[i];
        if (element.title === props.songInfo.title && element.performer === props.songInfo.performer) {
          if (setAsFavorite) {
            element.favorite = true;
          } else {
            element.favorite = false;
          }
          const newArr = [...props.songList.slice(0, i), element, ...props.songList.slice(i + 1)];
          props.setSongList(newArr);
          break;
        }
      }
  };

  const onClickHandler = () => {
    if (!requestInProgress) {
      setRequestInProgress(true);

      let method: HttpMethod;
      if (props.songInfo.favorite) {
        //If it's currently a favorite, delete it from the favorites table
        method = HttpMethod.DELETE;
      } else {
        //Add it to the favorites table
        method = HttpMethod.POST;
      }

      if (!isNil(appContext.user)) {
        const requestData: FavoriteRequest = {
          url: `${Config.FAVORITE_SERVICE_URL}`,
          method: method,
          timeout: Config.REQUEST_TIMEOUT,
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN) ?? undefined,
          },
          data: {
            userId: appContext.user?.id ?? 0,
            title: props.songInfo.title,
            performer: props.songInfo.performer,
            creatorId: props.songInfo.userId,
          },
        };

        axios(requestData)
          .then(() => {
            if (props.songList) {
              refreshSongList(method === "POST" ? true : false);
            }
            if (props.setSongInfo) {
              const newSong = { ...props.songInfo };
              newSong.favorite = method === "POST" ? true : false;
              props.setSongInfo(newSong);
            }
          })
          .catch(error => {
            console.log("Request error response:->", error.response);
          })
          .finally(() => {
            setRequestInProgress(false);
          });
      }
    }
  };

  return (
    <StyledDiv {...props} onClick={onClickHandler}>
      <ImagePreload src={heartRed} />
      <ImagePreload src={heartEmpty} />
    </StyledDiv>
  );
};
