import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { AboutPage } from "components/pages/about/AboutPage";
import { Homepage } from "components/pages/homepage/Homepage";
import { PageNotFound } from "components/pages/page-not-found/PageNotFound";
import { EmailSentPage } from "components/pages/reset-password/EmailSentPage";
import { ForgottenPasswordPage } from "components/pages/reset-password/ForgottenPasswordPage";
import { PasswordRestoredPage } from "components/pages/reset-password/PasswordRestoredPage";
import { ResetPasswordPage } from "components/pages/reset-password/ResetPasswordPage";
import { SongEditorPage } from "components/pages/song-editor/SongEditorPage";
import { SongListPage } from "components/pages/song-list/SongListPage";
import { SongViewerPage } from "components/pages/song-viewer/SongViewerPage";
import React, { ReactElement, useContext } from "react";
import homepageBgImg from "resources/images/maddie-leopardo-JF0Q4fYCQ3c-unsplash.webp";
import imageSongViewerBg from "resources/images/waethered-old-leather-texture-free.webp";
import styled from "styled-components";
import { SettingsPage } from "./pages/settings/SettingsPage";

const StyledDiv = styled.div<Props>`
  padding-top: 3rem;
  min-height: calc(100vh - 3rem);
  ${props => {
    switch(props.currentPage){
      case Config.PAGE_NAME__HOMEPAGE:
        // return `background-color: ${props.theme.bgColor1};`;
        return (`
          background-color: ${props.theme.bgColor1};
          background-image: url(${homepageBgImg});
          background-size: cover;
        `);
      case Config.PAGE_NAME__SONGLIST:
        return `background-color: ${props.theme.bgColor1};`;
      case Config.PAGE_NAME__SONGVIEWER:
        return (`
          background-color: ${props.theme.bgColor1};
          background-image: url(${imageSongViewerBg});
          background-size: auto;
        `);
      case Config.PAGE_NAME__SONGEDITOR:
        return `background-color: ${props.theme.bgColor3};`;
      default:
        return `background-color: ${props.theme.bgColor1};`;
    }
  }}
`;

type Props = {
  currentPage: string;
}

export const AppBody = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const getPageContent = () => {
    switch(appContext.page){
      case Config.PAGE_NAME__HOMEPAGE:
        return <Homepage/>;
      case Config.PAGE_NAME__SONGLIST:
        return <SongListPage/>;
      case Config.PAGE_NAME__SONGVIEWER:
        return <SongViewerPage/>;
      case Config.PAGE_NAME__SONGEDITOR:
        return <SongEditorPage/>;
      case Config.PAGE_NAME__ABOUT:
        return <AboutPage/>;
      case Config.PAGE_NAME__FORGOTTENPASSWORD:
        return <ForgottenPasswordPage/>;
      case Config.PAGE_NAME__RESETPASSWORD:
        return <ResetPasswordPage/>;
      case Config.PAGE_NAME__PASSWORDRESTORED:
        return <PasswordRestoredPage/>;
      case Config.PAGE_NAME__EMAILSENT:
        return <EmailSentPage/>;
      case Config.PAGE_NAME__SETTINGS:
        return <SettingsPage/>;
      case Config.PAGE_NAME__PAGENOTFOUND:
        return <PageNotFound/>;
      default:
        return <div>Page not found</div>;
    }
  }


  return(
    <StyledDiv currentPage={appContext.page ?? ""}>
      {getPageContent()}
    </StyledDiv>
  );
}