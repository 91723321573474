import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { ImagePreload } from "components/common/ImagePreload";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import heartEmpty from "resources/icons/heart_empty.svg";
import heartRed from "resources/icons/heart_red.svg";
import wastebinOpenIcon from "resources/icons/icon_waste_bin_open.svg";
import guitarImage from "resources/images/guitar-front-side.webp";
import styled from "styled-components";
import { SongList } from "types/common";
import { Loader } from "../../common/Loader";
import { SongsFilter } from "./SongsFilter";
import { SongsTable } from "./SongsTable";


const StyledDiv = styled.div`
  display: flex;
  height: calc(100vh - 3rem);
  color: ${props => props.theme.fgColor1};

  @media (max-width: ${props => props.theme.bp.mobile}) {
    display: block;
  }

  .left {
    background-image: url(${guitarImage});
    background-size: cover;
    width: 25%;
    max-width: 20rem;
    @media (max-width: ${props => props.theme.bp.mobile}) {
      display: none;
    }
  }
  .right {
    height: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* -webkit-box-shadow: 2px 0px 38px 66px rgba(24,27,31,1);
    -moz-box-shadow: 2px 0px 38px 66px rgba(24,27,31,1); */
    box-shadow: 2px 0px 38px 66px rgba(24, 27, 31, 1);
    .top {
      //height: 10rem;
      flex-grow: 0;
      position: relative;
      //z-index: 1;
    }
    .middle {
      //padding-left:2rem;
      flex-grow: 1;
      overflow: auto;
      padding-top: 1.2rem;

      @media (max-width: ${props => props.theme.bp.mobile}) {
        padding-top: 2.4rem;
      }
    }
    .bottom {
      //height: 10rem;
      /* -webkit-box-shadow: 0px -12px 14px 14px rgba(24,27,31,1);
      -moz-box-shadow: 0px -12px 14px 14px rgba(24,27,31,1); */
      box-shadow: 0px -12px 14px 14px rgba(24, 27, 31, 1);
      position: relative;
      z-index: 1;
    }
  }
`;

export const SongListPage = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [songList, setSongList] = useState<SongList>([]);
  const [loaderVisible, setLoaderVisible] = useState(true);
  const [serverError, setServerError] = useState(false);
  //const middleSectionRef = useRef<HTMLDivElement>();

  // // BEGIN Save/Restore table scroll position TODO!
  // useEffect(() => {
  //   if (localStorage.getItem(Config.LOCALSTORAGE_SONGLIST_SCROLLTOP) && !isNil(middleSectionRef)){
  //     middleSectionRef.current.scrollTop = localStorage.getItem(Config.LOCALSTORAGE_SONGLIST_SCROLLTOP);
  //   }
  // }, [songList]);

  // useEffect(() => {
  //   const current = middleSectionRef.current;
  //   return () => {
  //     localStorage.setItem(Config.LOCALSTORAGE_SONGLIST_SCROLLTOP, current.scrollTop);
  //   }
  // }, []);
  // // END Save/Restore table scroll position

  useEffect(() => {
    getSongList();
  }, [appContext.user, appContext.searchTerm, appContext.songListFilter]);

  const getSongList = () => {
    setLoaderVisible(true);

    let url = `${Config.SONGLIST_SERVICE_URL}?v=${Config.APP_VERSION}`;

    if (appContext.songListFilter === Config.SONGLIST_FILTER__MY_SONGS) {
      //TODO
      url = `${Config.SEARCH_SERVICE_URL}/mysongs?v=${Config.APP_VERSION}&${appContext.searchTerm}`;
    } else if (appContext.songListFilter === Config.SONGLIST_FILTER__MY_FAVORITES) {
      //TODO
      url = `${Config.SEARCH_SERVICE_URL}/myfavorites?v=${Config.APP_VERSION}&${appContext.searchTerm}`;
    }

    if (appContext.searchTerm !== "") {
      url = `${Config.SEARCH_SERVICE_URL}/bytitleorperformerword/${appContext.searchTerm}?v=${Config.APP_VERSION}`;
    }

    axios({
      url: url,
      method: "GET",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN),
      },
    })
      .then(res => {
        setSongList((res.data as SongList).sort((a, b) => a.performer.localeCompare(b.performer)));
        setServerError(false);
      })
      .catch(error => {
        console.log("Request error response:", error.response);
        setServerError(true);
      })
      .finally(() => {
        setLoaderVisible(false);
      });
  };

  return (
    <>
      <ImagePreload src={wastebinOpenIcon} />
      <ImagePreload src={heartEmpty} />
      <ImagePreload src={heartRed} />

      <StyledDiv>
        <div className="left">{/* Left */}</div>
        <div className="right">
          <div className="top">{appContext.user ? <SongsFilter /> : ""}</div>
          <div
            className="middle"
            // ref={middleSectionRef}
          >
            <SongsTable
              loading={loaderVisible}
              songList={songList}
              setSongList={setSongList}
              serverError={serverError}
            />
          </div>
          {/* <div className="bottom">
            Bottom Area
          </div> */}
        </div>
      </StyledDiv>
      <Loader visible={loaderVisible} />
    </>
  );
};
