/**
 * Usage: <Dialog>Question</Dialog>
 * props:
 *  {boolean} visible: if false, component will be undisplayed
 *  {*} closeButton: if present, a close button will be displayed
 *  {function} onClose: if present, on close the parameter function will be executed
 */

import React, { MouseEvent, ReactElement, ReactNode } from "react";
import { createGlobalStyle } from "styled-components";
import { ButtonIconText } from "./ButtonIconText";
import { MessageBox } from "./MessageBox";

const DialogGlobalStyle = createGlobalStyle`
  #modalDialog{
    .messagebox{
      width: 100%;
      .modal-dialog{
        .modal-dialog__title{
          text-align:center;
          /* font-size: 1.2rem;
          font-weight: 700; */
          margin-bottom: 2rem;
        }
        .modal-dialog__content{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          & > div{
            display: block;
            margin: .5rem;
          }
        }
      }
    }
  }
`
const onClickHandler = (e: MouseEvent, fn: () => void) => {
  fn();
}

type Props = {
  options: Record<string, () => void>;
  visible: boolean;
  children: ReactNode;
  onClose: () => void;
}

export const Dialog = (props:Props): ReactElement => {
  return (
    <MessageBox id="modalDialog" visible={props.visible} closeButton onClose={props.onClose}>
      <DialogGlobalStyle/>
      <div className="modal-dialog">
        <div className="modal-dialog__title">
          {props.children}
        </div>
        <div className="modal-dialog__content">
          {Object.keys(props.options).map((option, index) =>
            <ButtonIconText key={index} text={option} onClick={e => onClickHandler(e, Object.values(props.options)[index])}/>
          )}
        </div>
      </div>
    </MessageBox>
  )
}
