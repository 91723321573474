import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { ImagePreload } from "components/common/ImagePreload";
import { i8nText } from "components/common/Util";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useState } from "react";
import iconSpinner from "resources/icons/animated_spinner_white.svg";
import iconClose from "resources/icons/icon_close.webp";
import bgImage from "resources/images/guitars-bg.webp";
import logo from "resources/images/logo.svg";
import styled from "styled-components";
import { ButtonIconText } from "../common/ButtonIconText";

const SpinnerDiv = styled.div`
  width: 100%;
  height: 2.75rem;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  text-align: center;
  margin: 0.2rem;
  background-color: ${props => props.theme.bgColor2};
  color: ${props => props.theme.fgColor1};
  border: 2px solid ${props => props.theme.bgColor2};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 2rem;
  }
`;

const StyledDiv = styled.div`
  background-image: url(${bgImage});
  background-repeat: repeat;
  background-position: center;
  background-size: 20rem 20rem;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  opacity: 1;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  .close-button {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .form-wrapper {
    width: 25rem;
    max-width: 90%;
    height: 30rem;
    background-color: ${props => props.theme.fgColor2};
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 2rem;
    box-sizing: border-box;

    .header {
      .logo {
        width: 100%;
        text-align: center;

        img {
          width: 3rem;
          height: auto;
        }
      }
      .welcome-message {
        font-size: 1.5rem;
        text-align: center;
        color: ${props => props.theme.bgColor2};
      }
    }

    .body {
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 14rem;

        input {
          display: block;
          width: 100%;
          padding: 0.7rem 0.5rem;
          box-sizing: border-box;
          background-color: rgb(245, 245, 255);
          border-radius: 12px;
          position: relative;
          text-align: center;
          margin: 0.2rem;
          min-height: initial;
          text-align: left;
          border: 2px solid lightgray;
          &:hover {
            border-color: gray;
          }
        }

        input[error="true"] {
          border: 2px solid red;
        }

        input[type="submit"] {
          text-align: center;
          background-color: ${props => props.theme.bgColor2};
          color: ${props => props.theme.fgColor1};
          font-weight: 700;
          font-size: 1rem;
          border: 2px solid ${props => props.theme.bgColor2};
          cursor: pointer;
          &:hover {
            color: ${props => props.theme.fgColor2};
          }
        }
        a {
          font-size: 0.7rem;
          font-weight: 700;
          color: ${props => props.theme.bgColor2};
          text-decoration: underline;
          &:hover {
            color: ${props => props.theme.fgColor1};
          }
        }
      }
    }
    .footer {
      font-size: 0.7rem;
      font-weight: 700;
      div {
        text-decoration: underline;
        cursor: pointer;
        color: ${props => props.theme.bgColor2};
        &:hover {
          color: ${props => props.theme.fgColor1};
        }
      }
    }
  }
`;

type Props = {
  setSignInOptionClicked: (val: boolean) => void;
  setSignUpOptionClicked: (val: boolean) => void;
};

export const SignInForm = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signInError, setSignInError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const userSignIn = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.SIGNIN_SERVICE_URL}`,
      method: "POST",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        password: password,
      },
    })
      .then(res => {
        sessionStorage.setItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN, res.data.tokenType + " " + res.data.accessToken);
        sessionStorage.setItem(Config.LOCALSTORAGE_USER__JWT_DATA, JSON.stringify(res.data));
        appContext.setUser(res.data);
        props.setSignInOptionClicked(false);
        const redirectPages = [
          Config.PAGE_NAME__EMAILSENT,
          Config.PAGE_NAME__FORGOTTENPASSWORD,
          Config.PAGE_NAME__PASSWORDRESTORED,
          Config.PAGE_NAME__RESETPASSWORD,
        ];
        if (redirectPages.includes(appContext.page)) {
          window.location.href = Config.PAGE_ROUTE__HOMEPAGE;
        } else {
          setRequestInProgress(false);
        }
      })
      .catch(error => {
        console.log("Request error response:", error.response);
        setSignInError(true);
        setRequestInProgress(false);
      });
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (signInError) {
      setSignInError(false);
    }
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (signInError) {
      setSignInError(false);
    }
    setPassword(event.target.value);
  };
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!requestInProgress) {
      userSignIn();
    }
  };

  const handleClickCloseButton = () => {
    props.setSignInOptionClicked(false);
  };
  const switchToSignUp = () => {
    props.setSignInOptionClicked(false);
    props.setSignUpOptionClicked(true);
  };

  const getErrorMessage = () => {
    if (signInError) {
      return <div className="errorMessage">{i8nText(appContext.language, "login.loginFailed")}</div>;
    } else {
      return "";
    }
  };

  return (
    <StyledDiv>
      <ImagePreload src={iconSpinner} />

      <div className="close-button" onClick={handleClickCloseButton}>
        <ButtonIconText icon={iconClose} primary />
      </div>

      <div className="form-wrapper">
        <div className="header">
          <div className="logo">
            <img alt="logo" src={logo} />
          </div>

          <div className="welcome-message">
            {i8nText(appContext.language, "global.welcomeTo")} {i8nText(appContext.language, "global.appName")}
          </div>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              autoComplete="username"
              id="username"
              name="username"
              placeholder={i8nText(appContext.language, "login.enterUsername")}
              value={username}
              tabIndex={1}
              onChange={handleUserNameChange}
              spellCheck={false}
              minLength={3}
              maxLength={20}
              required
              data-error={signInError === true ? "true" : "false"}
            />
            <input
              type="password"
              autoComplete="password"
              id="password"
              name="password"
              placeholder={i8nText(appContext.language, "login.enterPassword")}
              value={password}
              minLength={6}
              maxLength={40}
              required
              tabIndex={2}
              onChange={handlePasswordChange}
              data-error={signInError === true ? "true" : "false"}
            />

            {requestInProgress ? (
              <SpinnerDiv>
                <img alt="spinner" src={iconSpinner} />
              </SpinnerDiv>
            ) : (
              <input type="submit" value={i8nText(appContext.language, "global.signIn")} tabIndex={3} />
            )}
            {getErrorMessage()}
            {/* <a tabIndex={4} href="/api/user/reset-password/email@email.com">{i8nText(appContext.language, "login.forgotPassword")}</a> */}
            <a href={`${Config.PAGE_ROUTE__FORGOTTENPASSWORD}?v=${Config.APP_VERSION}`}>
              {i8nText(appContext.language, "login.forgotPassword")}
            </a>
          </form>
        </div>

        <div className="footer">
          <div tabIndex={5} onClick={switchToSignUp}>
            {i8nText(appContext.language, "login.notAMember")}
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

/*
fetch SUCCESSFUL RESPONSE:
{
  "id": 2,
  "username": "pepe@gmail.com",
  "email": "pepe@gmail.com",
  "roles": [
    "ROLE_MODERATOR",
    "ROLE_USER"
  ],
  "tokenType": "Bearer",
  "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlQGdtYWlsLmNvbSIsImlhdCI6MTU4NzQ4NTMwNiwiZXhwIjoxNTg3NTcxNzA2fQ.QPGHcVJYg4Tzjp9FA6obXDJkvBct3W06nJvLUKPq1nZAq17kHFGSnstYU2BN5P-DluNqDAXPh30f8S28ZYw7gQ"
}

fetch ERROR RESPONSE:
{
  "timestamp": "2020-04-21T16:07:52.683+0000",
  "status": 401,
  "error": "Unauthorized",
  "message": "Error: Unauthorized",
  "path": "/api/auth/signin"
}
*/

// axios SUCCESSFUL RESPONSE
// {
//   "data": {
//     "id": 2,
//     "username": "pepe@gmail.com",
//     "email": "pepe@gmail.com",
//     "roles": [
//       "ROLE_USER",
//       "ROLE_MODERATOR"
//     ],
//     "tokenType": "Bearer",
//     "accessToken": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlQGdtYWlsLmNvbSIsImlhdCI6MTU4NzU1MzQ4MSwiZXhwIjoxNTg3NjM5ODgxfQ.XkM7hHGJSXJajCY_1hNLlaz_ALVRNa8JUopWyrsOlFXX6Q1NnWAJeaOuQN-5HUwkZ8MHCG_dI9rEQ0fg3XbJPg"
//   },
//   "status": 200,
//   "statusText": "",
//   "headers": {
//     "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
//     "content-type": "application/json",
//     "expires": "0",
//     "pragma": "no-cache"
//   },
//   "config": {
//     "url": "http://localhost:8080/api/auth/signin",
//     "method": "post",
//     "data": "{\"username\":\"pepe@gmail.com\",\"password\":\"passw0rd\"}",
//     "headers": {
//       "Accept": "application/json, text/plain, */*",
//       "Content-Type": "application/json;charset=utf-8"
//     },
//     "transformRequest": [
//       null
//     ],
//     "transformResponse": [
//       null
//     ],
//     "timeout": 4000,
//     "xsrfCookieName": "XSRF-TOKEN",
//     "xsrfHeaderName": "X-XSRF-TOKEN",
//     "maxContentLength": -1
//   },
//   "request": {}
// }
