/*
CHECK:
https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes

Usage: (escape = 27)
const  escapePressed = useKeyCodePress(27, onPressDown, onPressUp);
*/
import { useEffect, useState } from "react";

export const useKeyCodePress = (targetKeyCode: number, onPressDown = () => {}, onPressUp = () => {}): boolean => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    let prevKeyCode = 0;

    const downHandler = (keyEvent: KeyboardEvent) => {
      const { keyCode } = keyEvent;

      if (prevKeyCode === targetKeyCode) return;

      if (keyCode === targetKeyCode) {
        setKeyPressed(true);
        prevKeyCode = keyCode;
        onPressDown();
      }
    };

    const upHandler = (keyEvent: KeyboardEvent) => {
      const { keyCode } = keyEvent;

      if (keyCode === targetKeyCode) {
        setKeyPressed(false);
        prevKeyCode = 0;
        onPressUp();
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKeyCode]);

  return keyPressed;
};
