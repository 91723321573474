import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { getLineType, i8nText } from "components/common/Util";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import skinImg from "resources/images/black-skin.webp";
import styled from "styled-components";
import { ChordButton } from "./ChordButton";

const StyledDiv = styled.div`
    border-radius: 10px;
    border: 1px solid darkgray;
    overflow: auto;
    background-color: #c3c3c3;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${skinImg});
    background-position: bottom;

    &::-webkit-scrollbar-thumb {
      background: rgba(255,255,255,.8);
    }

    .title{
      margin-left: .5rem;
      margin-bottom: .5rem;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      color: ${props => props.theme.fgColor1};
    }
    .chords{
      //display: flex;
      height: 96px;
    }
`;

type Props = {
  currentChord: string,
  tab: string;
  setAddChord: (val: boolean) => void;
  setCurrentChord: (chord: string) => void;
}

export const MostUsedChordsSelector = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [usedChords, setUsedChords] = useState<Array<string>>([]);

  const getUsedChords = (tab:string):Array<string> => {
    const tabArray = tab.split("\n");
    const myMap = new Map();
    for (let i = 0; i < tabArray.length; i++) {
      const line = tabArray[i];
      const lineType = getLineType(line);
      if (lineType === Config.LINE_TYPE__CHORDS){
        const lineArray = line.split(" ");
        for (let j = 0; j < lineArray.length; j++) {
          const chord = lineArray[j];
          if (chord!=="" && "ABCDEFG".includes(chord[0])){
            if (myMap.has(chord)){
              myMap.set(chord, myMap.get(chord)+1);
            } else {
              myMap.set(chord, 1);
            }
          }
        }
      }
    }
    /*
    let usedChordsArray = [...myMap].sort(function(a,b){
      let va = a[1];
      let vb = b[1];
      if (va>vb) return -1
      else if (va<vb) return 1
      else return 0;
    });
    */
    const usedChordsArray = [...myMap];

    return usedChordsArray.map(el => el[0]);
  }

  useEffect(() => {
    setUsedChords(getUsedChords(props.tab));
  }, [props.tab]);

  return (
    <StyledDiv>
      <div className="title">{i8nText(appContext.language, "chordsUsed.chordsUsed")}</div>
      <div className="chords">
        {usedChords.map((chordName,index) => (
          <ChordButton key={index} chordName={chordName} {...props}/>
        ))}
      </div>
    </StyledDiv>
  );
}