import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useState } from "react";
import iconSpinner from "resources/icons/animated_spinner_white.svg";
import iconClose from "resources/icons/icon_close.webp";
import bgImage from "resources/images/guitars-bg.webp";
import logo from "resources/images/logo.svg";
import styled from "styled-components";
import { ButtonIconText } from "../common/ButtonIconText";
import { ImagePreload } from "../common/ImagePreload";

const SpinnerDiv = styled.div`
  width: 100%;
  height: 2.75rem;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  text-align: center;
  margin: 0.2rem;
  background-color: ${props => props.theme.bgColor2};
  color: ${props => props.theme.fgColor1};
  border: 2px solid ${props => props.theme.bgColor2};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 2rem;
  }
`;

const StyledDiv = styled.div`
  background-image: url(${bgImage});
  background-repeat: repeat;
  background-position: center;
  background-size: 20rem 20rem;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  opacity: 1;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  .close-button {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .form-wrapper {
    width: 25rem;
    max-width: 90%;
    min-height: 30rem;
    background-color: ${props => props.theme.fgColor2};
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 2rem;
    box-sizing: border-box;

    .header {
      .logo {
        width: 100%;
        text-align: center;

        img {
          width: 3rem;
          height: auto;
        }
      }
      .welcome-message {
        font-size: 1.5rem;
        text-align: center;
        color: ${props => props.theme.bgColor2};
      }
    }

    .body {
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 14rem;

        input {
          display: block;
          width: 100%;
          padding: 0.7rem 0.5rem;
          box-sizing: border-box;
          background-color: rgb(245, 245, 255);
          border-radius: 12px;
          position: relative;
          text-align: center;
          margin: 0.2rem;
          min-height: initial;
          text-align: left;
          border: 2px solid lightgray;

          &:hover {
            border-color: gray;
          }
        }

        input[error="true"] {
          border: 2px solid red;
        }

        input[type="submit"] {
          text-align: center;
          background-color: ${props => props.theme.bgColor2};
          color: ${props => props.theme.fgColor1};
          font-weight: 700;
          font-size: 1rem;
          border: 2px solid ${props => props.theme.bgColor2};
          cursor: pointer;
          &:hover {
            color: ${props => props.theme.fgColor2};
          }
        }
        a {
          font-size: 0.7rem;
          font-weight: 700;
          color: ${props => props.theme.bgColor2};
        }
        .errorMessage {
          color: red;
          font-size: 0.65rem;
          font-weight: 700;
          margin: 0;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
    .footer {
      font-size: 0.7rem;
      font-weight: 700;
      div {
        text-decoration: underline;
        cursor: pointer;
        color: ${props => props.theme.bgColor2};
        &:hover {
          color: ${props => props.theme.fgColor1};
        }
      }
    }
  }

  .message-wrapper {
    max-width: 35rem;
    width: 75%;
    padding: 2rem;
    background-color: ${props => props.theme.fgColor2};
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    text-align: center;
  }
`;

type Props = {
  setSignInOptionClicked: (val: boolean) => void;
  setSignUpOptionClicked: (val: boolean) => void;
};

export const SignUpForm = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [usernameErrors, setUsernameErrors] = useState<Array<string>>([]);
  const [emailErrors, setEmailErrors] = useState<Array<string>>([]);
  const [passwordErrors, setPasswordErrors] = useState<Array<string>>([]);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const getErrorMessage = (errorCode: number): string => {
    if (errorCode === 0) {
      return "";
    } else {
      return Config.API_RESPONSE_MESSAGE[errorCode];
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const userSignUp = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.SIGNUP_SERVICE_URL}`,
      method: "POST",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        email: email,
        password: password,
        /*role: ["mod","user"]*/
        role: ["user"],
      },
    })
      .then(() => {
        setShowSuccessMessage(true);
      })
      .catch(error => {
        console.log("Request error response:", error.response);
        if (!isNil(error.response.data.code)) {
          switch (error.response.data.code) {
            case 1:
              setUsernameErrors([getErrorMessage(error.response.data.code)]);
              break;
            case 2:
              setEmailErrors([getErrorMessage(error.response.data.code)]);
              break;
            default:
          }
        } else {
          const usernameErrors: Array<string> = [];
          const emailErrors: Array<string> = [];
          const passwordErrors: Array<string> = [];

          if (!isNil(error.response.data.errors) && error.response.data.errors.length > 0) {
            error.response.data.errors.map((error: { field: string; defaultMessage: string }) => {
              if (error.field === "username") {
                usernameErrors.push(capitalizeFirstLetter(error.defaultMessage));
              }
              if (error.field === "email") {
                emailErrors.push(capitalizeFirstLetter(error.defaultMessage));
              }
              if (error.field === "password") {
                passwordErrors.push(capitalizeFirstLetter(error.defaultMessage));
              }
              return null;
            });
          }
          setUsernameErrors(usernameErrors);
          setEmailErrors(emailErrors);
          setPasswordErrors(passwordErrors);
        }
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (usernameErrors.length > 0) {
      setUsernameErrors([]);
    }
    setUsername(event.target.value);
  };

  const handleEmailNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (emailErrors.length > 0) {
      setEmailErrors([]);
    }
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (passwordErrors.length > 0) {
      setPasswordErrors([]);
    }
    setPassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    userSignUp();
  };

  const handleClickCloseButton = () => {
    props.setSignUpOptionClicked(false);
  };

  const switchToSignIn = () => {
    props.setSignInOptionClicked(true);
    props.setSignUpOptionClicked(false);
  };

  const getContentToDisplay = () => {
    if (showSuccessMessage) {
      return (
        <>
          <div className="close-button" onClick={handleClickCloseButton}>
            <ButtonIconText icon={iconClose} primary />
          </div>
          <div className="message-wrapper">
            {i8nText(appContext.language, "login.hello")} {username},{" "}
            {i8nText(appContext.language, "login.welcomeNewUser")}
          </div>
        </>
      );
    } else {
      return (
        <>
          <ImagePreload src={iconSpinner} />

          <div className="close-button" onClick={handleClickCloseButton}>
            <ButtonIconText icon={iconClose} primary />
          </div>

          <div className="form-wrapper">
            <div className="header">
              <div className="logo">
                <img alt="logo" src={logo} />
              </div>

              <div className="welcome-message">
                {i8nText(appContext.language, "global.welcomeTo")} {i8nText(appContext.language, "global.appName")}
              </div>
            </div>

            <div className="body">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  autoComplete="username"
                  id="username"
                  name="username"
                  placeholder={i8nText(appContext.language, "login.enterUsername")}
                  value={username}
                  minLength={3}
                  maxLength={20}
                  required
                  tabIndex={1}
                  onChange={handleUserNameChange}
                  spellCheck={false}
                  data-error={usernameErrors.length > 0 ? "true" : "false"}
                />
                <div className="errorMessage">
                  {usernameErrors.map((error, i) => (
                    <div key={i}>{error}</div>
                  ))}
                </div>

                <input
                  type="email"
                  autoComplete="email"
                  id="email"
                  name="email"
                  placeholder={i8nText(appContext.language, "login.enterEmail")}
                  value={email}
                  minLength={6}
                  maxLength={40}
                  required
                  tabIndex={2}
                  onChange={handleEmailNameChange}
                  spellCheck={false}
                  data-error={emailErrors.length > 0 ? "true" : "false"}
                />
                <div className="errorMessage">
                  {emailErrors.map((error, i) => (
                    <div key={i}>{error}</div>
                  ))}
                </div>

                <input
                  type="password"
                  //autoComplete="password"
                  id="password"
                  name="password"
                  placeholder={i8nText(appContext.language, "login.enterPassword")}
                  value={password}
                  minLength={6}
                  maxLength={40}
                  required
                  tabIndex={3}
                  onChange={handlePasswordChange}
                  data-error={passwordErrors.length > 0 ? "true" : "false"}
                />
                <div className="errorMessage">
                  {passwordErrors.map((error, i) => (
                    <div key={i}>{error}</div>
                  ))}
                </div>

                {requestInProgress ? (
                  <SpinnerDiv>
                    <img alt="spinner" src={iconSpinner} />
                  </SpinnerDiv>
                ) : (
                  <input type="submit" value={i8nText(appContext.language, "global.signUp")} tabIndex={4} />
                )}
              </form>
            </div>

            <div className="footer">
              <div tabIndex={4} onClick={switchToSignIn}>
                {i8nText(appContext.language, "login.alreadyAMember")}
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return <StyledDiv>{getContentToDisplay()}</StyledDiv>;
};

// axios SUCESSFUL REGISTRATION RESPONSE
// {
//   "data": {
//     "message": "User registered successfully!"
//   },
//   "status": 200,
//   "statusText": "",
//   "headers": {
//     "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
//     "content-type": "application/json",
//     "expires": "0",
//     "pragma": "no-cache"
//   },
//   "config": {
//     "url": "http://localhost:8080/api/auth/signup",
//     "method": "post",
//     "data": "{\"username\":\"pablo@gmail.com\",\"email\":\"pablo@gmail.com\",\"password\":\"passw0rd\",\"role\":[\"mod\",\"user\"]}",
//     "headers": {
//       "Accept": "application/json, text/plain, */*",
//       "Content-Type": "application/json"
//     },
//     "transformRequest": [
//       null
//     ],
//     "transformResponse": [
//       null
//     ],
//     "timeout": 4000,
//     "xsrfCookieName": "XSRF-TOKEN",
//     "xsrfHeaderName": "X-XSRF-TOKEN",
//     "maxContentLength": -1
//   },
//   "request": {}
// }
