/**
 * Usage: <MessageBox>Content</MessageBox>
 * props:
 *  {boolean} visible: if false, component will be undisplayed
 *  {*} closeButton: if present, a close button will be displayed
 *  {function} onClose: if present, on close the parameter function will be executed
 */

import { isNil } from "lodash";
import React, { MouseEvent, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
//import { useKeyCodePress } from "components/common/useKeyCodePress";
//https://github.com/digital-flowers/react-animated-css
import { Animated } from "react-animated-css";
import styled from "styled-components";
import Portal from "./Portal";

const animationTime = 500;

const StyledDiv = styled.div<Props>`
  display: none;

  &.visible {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 1.5rem;
    left: 0;
    bottom: 0rem;
    right: 0;
    overflow: auto;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  .background {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    //backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all ${animationTime / 1000}s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }

  .foreground {
    position: absolute;
    opacity: 0;
    &.visible {
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .animated {
      max-width: 50%;
      min-width: 30%;
      @media (max-width: ${props => props.theme.bp.mobile}) {
        max-width: 90%;
      }
    }

    .messagebox {
      background-color: ${props => props.theme.fgColor2};
      border-radius: 10px;
      padding: 2rem 2rem;
      box-sizing: border-box;
      position: relative;
      box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.75);

      .close-button {
        position: absolute;
        top: -0.8rem;
        right: -0.8rem;
        width: 1.6rem;
        height: 1.6rem;
        background-color: ${props => props.theme.fgColor1};
        border-radius: 0.8rem;
        //border: 2px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: #ccc;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.7rem;
        }

        ${props => (props.closeButton ? "" : "display:none")};
      }
    }
  }
`;

type Props = {
  id?: string;
  visible?: boolean;
  closeButton?: boolean;
  onClose?: () => unknown;
  children?: ReactNode;
};

export const MessageBox = (props: Props): ReactElement => {
  let id;
  if (isNil(props.id)) {
    id = "messageBoxPortal_" + Date.now();
  } else {
    id = "messageBoxPortal_" + props.id;
  }
  const [divVisible, setDivVisible] = useState(false);
  const [isHiding, setIsHiding] = useState(false);
  const bg = useRef<HTMLDivElement>(null);
  const fg = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.visible) {
      setDivVisible(true);
      bg.current?.classList.add("visible");
      fg.current?.classList.add("visible");
    }
  }, [props.visible]);

  const onClickHandler = () => {
    if (props.closeButton) {
      if (!isHiding) {
        setIsHiding(true);
        setDivVisible(false);
        bg.current?.classList.remove("visible");
        setTimeout(function () {
          fg.current?.classList.remove("visible");
          if (props.onClose) props.onClose();
          setIsHiding(false);
        }, animationTime);
      }
    }
  };

  const stopEventPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };
  // const escKeyPressed = useKeyCodePress(27, onEscHandler);

  return props.visible ? (
    <Portal id={id}>
      <StyledDiv {...props} className={props.visible ? "visible" : ""}>
        <div ref={bg} className="background"></div>
        <div ref={fg} className="foreground" onClick={onClickHandler}>
          <Animated
            animationIn="bounceIn"
            animationOut="bounceOutDown"
            animationInDuration={animationTime}
            animationOutDuration={animationTime}
            isVisible={divVisible}
          >
            <div className="messagebox" onClick={stopEventPropagation}>
              <div className="close-button" onClick={onClickHandler}>
                <span>&#9587;</span>
              </div>
              {props.children}
            </div>
          </Animated>
        </div>
      </StyledDiv>
    </Portal>
  ) : (
    <></>
  );
};
