import {
  getCursorColumn,
  getCursorRow
} from "components/common/Util";
import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
    width: 100%;
    font-size: .6rem;
    display: inline-block;
    color: gray;
    text-transform: uppercase;
    margin-bottom: .8rem;

    .position{
        display: none;
    }
    .row{
        display: inline;
    }
    .column{
        display: inline;
    }
`;

type Props = {
  currentCursorPositionInTab: number;
  tab: string;
}

export const CursorPositionInTab = (props: Props): ReactElement => {
  const column = getCursorColumn(props.tab, props.currentCursorPositionInTab);
  const row = getCursorRow(props.tab, props.currentCursorPositionInTab);

  return (
    <StyledDiv>
      <span className="position">Pos:{props.currentCursorPositionInTab} -- </span>
      <span className="row">Ln: {row} </span>
      <span className="column">Col: {column}</span>
    </StyledDiv>
  );
}
