import React, { MouseEvent, ReactElement } from "react";
import styled from "styled-components";

const Button = styled.div<Props>`
  display: inline-block;
  cursor: pointer;
  user-select: none;
  padding: ${props => props.icon ? "0":".6rem"} 1rem ${props => props.icon ? "0":".6rem"} .625rem;
  padding: ${props => {if (props.icon && !props.text) return "0"}};
  padding: ${props => {if (!props.icon && props.text) return "0 1rem 0 1rem"}};

  overflow: hidden;
  margin: 4px 4px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  background: ${props => props.primary ? props => props.theme.bgColor2 : "linear-gradient(rgb(255, 255, 255) 0%, rgb(250, 250, 250) 100%)"};
  color: ${props => props.primary ? props.theme.fgColor2 : props.theme.bgColor2};
  transition: box-shadow 200ms ease-in-out 0s, background-color 200ms ease-in-out 0s, color 200ms ease-in-out 0s;

  &:hover{
    box-shadow: ${props => props.primary ? "rgba(13, 14, 17, 0.3) 0px 0px 0px 4px":"rgba(13, 14, 17, 0.1) 0px 0px 0px 4px"};
    background: ${props => props.primary ? "rgb(21, 23, 27)":"linear-gradient(rgb(250, 250, 250) 0%, rgb(245, 245, 245) 100%)"};
  }

  &:active{
    ${props => props.primary ? "filter: brightness(120%);":"filter: brightness(120%);"};
  }

  .button-icon-text{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .icon{
      background-size: cover;
      width: ${props => props.icon ? "2.5rem":"0"};
      height: 2.5rem;
      ${props => props.icon ? `background-image: url(${props.icon})`:""}
    }
    .text{
    }
  }

  ${props => {
    let css = "";
    const posNormal = props.invert ? "right":"left";
    const posHover  = props.invert ? "left":"right";
    css = `
      .button-icon-text .icon{
        background-position: ${posNormal};
      }
    `;

    if (!props.fixed){
      css = css + `
        &:hover .button-icon-text .icon{
          background-position: ${posHover};
        }
      `;
    }
    return css;
  }}
`;

type Props = {
  icon?: string;
  primary?: boolean;
  text?: string;
  invert?: boolean;
  fixed?: boolean;
  onClick?: (e: MouseEvent, option?: string) => void;
  className?: string;
}

export const ButtonIconText = (props: Props): ReactElement => {
  return (
    <Button {...props}>
      <div className="button-icon-text">
        <div className="icon"></div>
        <div className="text">{props.text}</div>
      </div>
    </Button>
  );
}
