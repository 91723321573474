import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledImg = styled.img`
  position: fixed;
  width: 1px;
  height: 1px;
  bottom: 0;
  right: 0;
  display: block;
  opacity: 0.001;
`;

type Props = {
  src: string;
};
export const ImagePreload = (props: Props): ReactElement => <StyledImg alt="preload" src={props.src} />;
