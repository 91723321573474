import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import iconViewer from "resources/icons/eye.svg";
import iconLens from "resources/icons/icon_magnifier2.svg";
import iconAbout from "resources/icons/info.svg";
import iconSongList from "resources/icons/music_note.svg";
import iconEditor from "resources/icons/pencil.svg";
import styled from "styled-components";
import { ButtonIconText } from "../common/ButtonIconText";
import { DeviceSelectorDiv } from "../common/DeviceSelectorDiv";

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  user-select: none;
  flex-wrap: nowrap;
  min-width: 9rem;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    user-select: none;

    li {
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      color: lightgrey;
      margin-right: 1rem;
      border-right: 1px solid ${props => props.theme.fgColor1};
      cursor: pointer;
      user-select: none;
      transition: all 0.3s ease-out;

      @media (max-width: ${props => props.theme.bp.mobile}) {
        margin-right: 0;
        border-right: none;
      }

      a {
        color: lightgrey;
        transition: all 0.3s ease-out;
        &:hover {
          color: ${props => props.theme.fgColor1};
        }
      }

      .text {
        display: block;
        @media (max-width: ${props => props.theme.bp.mobile}) {
          display: none;
        }
      }
      .button {
        display: none;
        @media (max-width: ${props => props.theme.bp.mobile}) {
          display: block;
        }
      }
    }
  }
`;

type Props = {
  bottomRowVisible: boolean;
  setBottomRowVisible: (bottomRowVisible: boolean) => void;
};

export const HeaderNav = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const getLastViewerSongURL = () => {
    const title = window.localStorage.getItem(Config.LOCALSTORAGE__LAST_LOADED_SONG__TITLE);
    const performer = window.localStorage.getItem(Config.LOCALSTORAGE__LAST_LOADED_SONG__PERFORMER);
    const userId = window.localStorage.getItem(Config.LOCALSTORAGE__LAST_LOADED_SONG__USERID);

    let path = `${Config.PAGE_ROUTE__SONGVIEWER}?v=${Config.APP_VERSION}`;

    if (title && performer && userId) {
      path += `&performer=${encodeURIComponent(performer)}&title=${encodeURIComponent(title)}&userid=${userId}`;
    }
    return path;
  };

  return (
    <StyledNav>
      <ul>
        <li>
          <a href={`${Config.PAGE_ROUTE__HOMEPAGE}?v=${Config.APP_VERSION}`}>
            <span className="text">{i8nText(appContext.language, "header.home")}</span>
          </a>
        </li>

        <li>
          <a href={`${Config.PAGE_ROUTE__SONGLIST}?v=${Config.APP_VERSION}`}>
            <span className="text">{i8nText(appContext.language, "header.songList")}</span>
            <ButtonIconText className="button" primary icon={iconSongList} onClick={() => {}} />
          </a>
        </li>

        <li>
          <a href={getLastViewerSongURL()}>
            <span className="text">{i8nText(appContext.language, "header.songViewer")}</span>
            <ButtonIconText className="button" primary icon={iconViewer} onClick={() => {}} />
          </a>
        </li>

        <li>
          <a href={`${Config.PAGE_ROUTE__SONGEDITOR}?v=${Config.APP_VERSION}`}>
            <span className="text">{i8nText(appContext.language, "header.songEditor")}</span>
            <ButtonIconText className="button" primary icon={iconEditor} onClick={() => {}} />
          </a>
        </li>

        <li>
          <DeviceSelectorDiv>
            <div className="desktop">
              <a href={`${Config.PAGE_ROUTE__ABOUT}?v=${Config.APP_VERSION}`}>
                <span className="text">{i8nText(appContext.language, "header.about")}</span>
                <ButtonIconText className="button" primary icon={iconAbout} onClick={() => {}} />
              </a>
            </div>
            <div className="mobile">
              <ButtonIconText
                className="button"
                primary
                icon={iconLens}
                onClick={() => {
                  props.setBottomRowVisible(!props.bottomRowVisible);
                }}
              />
            </div>
          </DeviceSelectorDiv>
        </li>
      </ul>
    </StyledNav>
  );
};
