import React, { ReactElement } from "react";
// import imagePicks from 'resources/images/deco-picks-pencil.webp';
// import imageJack from 'resources/images/deco-jack.webp';
import styled from "styled-components";

const StyledDiv = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  img{
    width: 99%;
    height: auto;
    display: block;
  }
`;

export const BottomDecoration = (): ReactElement => {
  return (
    <StyledDiv>
      <div className="left">
        {/* <img alt="decor left" src={imageJack}/> */}
      </div>
      <div className="center">
        {/* <img alt="decor center" src={imagePicks}/> */}
      </div>
      <div className="right">
        {/* <img alt="decor right" src={imagePicks}/> */}
      </div>
    </StyledDiv>
  );
}