import React, { ReactElement } from "react";
import imageNotebookBottom from "resources/images/notebook-bottom.webp";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  height: 15px;
  background-size: cover;
  background-repeat-y: no-repeat;
  background-repeat-x: no-repeat;
  background-image: url(${imageNotebookBottom});
  background-position: left;
`;

export const NotebookBottomSeparator = (): ReactElement => {
  return (
    <StyledDiv/>
  );
}