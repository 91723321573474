import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import emojiGoofy from "resources/emojis/goofy.svg";
import emojiShocked from "resources/emojis/shocked.svg";
import icon from "resources/icons/icon_save_song.svg";
import styled, { createGlobalStyle } from "styled-components";
import { EditedSongWithSetters, EditorErrors, EditorErrorsWithSetters } from "types/common";
import { MessageBox } from "../../common/MessageBox";

const SaveButtonMessageGlobalStyle = createGlobalStyle`
  #saveSongMessage{
    .messagebox{
      .save-button-message{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .messagebox-image{
          width: 6rem;
          height: 6rem;
          img{
            width: 6rem;
            height: 6rem;
            display:block;
          }
        }
        .messagebox-text{
          padding-left: 1rem;
        }
      }
    }
  }
`;

const StyledDiv = styled.div`
  display: inline-block;
`;

type Props = EditedSongWithSetters & EditorErrors & EditorErrorsWithSetters;

export const SaveSongButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const [saveOK, setSaveOK] = useState(false);
  const [saveKO, setSaveKO] = useState(false);
  const [registerMessageVisible, setRegisterMessageVisible] = useState(false);
  const [saveErrorMessageVisible, setSaveErrorMessageVisible] = useState(false);

  useEffect(() => {
    if (saveOK) {
      const timer = setTimeout(() => {
        setSaveOK(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saveOK]);

  useEffect(() => {
    if (saveKO) {
      console.log("Entra al timeout");
      const timer = setTimeout(() => {
        console.log("This will run after 2 second! -> SaveKO = false");
        setSaveKO(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saveKO]);

  const validateFields = () => {
    let errors = 0;
    if (props.title.trim() === "") {
      props.setTitleError(true);
      errors++;
    }
    if (props.performer.trim() === "") {
      props.setPerformerError(true);
      errors++;
    }
    if (props.tuning.trim() === "") {
      props.setTuningError(true);
      errors++;
    }
    if (props.capo.toString() === "") {
      props.setCapoError(true);
      errors++;
    }
    if (props.originalCapo.toString() === "") {
      props.setOriginalCapoError(true);
      errors++;
    }

    if (errors > 0) return false;

    return true;
  };

  const saveSong = () => {
    const fieldsOK = validateFields();
    if (isNil(appContext.user)) {
      setRegisterMessageVisible(true);
    } else if (!fieldsOK) {
      console.log("saveSong -> validateFields failed!");
    } else {
      const title = props.title.trim().toLowerCase();
      const performer = props.performer.trim().toLowerCase();

      axios({
        url: `${Config.SONGLIST_SERVICE_URL}`,
        method: "POST",
        timeout: Config.REQUEST_TIMEOUT,
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN),
        },
        data: {
          title: title,
          performer: performer,
          tuning: props.tuning.trim(),
          capo: props.capo,
          originalCapo: props.originalCapo,
          // eslint-disable-next-line
          comments: props.comments == "null" ? "" : props.comments.trim(),
          tab: props.tab,
          duration: props.duration,
          bpm: props.bpm,
          // eslint-disable-next-line
          links: props.links == "null" ? "" : props.links.trim(),
        },
      })
        .then(res => {
          console.log("res", res);
          console.log("Success!");
          setSaveOK(true);
        })
        .catch(error => {
          console.log("error", error);
          console.log("error.response", error.response);
          setSaveKO(true);
          setSaveErrorMessageVisible(true);
        });
    }
  };

  let invert = {};
  if (saveOK) {
    invert = { invert: "true" };
  } else if (saveKO) {
    //TODO Show modal error message
  }

  return (
    <StyledDiv>
      <ButtonIconText
        {...props}
        {...invert}
        text={i8nText(appContext.language, "editorButton.saveSong")}
        icon={icon}
        primary
        fixed
        onClick={saveSong}
      />
      <MessageBox
        id="saveSongMessage"
        visible={saveErrorMessageVisible}
        closeButton
        onClose={() => setSaveErrorMessageVisible(false)}
      >
        <SaveButtonMessageGlobalStyle />
        <div className="save-button-message">
          <div className="messagebox-image">
            <img alt="error" src={emojiShocked} />
          </div>
          <div className="messagebox-text">{i8nText(appContext.language, "message.saveError")}</div>
        </div>
      </MessageBox>

      {isNil(appContext.user) ? (
        <MessageBox
          id="saveSongMessage"
          visible={registerMessageVisible}
          closeButton
          onClose={() => setRegisterMessageVisible(false)}
        >
          <SaveButtonMessageGlobalStyle />
          <div className="save-button-message">
            <div className="messagebox-image">
              <img alt="error" src={emojiGoofy} />
            </div>
            <div className="messagebox-text">{i8nText(appContext.language, "message.saveOnlyForRegisteredUsers")}</div>
          </div>
        </MessageBox>
      ) : (
        ""
      )}
    </StyledDiv>
  );
};

// SUCCESS
// {
//   "url": "http://localhost:8080/api/songs",
//   "method": "post",
//   "data": "{\"title\":\"aaa\",\"performer\":\"aaa\",\"tuning\":\"E A D G B E\",\"capo\":\"2\",\"comments\":\"aaa\",\"tab\":\"Esto es una prueba\"}",
//   "headers": {
//     "Accept": "application/json, text/plain, */*",
//     "Content-Type": "application/json",
//     "Authorization": "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwZXBlQGdtYWlsLmNvbSIsImlhdCI6MTU4Nzk3ODM4NCwiZXhwIjoxNTg4MDY0Nzg0fQ.RQCJfUpgIreMF2aJNKv0iOWaXeTglP5rZVtKCBkZBhpeo8fowwuLNMO7pu0X7lmViCjyZjsfm-SiY0wiFH9Srw"
//   },
//   "transformRequest": [
//     null
//   ],
//   "transformResponse": [
//     null
//   ],
//   "timeout": 4000,
//   "xsrfCookieName": "XSRF-TOKEN",
//   "xsrfHeaderName": "X-XSRF-TOKEN",
//   "maxContentLength": -1
// }

// ERROR
// {
//   "data": {
//     "timestamp": "2020-04-27T09:22:46.172+0000",
//     "status": 401,
//     "error": "Unauthorized",
//     "message": "Error: Unauthorized",
//     "path": "/api/songs"
//   },
//   "status": 401,
//   "statusText": "",
//   "headers": {
//     "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
//     "content-type": "application/json",
//     "expires": "0",
//     "pragma": "no-cache"
//   },
//   "config": {
//     "url": "http://localhost:8080/api/songs",
//     "method": "post",
//     "data": "{\"title\":\"aaa\",\"performer\":\"aaa\",\"tuning\":\"E A D G B E\",\"capo\":\"2\",\"comments\":\"aaa\",\"tab\":\"Esto es una prueba\"}",
//     "headers": {
//       "Accept": "application/json, text/plain, */*",
//       "Content-Type": "application/json"
//     },
//     "transformRequest": [
//       null
//     ],
//     "transformResponse": [
//       null
//     ],
//     "timeout": 4000,
//     "xsrfCookieName": "XSRF-TOKEN",
//     "xsrfHeaderName": "X-XSRF-TOKEN",
//     "maxContentLength": -1
//   },
//   "request": {}
// }
