import React, { ReactElement } from "react";
import { EditedSongWithSetters, EditorErrors, EditorErrorsWithSetters } from "types/common";
import { NewSongButton } from "./NewSongButton";
import { RemoveBlankLinesButton } from "./RemoveBlankLinesButton";
import { SaveSongButton } from "./SaveSongButton";

type Props = EditedSongWithSetters & EditorErrors & EditorErrorsWithSetters;

export const SongEditorToolbar = (props: Props): ReactElement => {
  return (
    <div className="song-editor-toolbar">
      <SaveSongButton {...props}/>
      <NewSongButton {...props}/>
      <RemoveBlankLinesButton {...props}/>
    </div>
  );
}
