import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ChordUtilityComponent } from "./ChordUtilityComponent";
import { CursorPositionInTab } from "./CursorPositionInTab";
import { SongEditorToolbar } from "./SongEditorToolbar";
import { SongHeaderEditor } from "./SongHeaderEditor";
import { SongTabEditor } from "./SongTabEditor";

const StyledDiv = styled.div`
  width: calc(100% - 1.6rem);
  margin: 0 auto;
  padding-top: .8rem;
  height: calc(100vh - 4.6rem);
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;

  @media (max-width: ${props => props.theme.bp.mobile}){
    flex-wrap: wrap;
    height: auto;
  }

  > .column{
    width: calc(50% - .4rem);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.one{
      margin-right:.8rem;
    }

    &.two{
      max-width: 48rem;
    }

    @media (max-width: ${props => props.theme.bp.mobile}){
      width: 100%;
      &.one{
        flex-grow: 1;
        margin-right:0;
      }
      &.two{
        max-width: 100%;
      }

    }

    .center{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .song-tab-editor{
        flex-grow: 1;
      }
    }
  }
`;

export const SongEditorPage = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const initialStateTitle = () => window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TITLE) || "";
  const initialStatePerformer = () => window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_PERFORMER) || "";
  const initialStateTuning = () => window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TUNING) || "E A D G B E";
  const initialStateCapo = () => Number(window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_CAPO)) || 0;
  const initialStateOriginalCapo = () => Number(window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_ORIGINAL_CAPO)) || 0;
  const initialStateComments = () => window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_COMMENTS) || "";
  const initialStateDuration = () => Number(window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_DURATION)) || 0;
  const initialStateBpm = () => Number(window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_BPM)) || 0;
  const initialStateLinks = () => window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_LINKS) || "";
  const initialStateTab = () => window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TAB) || "";
  const initialStateCursorPosition = () => Number(window.localStorage.getItem(Config.LOCALSTORAGE_ITEM_NAME__CURSOR_POSITION)) || 0;
  const initialStateAddChord = false;

  const [title, setTitle] = useState(initialStateTitle);
  const [performer, setPerformer] = useState(initialStatePerformer);
  const [tuning, setTuning] = useState(initialStateTuning);
  const [capo, setCapo] = useState(initialStateCapo);
  const [originalCapo, setOriginalCapo] = useState(initialStateOriginalCapo);
  const [comments, setComments] = useState(initialStateComments);
  const [duration, setDuration] = useState(initialStateDuration);
  const [bpm, setBpm] = useState(initialStateBpm);
  const [links, setLinks] = useState(initialStateLinks);
  const [tab, setTab] = useState(initialStateTab);
  const [currentCursorPositionInTab, setCurrentCursorPositionInTab]  = useState(initialStateCursorPosition);
  const [addChord, setAddChord]  = useState(initialStateAddChord);

  const [titleError, setTitleError] = useState(false);
  const [performerError, setPerformerError] = useState(false);
  const [tuningError, setTuningError] = useState(false);
  const [capoError, setCapoError] = useState(false);
  const [originalCapoError, setOriginalCapoError] = useState(false);

  //TODO
  const [currentChord, setCurrentChord]  = useState("C#m");

  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TITLE, title),[title]);
  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_PERFORMER, performer),[performer]);
  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TUNING, tuning),[tuning]);
  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_DURATION, duration.toString()),[duration, appContext]);
  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_BPM, bpm.toString()),[bpm, appContext]);
  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_COMMENTS, comments),[comments]);
  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_LINKS, links),[links]);

  useEffect(() => {
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_CAPO, capo.toString());
    appContext.setTransposition(capo);
  },[capo, appContext]);
  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_ORIGINAL_CAPO, originalCapo.toString()),[originalCapo]);

  useEffect(() => {
    //console.log(Date.now() + " Updating tab...");
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TAB, tab);
    //textareaElement.current.value = tab;
    //TODO setCursorPosition
  },[tab]);

  useEffect(() => window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__CURSOR_POSITION, currentCursorPositionInTab.toString()),[currentCursorPositionInTab]);

  return (
    <StyledDiv className="song-editor-page">
      <div className="column one">
        <div className="top">
          <SongHeaderEditor
            title={title}
            setTitle={setTitle}
            performer={performer}
            setPerformer={setPerformer}
            tuning={tuning}
            setTuning={setTuning}
            capo={capo}
            setCapo={setCapo}
            originalCapo={originalCapo}
            setOriginalCapo={setOriginalCapo}
            comments={comments}
            setComments={setComments}
            duration={duration}
            setDuration={setDuration}
            bpm={bpm}
            setBpm={setBpm}
            links={links}
            setLinks={setLinks}
            tab={tab}
            setTab={setTab}

            titleError={titleError}
            performerError={performerError}
            tuningError={tuningError}
            capoError={capoError}
            originalCapoError={originalCapoError}

            setTitleError={setTitleError}
            setPerformerError={setPerformerError}
            setTuningError={setTuningError}
            setCapoError={setCapoError}
            setOriginalCapoError={setOriginalCapoError}
          />
        </div>
        <div className="center">
          <SongTabEditor
            tab={tab}
            setTab={setTab}
            currentChord={currentChord}
            setCurrentChord={setCurrentChord}
            currentCursorPositionInTab={currentCursorPositionInTab}
            setCurrentCursorPositionInTab={setCurrentCursorPositionInTab}
            addChord={addChord}
            setAddChord={setAddChord}
          />
          <CursorPositionInTab
            currentCursorPositionInTab={currentCursorPositionInTab}
            tab={tab}
          />
        </div>
        <div className="bottom">
          <SongEditorToolbar
            title={title}
            setTitle={setTitle}
            performer={performer}
            setPerformer={setPerformer}
            tuning={tuning}
            setTuning={setTuning}
            capo={capo}
            setCapo={setCapo}
            originalCapo={originalCapo}
            setOriginalCapo={setOriginalCapo}
            comments={comments}
            setComments={setComments}
            duration={duration}
            setDuration={setDuration}
            bpm={bpm}
            setBpm={setBpm}
            links={links}
            setLinks={setLinks}
            tab={tab}
            setTab={setTab}

            titleError={titleError}
            performerError={performerError}
            tuningError={tuningError}
            capoError={capoError}
            originalCapoError={originalCapoError}

            setTitleError={setTitleError}
            setPerformerError={setPerformerError}
            setTuningError={setTuningError}
            setCapoError={setCapoError}
            setOriginalCapoError={setOriginalCapoError}
          />
        </div>
      </div>
      <div className="column two">
        <ChordUtilityComponent
          currentChord={currentChord}
          setCurrentChord={setCurrentChord}
          tab={tab}
          setAddChord={setAddChord}
        />
      </div>
    </StyledDiv>
  );
}
