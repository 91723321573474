import { App } from "App";
import { AppStateProvider } from "AppStateProvider";
import React, { ReactElement } from "react";

export const AppWithContext = (): ReactElement => {
  return (
    <AppStateProvider>
      <App/>
    </AppStateProvider>
  )
}
