import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useRef, useState } from "react";
import iconCross from "resources/icons/icon_cross_black_small.webp";
import iconLens from "resources/icons/icon_lens_black_small.webp";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.fgColor1};

  color: black;
  font-size: 1rem;
  border-radius: 13px;
  padding: 0;
  margin:0;

  .icon-lens{
    width: 17px;
    height: 17px;
    margin-left: 6px;
    margin-right: 4px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .icon-cross{
    width: 16px;
    height: 16px;
    margin-right: 6px;
    margin-left: 4px;
    display: flex;
    justify-content:center;
    align-items: center;
    background-color: #ccc;
    border-radius:8px;
    cursor: pointer;

    &.hidden{
      opacity:0;
    }

    &:hover{
      background-color: #ddd;
    }
    img{
      width: 6px;
      height: 6px;
    }
  }

  form{
    display:flex;
    justify-content: center;
    align-items: center;

    .field{
      text-align: left;
      padding: .1rem;
      text-transform: uppercase;
      color: #afafaf;
      font-size: .7rem;
      font-weight: 400;
      input{
        font-family: 'Open Sans', sans-serif;
        color: black;
        font-size: 1rem;
        border: none;
        background-color: transparent;
        &:focus{
          outline: none
        }
      }
    }
  }
`;

type Props = {
  setBottomRowVisible?: (val: boolean) => void;
  className?: string;
}

export const HeaderSongsFinder = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputSearchTerm, setInputSearchTerm] = useState(appContext.searchTerm);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (props.setBottomRowVisible) props.setBottomRowVisible(false);
    appContext.setSearchTerm(inputSearchTerm);
    appContext.setPage(Config.PAGE_NAME__SONGLIST);
  }
  const cleanSearchTerm = () => {
    setInputSearchTerm("");
    appContext.setSearchTerm("");
    if (inputRef.current){
      inputRef.current.value="";
      inputRef.current.focus();
    }
  }

  const onChangeHandler = (e:ChangeEvent<HTMLInputElement>) => {
    setInputSearchTerm(e.target.value);
  }

  const getCrossClasses = () => {
    return inputSearchTerm === "" ? "icon-cross hidden":"icon-cross";
  }

  return (
    <StyledDiv {...props}>
      <div className="icon-lens">
        <img alt="iconLens" src={iconLens}/>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <input
            type="text"
            name="searchTerm"
            id="searchTerm"
            ref={inputRef}
            //autoFocus={true}
            placeholder={i8nText(appContext.language, "global.search")}
            tabIndex={1}
            //size={Config.SONG_EDITOR_PAGE__INPUT_SIZE}
            spellCheck={false}
            defaultValue={inputSearchTerm}
            onChange={e => onChangeHandler(e)}
          />
        </div>
      </form>
      <div className={getCrossClasses()} onClick={cleanSearchTerm}>
        <img alt="iconCross" src={iconCross}/>
      </div>
    </StyledDiv>
  );
}
