import React, { ReactElement } from "react";
import styled from "styled-components";
import { GuitarFretboardStringFretButton } from "./GuitarFretboardStringFretButton";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 21%;
`;

type Props = {
  stringState: number | null;
  string: number;
  changeStringStateHandler: (string: number, state: number | null) => void;
}

export const GuitarFretboardString = (props:Props): ReactElement => {
  const getState = (fret: number): number | null => {
    if (props.stringState === null){
      //String not set
      return null;
    } else if (props.stringState === -1) {
      if (fret === 0){
        return -1;
      }
    } else {
      if (fret === props.stringState){
        return props.stringState;
      }
    }
    return null;
  }

  return(
    <StyledDiv>
      <GuitarFretboardStringFretButton {...props} fret={0}  fretState={getState(0)} buttonWidth={5.633802817}/>
      <GuitarFretboardStringFretButton {...props} fret={1}  fretState={getState(1)} buttonWidth={12.27364185}/>
      <GuitarFretboardStringFretButton {...props} fret={2}  fretState={getState(2)} buttonWidth={10.46277666}/>
      <GuitarFretboardStringFretButton {...props} fret={3}  fretState={getState(3)} buttonWidth={9.657947686}/>
      <GuitarFretboardStringFretButton {...props} fret={4}  fretState={getState(4)} buttonWidth={8.651911469}/>
      <GuitarFretboardStringFretButton {...props} fret={5}  fretState={getState(5)} buttonWidth={7.847082495}/>
      <GuitarFretboardStringFretButton {...props} fret={6}  fretState={getState(6)} buttonWidth={7.645875252}/>
      <GuitarFretboardStringFretButton {...props} fret={7}  fretState={getState(7)} buttonWidth={7.645875252}/>
      <GuitarFretboardStringFretButton {...props} fret={8}  fretState={getState(8)} buttonWidth={7.042253521}/>
      <GuitarFretboardStringFretButton {...props} fret={9}  fretState={getState(9)} buttonWidth={6.438631791}/>
      <GuitarFretboardStringFretButton {...props} fret={10} fretState={getState(10)} buttonWidth={6.036217304}/>
      <GuitarFretboardStringFretButton {...props} fret={11} fretState={getState(11)} buttonWidth={5.432595573}/>
      <GuitarFretboardStringFretButton {...props} fret={12} fretState={getState(12)} buttonWidth={5.23138833}/>
    </StyledDiv>
  );
}
