import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_line_height.svg";

export const LineHeightSelector = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const onClickHandler = (increment: number) => {
    let newValue = Number(appContext.viewerLineHeight) + increment;
    if (newValue <= 0) {
      newValue = 1;
    }

    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_LINE_HEIGHT, newValue.toString());
    appContext.setViewerLineHeight(newValue);
  };

  return (
    <div className="line-height-selector">
      <ButtonIconText
        data-for="tooltipDecreaseLineHeight"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        invert
        fixed
        onClick={() => {
          onClickHandler(-1);
        }}
      />
      <ButtonIconText
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        data-for="tooltipIncreaseLineHeight"
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        fixed
        onClick={() => {
          onClickHandler(+1);
        }}
      />
      <ReactTooltip id="tooltipDecreaseLineHeight" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.decreaseLineHeight")}</span>
      </ReactTooltip>
      <ReactTooltip id="tooltipIncreaseLineHeight" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.increaseLineHeight")}</span>
      </ReactTooltip>
    </div>
  );
};
