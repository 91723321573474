import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { ImagePreload } from "components/common/ImagePreload";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useState } from "react";
import wastebinClosedIcon from "resources/icons/icon_waste_bin_closed.svg";
import wastebinOpenIcon from "resources/icons/icon_waste_bin_open.svg";
import styled from "styled-components";
import { SongInfo, SongList } from "types/common";
import { Dialog } from "../../common/Dialog";

const StyledDiv = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 15px;
  height: 20px;
  background-image: url(${wastebinClosedIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: ${props => props.theme.bp.mobile}) {
    width: 20px;
    height: 26.6px;
  }

  &:hover {
    background-image: url(${wastebinOpenIcon});
  }
`;

type Props = {
  songInfo: SongInfo;
  songList?: SongList;
  setSongList?: (songList: SongList) => void;
};

export const DeleteSongButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [showDialog, setShowDialog] = useState(false);

  const onClickHandler = () => {
    setShowDialog(true);
  };

  function closeDialog() {
    setShowDialog(false);
  }

  const deleteSong = () => {
    setShowDialog(false);

    const refreshSongList = () => {
      if (!isNil(props.songList) && !isNil(props.setSongList)) {
        for (let i = 0; i < props.songList.length; i++) {
          const element = props.songList[i];
          if (element.title === props.songInfo.title && element.performer === props.songInfo.performer) {
            const newArr = [...props.songList.slice(0, i), ...props.songList.slice(i + 1)];
            props.setSongList(newArr);
            break;
          }
        }
      }
    };

    axios({
      url: `${Config.SONGLIST_SERVICE_URL}`,
      method: "DELETE",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN),
      },
      data: {
        title: props.songInfo.title,
        performer: props.songInfo.performer,
      },
    })
      .then(() => {
        if (props.songList && props.setSongList) {
          refreshSongList();
        } else {
          window.location.replace(Config.PAGE_ROUTE__SONGLIST);
        }
      })
      .catch(error => {
        //TODO Show error message
        console.log("Request error response:->", error.response);
      });
  };

  const options = {
    [i8nText(appContext.language, "global.yes")]: deleteSong,
    [i8nText(appContext.language, "global.no")]: closeDialog,
  };

  return props.songInfo.userId === appContext.user?.id ? (
    <>
      <ImagePreload src={wastebinOpenIcon} />
      <StyledDiv onClick={onClickHandler} />
      <Dialog visible={showDialog} options={options} onClose={closeDialog}>
        {i8nText(appContext.language, "songList.deleteMessage")}&nbsp;
        <span style={{ fontWeight: 700 }}>
          {props.songInfo.performer} - {props.songInfo.title}
        </span>
        ?
      </Dialog>
    </>
  ) : (
    <></>
  );
};
