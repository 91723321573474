import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_font_size.svg";


export const FontSizeSelector = (): ReactElement => {

  const appContext = useContext(AppStateContext);

  const onClickHandler = (increment: number) => {
    let newValue = Number(appContext.viewerFontSize) + increment;
    if (newValue<=0){
      newValue=1;
    }

    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_FONT_SIZE, newValue.toString());
    appContext.setViewerFontSize(newValue);
  }

  return (
    <div className="font-size-selector">
      <ButtonIconText
        data-for="tooltipDecreaseFontSize"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        invert
        fixed
        onClick={()=>onClickHandler(-1)}
      />
      <ButtonIconText
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        data-for="tooltipIncreaseFontSize"
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        fixed
        onClick={()=>onClickHandler(+1)}
      />
      <ReactTooltip id="tooltipDecreaseFontSize" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.decreaseFontSize")}</span>
      </ReactTooltip>
      <ReactTooltip id="tooltipIncreaseFontSize" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.increaseFontSize")}</span>
      </ReactTooltip>
    </div>
  );
}