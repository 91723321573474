import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import icon from "resources/icons/icon_remove_blank_lines.svg";
import styled from "styled-components";
import { EditedSongWithSetters, EditorErrors, EditorErrorsWithSetters } from "types/common";

const StyledDiv = styled.div`
  display: inline-block;
`;

type Props = EditedSongWithSetters & EditorErrors & EditorErrorsWithSetters;

export const RemoveBlankLinesButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const onClickHandler = () => {
    console.log("RemoveBlankLinesButton clicked!");

    let newTab = "";
    const oldTabArray = props.tab.split("\n");

    for (let i = 0; i < oldTabArray.length; i++) {
      const line = oldTabArray[i];
      if (line.trim() !== "") {
        newTab += line + "\n";
      }
    }
    props.setTab(newTab);
  };

  return (
    <StyledDiv onClick={onClickHandler}>
      <ButtonIconText
        {...props}
        text={i8nText(appContext.language, "editorButton.removeBlankLines")}
        icon={icon}
        primary
        fixed
      />
    </StyledDiv>
  );
};
