//https://classic.yarnpkg.com/en/docs/cli/version
import { isNil } from "lodash";
import { Environment } from "types/common";
import appInfo from "../../../package.json";

if (isNil(window.environment)) {
  window.environment = Environment.PRODUCTION;
  if (window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") {
    window.environment = Environment.DEVELOPMENT;
  }
}
let API_HOST = "https://api.guitarnotebook.com";
if (window.environment === Environment.DEVELOPMENT) {
  API_HOST = "http://localhost:8080";
}
if (window.environment === Environment.DEVELOPMENT) {
  console.log("window.location: ", window.location);
  console.log("Environment: ", window.environment);
  console.log("API_HOST: ", API_HOST);
}

export const Config = {
  APP_VERSION: appInfo.version,

  SONGLIST_SERVICE_URL: API_HOST + "/api/songs",
  SONG_SERVICE_URL: API_HOST + "/api/song",
  SEARCH_SERVICE_URL: API_HOST + "/api/search",
  CHORD_SERVICE_URL: API_HOST + "/api/chords",
  FAVORITE_SERVICE_URL: API_HOST + "/api/favorites",
  SIGNUP_SERVICE_URL: API_HOST + "/api/auth/signup",
  SIGNIN_SERVICE_URL: API_HOST + "/api/auth/signin",
  STATS_SERVICE_URL: API_HOST + "/api/stats",
  SEND_RESET_PASSWORD_EMAIL_SERVICE_URL: API_HOST + "/api/user/reset-password",
  CHANGE_PASSWORD_SERVICE_URL: API_HOST + "/api/user/change-password",

  REQUEST_TIMEOUT: 20000,

  // Internal name of page
  PAGE_NAME__HOMEPAGE: "homepage",
  PAGE_NAME__PAGENOTFOUND: "page-not-found",
  PAGE_NAME__SONGLIST: "song-list-page",
  PAGE_NAME__SONGVIEWER: "song-viewer-page",
  PAGE_NAME__SONGEDITOR: "song-editor-page",
  PAGE_NAME__ABOUT: "about",
  PAGE_NAME__FORGOTTENPASSWORD: "forgotten-password",
  PAGE_NAME__RESETPASSWORD: "reset-password",
  PAGE_NAME__PASSWORDRESTORED: "password-restored",
  PAGE_NAME__EMAILSENT: "email-sent",
  PAGE_NAME__SETTINGS: "settings",

  // URL of the page
  PAGE_ROUTE__HOMEPAGE: "/",
  PAGE_ROUTE__SONGLIST: "/songs",
  PAGE_ROUTE__SONGVIEWER: "/viewer",
  PAGE_ROUTE__SONGEDITOR: "/editor",
  PAGE_ROUTE__ABOUT: "/about",
  PAGE_ROUTE__FORGOTTENPASSWORD: "/forgotten-password",
  PAGE_ROUTE__RESETPASSWORD: "/reset-password",
  PAGE_ROUTE__PASSWORDRESTORED: "/password-restored",
  PAGE_ROUTE__EMAILSENT: "/email-sent",
  PAGE_ROUTE__SETTINGS: "/settings",

  SONGLIST_FILTER__ALL_SONGS: "all-songs",
  SONGLIST_FILTER__MY_SONGS: "my-songs",
  SONGLIST_FILTER__MY_FAVORITES: "my-favorites",

  LOCALSTORAGE_SONGLIST_SCROLLTOP: "gtm-songlist-scrolltop",

  LOCALSTORAGE_USER__JWT_DATA: "gtm-user-jwt-data",
  LOCALSTORAGE_USER__AUTH_TOKEN: "gtm-user-auth-token",

  LOCALSTORAGE_APP__LANGUAGE: "gtm-app-language",
  LOCALSTORAGE_APP__SOUND_ENABLED: "gtm-app-sound-enabled",
  LOCALSTORAGE_APP__CACHE_ENABLED: "gtm-app-cache-enabled",
  LOCALSTORAGE_APP__METRONOME_ENABLED: "gtm-app-metronome-enabled",
  LOCALSTORAGE_APP__METRONOME_SOUND_ENABLED: "gtm-app-metronome-sound-enabled",
  LOCALSTORAGE_APP__METRONOME_OPACITY: "gtm-app-metronome-opacity",
  LOCALSTORAGE_APP__METRONOME_SIZE: "gtm-app-metronome-size",
  LOCALSTORAGE_APP__TOOLTIPS_ENABLED: "gtm-app-tooltips-enabled",
  LOCALSTORAGE_APP__AUTOFIT_ENABLED: "gtm-app-autofit-enabled",

  LOCALSTORAGE_CACHE__RESOURCE_PREFIX: "gtm-resource_",

  LOCALSTORAGE_ITEM_NAME__CURRENT_SONG: "gtm-song",
  LOCALSTORAGE_ITEM_NAME__CURRENT_SONG_INFO: "gtm-song-info",
  LOCALSTORAGE_ITEM_NAME__TRANSPOSITION: "gtm-transposition",
  LOCALSTORAGE_ITEM_NAME__BPM: "gtm-bpm",
  LOCALSTORAGE_ITEM_NAME__NUMBER_OF_COLUMNS_IN_SONG: "gtm-number-of-columns-in-song",
  LOCALSTORAGE_ITEM_NAME__VIEWER_FONT_SIZE: "gtm-viewer-font-size",
  LOCALSTORAGE_ITEM_NAME__VIEWER_LINE_HEIGHT: "gtm-viewer-line-height",
  LOCALSTORAGE_ITEM_NAME__VIEWER_LETTER_SPACING: "gtm-viewer-letter-spacing",
  LOCALSTORAGE_ITEM_NAME__CURRENT_PAGE: "gtm-current-page",

  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TITLE: "gtm-edited-song-title",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_PERFORMER: "gtm-edited-song-performer",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TUNING: "gtm-edited-song-tuning",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_CAPO: "gtm-edited-song-capo",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_ORIGINAL_CAPO: "gtm-edited-song-original-capo",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_COMMENTS: "gtm-edited-song-comments",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_DURATION: "gtm-edited-song-duration",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_BPM: "gtm-edited-song-bpm",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_LINKS: "gtm-edited-song-links",
  LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TAB: "gtm-edited-song-tab",
  LOCALSTORAGE_ITEM_NAME__CURSOR_POSITION: "gtm-cursor-position",

  LOCALSTORAGE__LAST_LOADED_SONG__TITLE: "gtm-lls-title",
  LOCALSTORAGE__LAST_LOADED_SONG__PERFORMER: "gtm-lls-performer",
  LOCALSTORAGE__LAST_LOADED_SONG__USERID: "gtm-lls-userid",

  SONG_EDITOR_PAGE__INPUT_SIZE: "20",

  LINE_TYPE__LYRICS: "LYRICS",
  LINE_TYPE__CHORDS: "CHORDS",
  LINE_TYPE__SECTION: "SECTION",
  LINE_TYPE__LINEBREAK: "LINEBREAK",

  TOOLTIP__DELAY_SHOW: 500,

  API_RESPONSE_MESSAGE: ["User registered successfully", "Username is already taken", "Email is already in use"],
};
