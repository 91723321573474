import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { getChord, i8nText, playChord } from "components/common/Util";
import React, { ReactElement, useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_play_prev_chord.svg";

type Props = {
  keyPressed: boolean;
  chordPositionList: Array<string>;
  currentChordPosition: number;
  setCurrentChordPosition: (val: number) => void;
};

export const PlayPrevChordButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  useEffect(() => {
    if (props.keyPressed) onClickHandler();
    // eslint-disable-next-line
  }, [props.keyPressed]);

  const onClickHandler = () => {
    let position = props.currentChordPosition - 1;
    if (position < 0) {
      position = props.chordPositionList.length - 1;
    }
    const chordName = props.chordPositionList[position];
    const myChord = getChord(chordName, appContext.chordList);
    if (appContext.soundEnabled && myChord) playChord(myChord.string, appContext.transposition);
    props.setCurrentChordPosition(position);
  };

  return (
    <div className="play-prev-chord-button">
      <ButtonIconText
        {...props}
        data-for="tooltipPlayPrevChord"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        onClick={onClickHandler}
      />
      <ReactTooltip id="tooltipPlayPrevChord" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.playPrevChord")}</span>
      </ReactTooltip>
    </div>
  );
};
