import { AppStateContext } from "AppStateProvider";
import { i8nText } from "components/common/Util";
import React, { ChangeEvent, MouseEvent, ReactElement, useContext, useRef, useState } from "react";
import iconEye from "resources/icons/eye2.svg";
import iconInstagram from "resources/icons/icon_instagram.svg";
import iconUnknown from "resources/icons/icon_question.png";
import iconSpotify from "resources/icons/icon_spotify.svg";
import iconTiktok from "resources/icons/icon_tiktok.svg";
import iconTwitch from "resources/icons/icon_twitch.svg";
import iconVimeo from "resources/icons/icon_vimeo.svg";
import iconYoutube from "resources/icons/icon_youtube.svg";
import styled from "styled-components";
import { FullSongInfo, SongInfo } from "types/common";
import { ShareButton } from "../../common/ShareButton";
import { TextEditorButton } from "../song-editor/TextEditorButton";
import { DeleteSongButton } from "../song-list/DeleteSongButton";
import { FavoriteSongButton } from "../song-list/FavoriteSongButton";

const StyledDiv = styled.div`
  flex-grow: 1;

  .links {
    display: flex;
    align-items: center;
    .text {
      display: flex;
      align-items: center;
      a {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .comments {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    .eye {
      > div {
        height: 1rem;
        width: 1.3rem;
        position: relative;
        top: -0.125rem;
      }
    }
  }

  .label {
    font-size: 0.6rem;
    display: inline-block;
    color: gray;
    text-transform: uppercase;
    text-align: right;
    margin-right: 0.3rem;
    user-select: none;
  }

  .text {
    display: inline-block;
    font-size: 1rem;
    text-transform: capitalize;
    .link {
      height: 1.5rem;
      width: 1.5rem;
      background-repeat: no-repeat;
      display: inline-block;
      background-size: contain;
      background-position: center center;

      &.youtube {
        background-image: url(${iconYoutube});
      }
      &.spotify {
        background-image: url(${iconSpotify});
      }
      &.tiktok {
        background-image: url(${iconTiktok});
      }
      &.instagram {
        background-image: url(${iconInstagram});
      }
      &.vimeo {
        background-image: url(${iconVimeo});
      }
      &.twitch {
        background-image: url(${iconTwitch});
      }
      &.unknown {
        background-image: url(${iconUnknown});
        width: 1.3rem;
      }
    }
  }

  .row {
    display: flex;
    align-items: center;
    @media (min-width: ${props => props.theme.bp.desktop}) {
      height: 1.5rem;
    }

    &:empty {
      display: none;
    }

    .title {
      .favorite {
        display: inline-block;
        margin-left: 0.7rem;
        position: relative;
        top: 0.3rem;
        @media (max-width: ${props => props.theme.bp.mobile}) {
          top: 0.6rem;
        }
      }
      .delete {
        display: inline-block;
        margin-left: 0.7rem;
        position: relative;
        top: 0.3rem;
        @media (max-width: ${props => props.theme.bp.mobile}) {
          top: 0.4rem;
        }
      }
      .share {
        display: inline-block;
        margin-left: 0.7rem;
        position: relative;
        top: 0.3rem;
        @media (max-width: ${props => props.theme.bp.mobile}) {
          top: 0.4rem;
        }
      }
    }
    .bpm {
      margin-left: 0.3rem;
    }
  }
  .row > div:first-child > .label {
    width: 3.5rem;
    margin-left: 0;
  }
  .row.comments-links {
    position: relative;
    top: 0.125rem;
  }
  .row.tuning-capo {
    @media (max-width: ${props => props.theme.bp.mobile}) {
      display: block;
    }
  }

  div.tab-by .text {
    text-transform: none;
  }

  .capo-wrapper {
    display: flex;

    .capo,
    .original-capo,
    .custom-capo {
      cursor: pointer;
      padding: 0 0.3rem;
      margin-left: 0.5rem;
      border-radius: 1rem;
      border: 2px solid gray;
      height: 1.25rem;

      &:hover {
        //border: 2px solid gray;
        border: 2px solid ${props => props.theme.fgColor1};
      }
      &.selected {
        background-color: ${props => props.theme.fgColor2};
        border: 2px solid ${props => props.theme.fgColor1};
        overflow: hidden;
        .text {
          color: black;
        }
      }

      .label {
        position: relative;
        top: -0.125rem;
      }
      .text {
        position: relative;
        top: -0.125rem;
      }

      input {
        font-family: "Open Sans", sans-serif;
        color: black;
        font-size: 0.9rem;
        font-weight: 600;
        border-radius: 1rem;
        border: 1px solid darkgray;
        padding-left: 0.5rem;
        text-align: center;
        height: 1rem;
        position: relative;
        top: -0.125rem;
        width: 2rem;
        right: -0.3rem;
      }
    }
  }

  .custom-capo {
    padding-right: 0;
  }

  .capo .label,
  .original-capo .label,
  .bpm .label,
  .links .label {
    //width: unset;
    //margin-left: .5rem;
  }
`;

type Props = {
  songData: FullSongInfo;
  setSongData: (val: FullSongInfo) => void;
};

export const ViewerSongInfo = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const [customCapoValue, setCustomCapoValue] = useState<number>(props.songData.originalCapo);

  const songInfo: SongInfo = {
    performer: props.songData.performer,
    title: props.songData.title,
    lastModified: props.songData.lastModified,
    userId: props.songData.userId,
    username: props.songData.username,
    favorite: props.songData.favorite,
  };

  const setSongInfo = (songInfo: SongInfo) => {
    const newSongData = { ...props.songData };
    newSongData.favorite = songInfo.favorite;
    props.setSongData(newSongData);
  };

  const capo = useRef<HTMLDivElement>(null);
  const originalCapo = useRef<HTMLDivElement>(null);
  const customCapo = useRef<HTMLDivElement>(null);

  const getLinkType = (link: string) => {
    if (link.includes("youtube.com") || link.includes("youtu.be")) {
      return "youtube";
    } else if (link.includes("spotify")) {
      return "spotify";
    } else if (link.includes("instagram")) {
      return "instagram";
    } else if (link.includes("tiktok")) {
      return "tiktok";
    } else if (link.includes("twitch")) {
      return "twitch";
    } else if (link.includes("vimeo")) {
      return "vimeo";
    }
    return "unknown";
  };

  const onClickCapoHandler = (e: MouseEvent) => {
    let el = e.target as HTMLElement;
    if (
      !(el.classList.contains("capo") || el.classList.contains("original-capo") || el.classList.contains("custom-capo"))
    ) {
      el = el.parentElement ?? el;
    }
    if (capo.current && originalCapo.current && customCapo.current) {
      if (el.classList.contains("capo")) {
        appContext.setTransposition(props.songData.capo);
        capo.current.classList.add("selected");
        originalCapo.current.classList.remove("selected");
        customCapo.current.classList.remove("selected");
      } else if (el.classList.contains("original-capo")) {
        appContext.setTransposition(props.songData.originalCapo);
        originalCapo.current.classList.add("selected");
        capo.current.classList.remove("selected");
        customCapo.current.classList.remove("selected");
      } else if (el.classList.contains("custom-capo")) {
        appContext.setTransposition(customCapoValue);
        customCapo.current.classList.add("selected");
        capo.current.classList.remove("selected");
        originalCapo.current.classList.remove("selected");
      }
    }
  };
  const onChangeCustomCapoHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== "") {
      setCustomCapoValue(Number(e.target.value));
      appContext.setTransposition(Number(e.target.value));
    }
  };

  return (
    <StyledDiv>
      <div className="row">
        <div className="title">
          <div className="label">{i8nText(appContext.language, "global.title")}</div>
          <div className="text">{props.songData.title}</div>
          {appContext.user ? (
            <div className="favorite">
              <FavoriteSongButton songInfo={songInfo} setSongInfo={setSongInfo} />
            </div>
          ) : (
            ""
          )}
          {appContext.user && props.songData && appContext.user.id === props.songData.userId ? (
            <div className="delete">
              <DeleteSongButton songInfo={songInfo} />
            </div>
          ) : (
            ""
          )}
          <div className="share">
            <ShareButton
              performer={props.songData.performer}
              title={props.songData.title}
              userId={props.songData.userId}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="performer">
          <div className="label">{i8nText(appContext.language, "global.performer")}</div>
          <div className="text">{props.songData.performer}</div>
        </div>
      </div>

      <div className="row tuning-capo">
        <div className="tuning">
          <div className="label">{i8nText(appContext.language, "global.tuning")}</div>
          <div className="text">{props.songData.tuning}</div>
        </div>

        <div className="capo-wrapper">
          <div className="capo selected" ref={capo} onClick={e => onClickCapoHandler(e)}>
            <div className="label">{i8nText(appContext.language, "global.capo")}</div>
            <div className="text">{props.songData.capo}</div>
          </div>
          <div className="original-capo" ref={originalCapo} onClick={e => onClickCapoHandler(e)}>
            <div className="label">{i8nText(appContext.language, "global.original")}</div>
            <div className="text">{props.songData.originalCapo}</div>
          </div>
          <div className="custom-capo" ref={customCapo} onClick={e => onClickCapoHandler(e)}>
            <div className="label">{i8nText(appContext.language, "global.custom")}</div>
            <input
              type="number"
              name="customCapo"
              id="customCapo"
              min={-4}
              max={7}
              size={2}
              spellCheck={false}
              defaultValue={customCapoValue}
              onChange={e => onChangeCustomCapoHandler(e)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="duration">
          <div className="label">{i8nText(appContext.language, "global.duration")}</div>
          <div className="text">
            {Math.floor(props.songData.duration / 60)}:
            {(props.songData.duration % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 })}
          </div>
        </div>
        <div className="bpm">
          <div className="label">{i8nText(appContext.language, "global.bpm")}</div>
          <div className="text">{props.songData.bpm}</div>
        </div>
      </div>
      <div className="row comments-links">
        {props.songData.comments === "" ? (
          ""
        ) : (
          <div className="comments">
            <div className="label">{i8nText(appContext.language, "global.comments")}</div>
            <div className="eye">
              <TextEditorButton
                propertyProp={props.songData.comments}
                setter={() => {}}
                icon={iconEye}
                headerTitle={i8nText(appContext.language, "global.comments")}
                disabled={true}
              />
            </div>
          </div>
        )}

        {props.songData.links === "" ? (
          ""
        ) : (
          <div className="links">
            <div className="label">{i8nText(appContext.language, "global.links")}</div>
            <div className="text">
              {props.songData.links
                ? props.songData.links.split("\n").map((link, index) => {
                    return (
                      <a key={index} href={link} rel="noopener noreferrer" target="_blank">
                        <div className={"link " + getLinkType(link)}></div>
                      </a>
                    );
                  })
                : ""}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="tab-by">
          <div className="label">{i8nText(appContext.language, "global.tabbedBy")}</div>
          <div className="text">
            {props.songData.username}
            {appContext.user && props.songData.username === appContext.user.username
              ? " (" + i8nText(appContext.language, "global.me") + ")"
              : ""}
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};
