import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_edit_song.svg";
import { FullSongInfo } from "types/common";

type Props = {
  songData: FullSongInfo;
}

export const EditSongButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const onClickHandler = () => {
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TITLE, props.songData.title);
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_PERFORMER, props.songData.performer);
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TUNING, props.songData.tuning);
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_CAPO, props.songData.capo.toString());
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_ORIGINAL_CAPO, props.songData.originalCapo.toString());
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_COMMENTS, props.songData.comments);
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_LINKS, props.songData.links);
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_TAB, props.songData.tab);
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_DURATION, props.songData.duration.toString());
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__EDITED_SONG_BPM, props.songData.bpm.toString());

    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__CURRENT_PAGE, Config.PAGE_NAME__SONGEDITOR);
    appContext.setPage(Config.PAGE_NAME__SONGEDITOR);
  }

  return (
    <div className="edit-song-button">
      <ButtonIconText {...props}
        data-for="tooltipEditSong"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        fixed
        onClick={onClickHandler}
      />
      <ReactTooltip id="tooltipEditSong" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.editSong")}</span>
      </ReactTooltip>
    </div>
  );
}