import { HeaderLogo } from "components/header/HeaderLogo";
import { HeaderNav } from "components/header/HeaderNav";
import { UserInfo } from "components/header/UserInfo";
import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { User } from "types/common";
import { HeaderSongsFinder } from "./header/HeaderSongsFinder";

const StyledHeader = styled.header`
  .top-row-wrapper{
    width: 100%;
    height: 3rem;
    background-color: ${props => props.theme.bgColor2};
    box-shadow: rgb(0, 0, 0) 0px 5px 20px -10px;
    overflow: hidden;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 200;

    .left-area{
      display: flex;
      align-items: center;

      .search-box{
        @media (max-width: ${props => props.theme.bp.mobile}){
          display:none;
        }
      }
    }
    .center-area{
      display: flex;
      align-items: center;
      flex-grow:1;
    }
    .right-area{
      display: flex;
      align-items: center;
    }
  }
  .bottom-row-wrapper{
    width: 100%;
    height: 3rem;
    background-color: ${props => props.theme.fgColor2};
    box-shadow: rgb(0, 0, 0) 0px 5px 20px -10px;
    overflow: hidden;
    display:flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    top: 0rem;
    transition: all .3s ease-in-out;
    box-sizing: border-box;

    &.visible{
      top: 3rem;
    }
  }
`;

type Props = {
  user: User | undefined;
}

export const AppHeader = (props: Props): ReactElement => {
  const [bottomRowVisible, setBottomRowVisible] = useState(false);

  return (
    <StyledHeader>
      <div className="top-row-wrapper">
        <div className="left-area">
          <HeaderLogo/>
          <HeaderNav bottomRowVisible={bottomRowVisible} setBottomRowVisible={setBottomRowVisible}/>
          <HeaderSongsFinder className="search-box"/>
        </div>
        <div className="right-area">
          <UserInfo user={props.user}/>
        </div>
      </div>
      <div className={"bottom-row-wrapper " +(bottomRowVisible ? "visible":"")}>
        <HeaderSongsFinder/>
      </div>
    </StyledHeader>
  );
}