import { Config } from "components/common/Config";
import { initializeApp } from "components/common/Initialization";
import { isNil } from "lodash";
import React, { createContext, ReactElement, useState } from "react";
import { Chord, FullSongInfo, User } from "types/common";
import { getPreferredLanguage } from "./components/common/Language";

type ContextProps = {
  language: string;
  setLanguage: (val: string) => void;
  soundEnabled: boolean;
  setSoundEnabled: (val: boolean) => void;
  cacheEnabled: boolean;
  setCacheEnabled: (val: boolean) => void;
  metronomeEnabled: boolean;
  setMetronomeEnabled: (val: boolean) => void;
  metronomeSoundEnabled: boolean;
  setMetronomeSoundEnabled: (val: boolean) => void;
  metronomeSize: number;
  setMetronomeSize: (val: number) => void;
  metronomeOpacity: number;
  setMetronomeOpacity: (val: number) => void;
  tooltipsEnabled: boolean;
  setTooltipsEnabled: (val: boolean) => void;
  autoFitEnabled: boolean;
  setAutoFitEnabled: (val: boolean) => void;
  user: User | undefined;
  setUser: (val: User | undefined) => void;
  page: string;
  setPage: (val: string) => void;
  location: Location;
  song: FullSongInfo | undefined;
  setSong: (val: FullSongInfo | undefined) => void;
  numberOfColumnsInSong: number;
  setNumberOfColumnsInSong: (val: number) => void;
  viewerFontSize: number;
  setViewerFontSize: (val: number) => void;
  viewerLineHeight: number;
  setViewerLineHeight: (val: number) => void;
  viewerLetterSpacing: number;
  setViewerLetterSpacing: (val: number) => void;
  chordList: Array<Chord>;
  setChordList: (val: Array<Chord>) => void;
  transposition: number;
  setTransposition: (val: number) => void;
  searchTerm: string;
  setSearchTerm: (val: string) => void;
  songListFilter: string;
  setSongListFilter: (val: string) => void;
};

const contextInitValues: ContextProps = {
  language: "",
  setLanguage: () => {},
  soundEnabled: false,
  setSoundEnabled: () => {},
  cacheEnabled: false,
  setCacheEnabled: () => {},
  metronomeEnabled: false,
  setMetronomeEnabled: () => {},
  metronomeSoundEnabled: false,
  setMetronomeSoundEnabled: () => {},
  metronomeSize: 0,
  setMetronomeSize: () => {},
  metronomeOpacity: 0,
  setMetronomeOpacity: () => {},
  tooltipsEnabled: false,
  setTooltipsEnabled: () => {},
  autoFitEnabled: false,
  setAutoFitEnabled: () => {},
  user: undefined,
  setUser: () => {},
  page: "",
  setPage: () => {},
  location: location,
  song: undefined,
  setSong: () => {},
  numberOfColumnsInSong: 0,
  setNumberOfColumnsInSong: () => {},
  viewerFontSize: 0,
  setViewerFontSize: () => {},
  viewerLineHeight: 0,
  setViewerLineHeight: () => {},
  viewerLetterSpacing: 0,
  setViewerLetterSpacing: () => {},
  chordList: [],
  setChordList: () => {},
  transposition: 0,
  setTransposition: () => {},
  searchTerm: "",
  setSearchTerm: () => {},
  songListFilter: "",
  setSongListFilter: () => {},
};

export const AppStateContext = createContext<ContextProps>(contextInitValues);

type Props = {
  children: JSX.Element;
};

export const AppStateProvider = (props: Props): ReactElement => {
  const { children } = props;

  initializeApp();

  /* ROUTER */
  // console.log("LOCATION",window.location)
  /*
  {
    "href": "http://localhost:3000/viewer?title=Somewhere%20Only%20We%20Know&performer=Keane&userid=1",
    "ancestorOrigins": {},
    "origin": "http://localhost:3000",
    "protocol": "http:",
    "host": "localhost:3000",
    "hostname": "localhost",
    "port": "3000",
    "pathname": "/viewer",
    "search": "?title=Somewhere%20Only%20We%20Know&performer=Keane&userid=1",
    "hash": ""
  }
  */
  let currentPage = "";
  switch (window.location.pathname) {
    case Config.PAGE_ROUTE__HOMEPAGE:
      currentPage = Config.PAGE_NAME__HOMEPAGE;
      break;
    case Config.PAGE_ROUTE__SONGLIST:
      currentPage = Config.PAGE_NAME__SONGLIST;
      break;
    case Config.PAGE_ROUTE__SONGVIEWER:
      currentPage = Config.PAGE_NAME__SONGVIEWER;
      break;
    case Config.PAGE_ROUTE__SONGEDITOR:
      currentPage = Config.PAGE_NAME__SONGEDITOR;
      break;
    case Config.PAGE_ROUTE__ABOUT:
      currentPage = Config.PAGE_NAME__ABOUT;
      break;
    case Config.PAGE_ROUTE__RESETPASSWORD:
      currentPage = Config.PAGE_NAME__RESETPASSWORD;
      break;
    case Config.PAGE_ROUTE__FORGOTTENPASSWORD:
      currentPage = Config.PAGE_NAME__FORGOTTENPASSWORD;
      break;
    case Config.PAGE_ROUTE__PASSWORDRESTORED:
      currentPage = Config.PAGE_NAME__PASSWORDRESTORED;
      break;
    case Config.PAGE_ROUTE__EMAILSENT:
      currentPage = Config.PAGE_NAME__EMAILSENT;
      break;
    case Config.PAGE_ROUTE__SETTINGS:
      currentPage = Config.PAGE_NAME__SETTINGS;
      break;
    default:
      currentPage = Config.PAGE_NAME__PAGENOTFOUND;
  }

  function getStoredValueOrDefault<T>(localStorageSettingName: string, defaultValue: T) {
    const storedValue = window.localStorage.getItem(localStorageSettingName);
    if (storedValue === null) {
      return defaultValue;
    } else {
      return storedValue as unknown as T;
    }
  }

  const getStoredUser = () => {
    const storedUser = window.sessionStorage.getItem(Config.LOCALSTORAGE_USER__JWT_DATA);
    if (isNil(storedUser)) {
      return undefined;
    }
    const parsedStoredUser: User | undefined = JSON.parse(storedUser);
    return parsedStoredUser;
  };

  /* APP SETTINGS */
  const storedUser = getStoredUser();

  const storedLang = getStoredValueOrDefault<string>(Config.LOCALSTORAGE_APP__LANGUAGE, getPreferredLanguage());
  const storedSoundEnabled = getStoredValueOrDefault<boolean>(Config.LOCALSTORAGE_APP__SOUND_ENABLED, true);
  const storedCacheEnabled = getStoredValueOrDefault<boolean>(Config.LOCALSTORAGE_APP__CACHE_ENABLED, true);
  const storedMetronomeEnabled = getStoredValueOrDefault<boolean>(Config.LOCALSTORAGE_APP__METRONOME_ENABLED, true);
  const storedMetronomeSoundEnabled = getStoredValueOrDefault<boolean>(
    Config.LOCALSTORAGE_APP__METRONOME_SOUND_ENABLED,
    false,
  );
  const storedMetronomeOpacity = getStoredValueOrDefault<number>(Config.LOCALSTORAGE_APP__METRONOME_OPACITY, 0.5);
  const storedMetronomeSize = getStoredValueOrDefault<number>(Config.LOCALSTORAGE_APP__METRONOME_SIZE, 0.7);
  const storedTooltipsEnabled = getStoredValueOrDefault<boolean>(Config.LOCALSTORAGE_APP__TOOLTIPS_ENABLED, true);
  const storedAutoFitEnabled = getStoredValueOrDefault<boolean>(Config.LOCALSTORAGE_APP__AUTOFIT_ENABLED, true);
  const storedTransposition = getStoredValueOrDefault<number>(Config.LOCALSTORAGE_ITEM_NAME__TRANSPOSITION, 0);
  const storedColumns = getStoredValueOrDefault<number>(Config.LOCALSTORAGE_ITEM_NAME__NUMBER_OF_COLUMNS_IN_SONG, 2);
  const storedViewerFontSize = getStoredValueOrDefault<number>(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_FONT_SIZE, 16);
  const storedViewerLineHeight = getStoredValueOrDefault<number>(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_LINE_HEIGHT, 16);
  const storedViewerLetterSpacing = getStoredValueOrDefault<number>(
    Config.LOCALSTORAGE_ITEM_NAME__VIEWER_LETTER_SPACING,
    0,
  );

  const [language, setLanguage] = useState(storedLang);
  const [soundEnabled, setSoundEnabled] = useState(storedSoundEnabled);
  const [cacheEnabled, setCacheEnabled] = useState(storedCacheEnabled);
  const [metronomeEnabled, setMetronomeEnabled] = useState(storedMetronomeEnabled);
  const [metronomeSoundEnabled, setMetronomeSoundEnabled] = useState(storedMetronomeSoundEnabled);
  const [metronomeOpacity, setMetronomeOpacity] = useState(storedMetronomeOpacity);
  const [metronomeSize, setMetronomeSize] = useState(storedMetronomeSize);
  const [tooltipsEnabled, setTooltipsEnabled] = useState(storedTooltipsEnabled);
  const [autoFitEnabled, setAutoFitEnabled] = useState(storedAutoFitEnabled);

  const [user, setUser] = useState(storedUser);

  const [page, setPage] = useState(currentPage);
  // eslint-disable-next-line
  const [location, setLocation] = useState(window.location);

  const [song, setSong] = useState<FullSongInfo>();
  const [numberOfColumnsInSong, setNumberOfColumnsInSong] = useState(storedColumns);
  const [viewerFontSize, setViewerFontSize] = useState(storedViewerFontSize);
  const [viewerLineHeight, setViewerLineHeight] = useState(storedViewerLineHeight);
  const [viewerLetterSpacing, setViewerLetterSpacing] = useState(storedViewerLetterSpacing);
  const [chordList, setChordList] = useState<Array<Chord>>([]);
  const [transposition, setTransposition] = useState(storedTransposition);

  const [searchTerm, setSearchTerm] = useState("");
  const [songListFilter, setSongListFilter] = useState("all-songs");

  const state = {
    language: language,
    setLanguage: setLanguage,
    soundEnabled: soundEnabled,
    setSoundEnabled: setSoundEnabled,
    cacheEnabled: cacheEnabled,
    setCacheEnabled: setCacheEnabled,
    metronomeEnabled: metronomeEnabled,
    setMetronomeEnabled: setMetronomeEnabled,
    metronomeSoundEnabled: metronomeSoundEnabled,
    setMetronomeSoundEnabled: setMetronomeSoundEnabled,
    metronomeSize: metronomeSize,
    setMetronomeSize: setMetronomeSize,
    metronomeOpacity: metronomeOpacity,
    setMetronomeOpacity: setMetronomeOpacity,
    tooltipsEnabled: tooltipsEnabled,
    setTooltipsEnabled: setTooltipsEnabled,
    autoFitEnabled: autoFitEnabled,
    setAutoFitEnabled: setAutoFitEnabled,

    user: user,
    setUser: setUser,
    page: page,
    setPage: setPage,
    location: location,
    song: song,
    setSong: setSong,
    numberOfColumnsInSong: numberOfColumnsInSong,
    setNumberOfColumnsInSong: setNumberOfColumnsInSong,
    viewerFontSize: viewerFontSize,
    setViewerFontSize: setViewerFontSize,
    viewerLineHeight: viewerLineHeight,
    setViewerLineHeight: setViewerLineHeight,
    viewerLetterSpacing: viewerLetterSpacing,
    setViewerLetterSpacing: setViewerLetterSpacing,
    chordList: chordList,
    setChordList: setChordList,
    transposition: transposition,
    setTransposition: setTransposition,

    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    songListFilter: songListFilter,
    setSongListFilter: setSongListFilter,
  };

  return <AppStateContext.Provider value={state}>{children}</AppStateContext.Provider>;
};
