import React, { ChangeEvent, ReactElement, useState } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;

  .text{
    width: 50%;
    display: inline-flex;
    height: 100%;
    text-align: left;
  }
  .slider-wrapper{
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    height: 100%;

    input {
      width: 90%;
    }
  }
`;

type Props = {
  localStorageKey: string;
  setting: number;
  setSetting: (value: number) => void;
  text: string;
  min: number;
  max: number;
  step: number;
}

export const SettingsSlider = (props: Props): ReactElement => {
  const [value, setValue] = useState(props.setting);

  const onChangeHandler = (e:ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value;
    if (props.localStorageKey) window.localStorage.setItem(props.localStorageKey, v);
    setValue(Number(v));
    props.setSetting(Number(v));
  }
  return (
    <StyledDiv>
      <div className="text">{props.text}</div>
      <div className="slider-wrapper">
        <input
          type="range"
          min={props.min}
          step={props.step}
          max={props.max}
          value={value}
          onChange={onChangeHandler}
        />
      </div>
    </StyledDiv>
  );
}
