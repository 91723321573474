import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_letter_spacing.svg";

export const LetterSpacingSelector = (): ReactElement => {

  const appContext = useContext(AppStateContext);

  const onClickHandler = (increment: number) => {
    const newValue = Number(appContext.viewerLetterSpacing) + increment;

    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_LETTER_SPACING, newValue.toString());
    appContext.setViewerLetterSpacing(newValue);
  }

  return (
    <div className="font-size-selector">
      <ButtonIconText
        data-for="tooltipDecreaseLetterSpacing"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        invert
        fixed
        onClick={()=>{onClickHandler(-0.025)}}
      />
      <ButtonIconText
        data-for="tooltipIncreaseLetterSpacing"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        fixed
        onClick={()=>{onClickHandler(+0.025)}}
      />
      <ReactTooltip id="tooltipDecreaseLetterSpacing" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.decreaseLetterSpacing")}</span>
      </ReactTooltip>
      <ReactTooltip id="tooltipIncreaseLetterSpacing" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.increaseLetterSpacing")}</span>
      </ReactTooltip>
    </div>
  );
}