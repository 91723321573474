import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ChangeEvent, ReactElement, useContext, useState } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  label{
    width: 40%;
    display: inline-block;
    text-align: left;
  }
  select{
  }

  /* https://www.esthersola.com/estilos-select-css/ */

  .select-css {
    width: 60%;
    height: 2rem;
    padding-left: .5rem;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    color: #444;
    line-height: 1.3;
    padding: .4em 1.4em .3em .8em;
    box-sizing: border-box;
    /* margin: 20px auto; */
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
    border-radius: .3em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: ${props => props.theme.fgColor1};
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
      linear-gradient(to bottom, #ffffff 0%,#f7f7f7 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
  }
  .select-css::-ms-expand {
      display: none;
  }
  .select-css:hover {
      border-color: #888;
  }
  .select-css:focus {
      border-color: #aaa;
      box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
      box-shadow: 0 0 0 3px -moz-mac-focusring;
      color: #222;
      outline: none;
  }
  .select-css option {
      font-weight:normal;
  }

  .classOfElementToColor:hover {background-color:red; color:black}

  .select-css option[selected] {
    background-color: orange;
  }

`;

export const LanguageSelector = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [selectedOption, setSelectedOption] = useState(appContext.language);

  const onChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    window.localStorage.setItem(Config.LOCALSTORAGE_APP__LANGUAGE, e.target.value);
    setSelectedOption(e.target.value);
    appContext.setLanguage(e.target.value);
  }

  return (
    <StyledDiv>
      <label htmlFor="langSelector">{i8nText(appContext.language, "global.language")}</label>
      <select id="langSelector" className="select-css" value={selectedOption} onChange={onChangeHandler}>
        {Object.keys(window.availableLanguages).map((lang, index)=>
          <option key={index} value={lang}>
            {Object.values(window.availableLanguages)[index]}
          </option>
        )}
      </select>
    </StyledDiv>
  );
}
