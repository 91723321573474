import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext, useState } from "react";
//https://github.com/markusenglund/react-switch
import Switch from "react-switch";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  label{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SoundSwitch = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [selectedOption, setSelectedOption] = useState(appContext.soundEnabled);

  const onChangeHandler = (checked: boolean) => {
    window.localStorage.setItem(Config.LOCALSTORAGE_APP__SOUND_ENABLED, checked.toString());
    setSelectedOption(checked);
    appContext.setSoundEnabled(checked);
  }

  return (
    <StyledDiv>
      <label>
        <span>{i8nText(appContext.language, "settings.soundEnabled")}</span>
        <Switch onChange={onChangeHandler} checked={selectedOption} />
      </label>
    </StyledDiv>
  );
}
