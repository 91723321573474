import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import img1 from "resources/images/jefferson-santos-fCEJGBzAkrU-unsplash.webp";
import logo from "resources/images/logo.svg";
import img2 from "resources/images/te-nguyen-Wt7XT1R6sjU-unsplash.webp";
import img3 from "resources/images/yoel-peterson-9GiiGtNVoSY-unsplash.webp";
import styled from "styled-components";


const StyledDiv = styled.div`
  color: ${props => props.theme.fgColor1};
  max-width: ${props => props.theme.bp.mobile};
  margin: 0 auto;
  padding-bottom: 4rem;

  h1{
    margin:0;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    text-align: center;
  }
  .logo{
    display: flex;
    justify-content: center;
    width: 7.5rem;
    height: auto;
    margin: 0 auto;
    padding-bottom: 2rem;
    img{
      width: 100%;
      height: auto;
    }
  }

  .row{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    max-width: ${props => props.theme.bp.mobile};
    overflow:hidden;
    width: 100%;

    .image{
      position:relative;
      flex-grow:1;
      max-height: 18rem;
      border-radius: 10px;
      overflow: hidden;
      flex-shrink: 0;
      height: 18rem;

      @media (max-width: ${props => props.theme.bp.mobile}){
        display: none;
      }

      img{
        width: 100%;
        height: auto;
        display:block;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }

    .section{
      position: relative;
      background-size: contain;
      background-size: 1024px;
      background-repeat: no-repeat;
      background-position: right;

      @media (max-width: ${props => props.theme.bp.mobile}){
        width: 100%;
      }

      &.latest-tabs{
        padding-right: 1rem;
        @media (max-width: ${props => props.theme.bp.mobile}){
          padding-right: 0rem;
        }
      }
      &.most-popular{
        padding-left: 1rem;
        @media (max-width: ${props => props.theme.bp.mobile}){
          padding-left: 0rem;
        }
      }
      &.top-users{
        padding-right: 1rem;
        @media (max-width: ${props => props.theme.bp.mobile}){
          padding-right: 0rem;
        }

        .username{
          text-align: left;
        }
      }

      &--title{
        font-size: 1.2rem;
        font-weight: 700;
        text-transform: uppercase;
        color: ${props => props.theme.fgColor2};

        @media (max-width: ${props => props.theme.bp.mobile}){
          padding-left: .625rem;
        }
      }
      &--content{
        font-size: .9rem;

        table{
          @media (max-width: ${props => props.theme.bp.mobile}){
            width: 100%;
          }

          thead{
            @media (max-width: ${props => props.theme.bp.mobile}){
              display:none;
            }
            th{
              text-align: left;
              text-transform: uppercase;
              text-decoration: none;
              color: #afafaf;
              font-size: .7rem;
              font-weight: 400;
              white-space: nowrap;
            }
            .username{
              text-align: center;
            }
            .creation-date{
              text-align: center;
            }
          }

          tbody{
            tr{
              @media (max-width: ${props => props.theme.bp.mobile}){
                border-top: 1px solid #282828;
                display: block;
              }
              &:first-child{
                border: none;
              }

              td{
                white-space: nowrap;

                &.performer{
                  text-transform: capitalize;
                  @media (max-width: ${props => props.theme.bp.mobile}){
                    display: none;
                  }
                }
                &.title{
                  text-transform: capitalize;
                  text-decoration: underline;
                  font-weight: 700;
                  @media (max-width: ${props => props.theme.bp.mobile}){
                    display: none;
                  }

                }
                &.username, &.totalViews{
                  text-align: center;

                  @media (max-width: ${props => props.theme.bp.mobile}){
                    display: none;
                  }

                }
                &.date, &.creation-date{

                  @media (max-width: ${props => props.theme.bp.mobile}){
                    display: none;
                  }
                }
              }
            }

            .mix{
              display:none;
              @media (max-width: ${props => props.theme.bp.mobile}){
                display: table-cell;
                padding: .625rem;
              }

              .label{
                text-transform: uppercase;
                color: #afafaf;
                font-size: .7rem;
                font-weight: 400;
                white-space: nowrap;

              }
              .performer{
                font-weight: 600;
                text-transform: capitalize;
              }
              .title{
                font-size: 1.1rem;
                font-weight: 600;
                text-transform: capitalize;
                white-space: initial;
              }
              .last-update{
                font-weight: 600;
                text-transform: capitalize;
              }
              .username{
                text-transform: none;
                padding-right: .625rem;
                font-weight: 600;
              }
              .rating{
              }
            }
          }
        }
      }
    }
  }
`;

type TopUser = {
  0: string;
  1: string;
};

type MostPopular = {
performer: string;
title: string;
totalViews: number;
userId: number;
username: string;
};

type LatestSong = {
creationDate: string;
performer: string;
title: string;
userId: number
username: string;
};


export const Homepage = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [mostPopular, setMostPopular] = useState<Array<MostPopular>>([]);
  const [latestSongs, setLatestSongs] = useState<Array<LatestSong>>([]);
  const [topUsers, setTopUsers] = useState<Array<TopUser>>([]);

  useEffect(() => {
    if (mostPopular.length === 0){
      axios({
        url: `${Config.STATS_SERVICE_URL}/most-popular?v=${Config.APP_VERSION}`,
        method: "GET",
        timeout: Config.REQUEST_TIMEOUT,
        headers: {
          "Content-Type": "application/json"
        },
        /*
        data: {
          username: username,
          password: password
        }
        */
      })
      .then(res => {
        //console.log("res most-popular", res)
        setMostPopular(res.data);
      })
      .catch(error => {
        console.log("Request error response:", error.response);
      })
      .finally(()=>{
      });
    }
  }, [mostPopular]);

  useEffect(() => {
    if (latestSongs.length === 0){
      axios({
        url: `${Config.STATS_SERVICE_URL}/latest-tabs?v=${Config.APP_VERSION}`,
        method: "GET",
        timeout: Config.REQUEST_TIMEOUT,
        headers: {
          "Content-Type": "application/json"
        },
        /*
        data: {
          username: username,
          password: password
        }
        */
      })
      .then(res => {
        // console.log("res latest-tabs", res.data)
        setLatestSongs(res.data);
      })
      .catch(error => {
        console.log("Request error response:", error.response);
      })
      .finally(()=>{
      });
    }
  }, [latestSongs]);

  useEffect(() => {
    if (topUsers.length === 0){
      axios({
        url: `${Config.STATS_SERVICE_URL}/top-users?v=${Config.APP_VERSION}`,
        method: "GET",
        timeout: Config.REQUEST_TIMEOUT,
        headers: {
          "Content-Type": "application/json"
        },
        /*
        data: {
          username: username,
          password: password
        }
        */
      })
      .then(res => {
        //console.log("res top-users", res)
        const myMap = new Map();
        Object.keys(res.data).map(key => {
          myMap.set(key,res.data[key]);
          return null;
        });
        // console.log("prueba",[...myMap.entries()].sort((a, b) => a[1] - b[1]).reverse())
        // var mapDesc = new Map( [...myMap.entries()].sort((a, b) => a[1] - b[1]).reverse() );
        setTopUsers([...myMap.entries()].sort((a, b) => a[1] - b[1]).reverse());
      })
      .catch(error => {
        console.log("Request error response:", error.response);
      })
      .finally(()=>{
      });
    }
  }, [topUsers]);

  const getSongURL = (element: LatestSong | MostPopular) => {
    return `${Config.PAGE_ROUTE__SONGVIEWER}?v=${Config.APP_VERSION}&performer=${encodeURIComponent(element.performer)}&title=${encodeURIComponent(element.title)}&userid=${element.userId}`;
  }

  return (
    <StyledDiv>
      <h1>
        {i8nText(appContext.language, "global.welcomeTo")} {i8nText(appContext.language, "global.appName")}
      </h1>
      <div className="logo">
        <img alt="logo" src={logo}/>
      </div>

      <div className="row">
        <div className="section latest-tabs">
          <div className="section--title">
            {i8nText(appContext.language, "summary.latestTabs")}
          </div>
          <div className="section--content">
            <table>
              <thead>
                <tr>
                  <th className="performer">{i8nText(appContext.language, "global.performer")}</th>
                  <th className="title">{i8nText(appContext.language, "global.title")}</th>
                  <th className="username">{i8nText(appContext.language, "global.tabbedBy")}</th>
                  <th className="creation-date">{i8nText(appContext.language, "global.date")}</th>
                </tr>
              </thead>
              <tbody>
                {latestSongs.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td className="performer"><a href={getSongURL(el)}>{el.performer}</a></td>
                      <td className="title"><a href={getSongURL(el)}>{el.title}</a></td>
                      <td className="username"><a href={getSongURL(el)}>{el.username}</a></td>
                      <td className="creation-date">{el.creationDate.split("T")[0]}</td>
                      <td className="mix">
                        <a href={getSongURL(el)}>
                          <div className="title"><span>{el.title}</span></div>
                          <div className="performer"><span className="label">{i8nText(appContext.language, "global.performer")}</span> <span>{el.performer}</span></div>
                          <div className="last-update"><span className="label">{i8nText(appContext.language, "songsViewer.lastUpdate")}</span> <span>{el.creationDate.split("T")[0]}</span></div>
                          <div className="username"><span className="label">{i8nText(appContext.language, "songsViewer.tabBy")}</span> <span>{el.username}</span></div>
                          <div className="rating"><span>&#9733;&#9733;&#9733;&#9733;&#9733;</span></div>
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="image">
          <img alt="guitar" src={img1}/>
        </div>

      </div>


      <div className="row">

        <div className="image">
          <img alt="guitar" src={img2}/>
        </div>

        <div className="section most-popular">
          <div className="section--title">
            {i8nText(appContext.language, "summary.mostPopular")}
          </div>
          <div className="section--content">
            <table>
              <thead>
                <tr>
                  <th className="performer">{i8nText(appContext.language, "global.performer")}</th>
                  <th className="title">{i8nText(appContext.language, "global.title")}</th>
                  <th className="username">{i8nText(appContext.language, "global.tabbedBy")}</th>
                  <th className="totalViews">{i8nText(appContext.language, "global.views")}</th>
                </tr>
              </thead>
              <tbody>
                {mostPopular.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td className="performer"><a href={getSongURL(el)}>{el.performer}</a></td>
                      <td className="title"><a href={getSongURL(el)}>{el.title}</a></td>
                      <td className="username"><a href={getSongURL(el)}>{el.username}</a></td>
                      <td className="totalViews">{el.totalViews}</td>

                      <td className="mix">
                        <a href={getSongURL(el)}>
                          <div className="title"><span>{el.title}</span></div>
                          <div className="performer"><span className="label">{i8nText(appContext.language, "global.performer")}</span> <span>{el.performer}</span></div>
                          <div className="username"><span className="label">{i8nText(appContext.language, "songsViewer.tabBy")}</span> <span>{el.username}</span></div>
                          <div className="total-views"><span className="label">{i8nText(appContext.language, "global.views")}</span> <span>{el.totalViews}</span></div>
                          <div className="rating"><span>&#9733;&#9733;&#9733;&#9733;&#9733;</span></div>
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="section top-users">
          <div className="section--title">
            {i8nText(appContext.language, "summary.topUsers")}
          </div>
          <div className="section--content">
            <table>
              <thead>
                <tr>
                  <th className="username">{i8nText(appContext.language, "global.username")}</th>
                  <th className="totalTabs">{i8nText(appContext.language, "global.total")}</th>
                </tr>
              </thead>
              <tbody>
                {topUsers.map((el, index) => {
                  return (
                    <tr key={index}>
                      <td className="username">{el[0]}</td>
                      <td className="totalViews">{el[1]}</td>
                      <td className="mix">
                        {/* <a href={getSongURL(el)}> */}
                          <div className="username"><span className="label">{i8nText(appContext.language, "songsViewer.tabBy")}</span> <span>{el[0]}</span></div>
                          <div className="total-tabs"><span className="label">{i8nText(appContext.language, "global.total")}</span> <span>{el[1]}</span></div>
                        {/* </a> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="image">
          <img alt="guitar" src={img3}/>
        </div>
      </div>
    </StyledDiv>
  );
}