import { AppStateContext } from "AppStateProvider";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  color: ${props => props.theme.fgColor1};
  text-align: center;
`;

export const PageNotFound = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  return (
    <StyledDiv>
      <h1>{i8nText(appContext.language, "global.pageNotFound")}</h1>
    </StyledDiv>
  );
}