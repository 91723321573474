import { AppStateContext } from "AppStateProvider";
import { AppBody } from "components/AppBody";
import { AppFooter } from "components/AppFooter";
import { AppHeader } from "components/AppHeader";
import { ChordListLoader } from "components/common/ChordListLoader";
import React, { ReactElement, useContext } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { Environment } from "types/common";

const BREAKPOINT = 1024;

const dark = {
  bp: {
    mobile: BREAKPOINT + "px",
    mini: BREAKPOINT / 2 + "px",
  }, //media query breakpoint. Usage: @media (max-width: ${props => props.theme.bp.mobile}){} @media (min-width: ${props => props.theme.bp.mobile}+1){}
  bgColor1: "#181b1f", //Dark grey
  bgColor2: "#20232a", //Dark grey (lighter)
  bgColor3: "#dedfe0", //Light grey
  fgColor1: "#ffffff", //White
  fgColor2: "#f5c02f", //Gold
  fgColor3: "lightgrey", //Light grey
  fontFamilyPrimary: "",
  fontFamilySecondary: "",
  fontFamilyTab: "",
};

const GlobalStyles = createGlobalStyle`
  a{
    text-decoration: none;
    color: ${dark.fgColor1};
  }
`;

const StyledDiv = styled.div`
  min-height: 100% !important;
  height: 100%;
  /* color: #000; */
  background-color: ${dark.bgColor1};
`;

export const App = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  if (window.environment === Environment.DEVELOPMENT) console.log("appContext::", appContext);

  return (
    <ThemeProvider theme={dark}>
      <ChordListLoader />
      <GlobalStyles />
      <StyledDiv>
        <AppHeader user={appContext.user} />
        <AppBody />
        <AppFooter />
      </StyledDiv>
    </ThemeProvider>
  );
};
