import { AppStateContext } from "AppStateProvider";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext, useState } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_bpm.svg";
import styled from "styled-components";

const RESET_TIME_IN_MS = 2000;

const StyledWrapperDiv = styled.div`
  white-space: pre-line;
  text-align: center;
`;

const StyledDiv = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-color: ${props => props.theme.bgColor1};
  border-radius: 1rem;
  border: 2px solid ${props => props.theme.bgColor1};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  div {
    width: 100%;
    height: 100%;
    mask: url(${icon});
    mask-size: cover;
    mask-position: center;
    background: ${props => props.theme.fgColor1};

    &:hover,
    &:focus {
      background: ${props => props.theme.fgColor2};
    }
  }

  &:active {
    border: 2px solid red;
  }
`;

type Props = {
  setBpm: (value: number) => void;
};

export const BpmCalculatorButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [clickMoment, setClickMoment] = useState<Array<number>>([]);
  const arrayAverage = (difArr: Array<number>) => difArr.reduce((a, b) => a + b, 0) / difArr.length;
  const msToBpm = (ms: number) => 60000 / ms;

  const onClickHandler = () => {
    const now = Date.now();
    if (clickMoment === [] || now - clickMoment[clickMoment.length - 1] > RESET_TIME_IN_MS) {
      setClickMoment([now]);
    } else {
      const newArr = clickMoment;
      newArr.push(now);
      const difArr: Array<number> = [];
      newArr.map((el, i, arr) => {
        if (i + 1 !== arr.length) {
          difArr.push(arr[i + 1] - el);
          const avg = arrayAverage(difArr);
          const bpm = msToBpm(avg);
          // console.log("onClickHandler -> bpm", bpm)
          props.setBpm(Math.round(bpm));
        }
        return null;
      });
      setClickMoment(newArr);
    }
  };

  return (
    <StyledWrapperDiv>
      <StyledDiv
        {...props}
        data-for="tooltipBpmButton"
        data-tip
        data-multiline={true}
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        onClick={onClickHandler}
      >
        <div />
      </StyledDiv>
      <ReactTooltip className="tooltip" id="tooltipBpmButton" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.calculateBpm")}</span>
      </ReactTooltip>
    </StyledWrapperDiv>
  );
};
