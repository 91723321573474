import styled from "styled-components";

export const DeviceSelectorDiv = styled.div`
  .desktop{
    display: block;
    @media (max-width: ${props => props.theme.bp.mobile}){
      display: none;
    }
  }

  .mobile{
    display: none;
    @media (max-width: ${props => props.theme.bp.mobile}){
      display: block;
    }
  }
`;