import { AppStateContext } from "AppStateProvider";
import { useKeyCodePress } from "components/common/useKeyCodePress";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import styled from "styled-components";
import { FullSongInfo } from "types/common";
import { EditSongButton } from "./EditSongButton";
import { FontSizeSelector } from "./FontSizeSelector";
import { LetterSpacingSelector } from "./LetterSpacingSelector";
import { LineHeightSelector } from "./LineHeightSelector";
import { MaximizeTabView } from "./MaximizeTabView";
import { PlayNextChordButton } from "./PlayNextChordButton";
import { PlayPrevChordButton } from "./PlayPrevChordButton";
import { ResetViewerSettingsButton } from "./ResetViewerSettingsButton";
import { TabColumnsSelector } from "./TabColumnsSelector";

const StyledDiv = styled.div`
  min-width: 18rem;
  flex-grow: 0;
  .title {
    font-size: 0.6rem;
    display: inline-block;
    color: gray;
    text-transform: uppercase;
  }
  .container {
    .row {
      display: flex;
    }
  }
`;

type Props = {
  calculatingMaxTab: boolean;
  setCalculatingMaxTab: (val: boolean) => void;
  autoFitFinished: boolean;
  setAutoFitFinished: (val: boolean) => void;
  chordPositionList: Array<string>;
  currentChordPosition: number;
  setCurrentChordPosition: (val: number) => void;
  songData: FullSongInfo;
};

export const ViewerUtilitiesToolbar = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const xKeyPressed = useKeyCodePress(
    88,
    () => {},
    () => {},
  );
  const zKeyPressed = useKeyCodePress(
    90,
    () => {},
    () => {},
  );

  return (
    <StyledDiv>
      <div className="title">{i8nText(appContext.language, "songsViewer.utilities")}</div>
      <div className="container">
        <div className="row">
          <FontSizeSelector />
          <LineHeightSelector />
          <TabColumnsSelector />
        </div>
        <div className="row">
          <MaximizeTabView
            // calculatingMaxTab={props.calculatingMaxTab}
            setCalculatingMaxTab={props.setCalculatingMaxTab}
            // autoFitFinished={props.autoFitFinished}
            setAutoFitFinished={props.setAutoFitFinished}
          />
          <LetterSpacingSelector />
          <ResetViewerSettingsButton />
          <PlayPrevChordButton
            chordPositionList={props.chordPositionList}
            currentChordPosition={props.currentChordPosition}
            setCurrentChordPosition={props.setCurrentChordPosition}
            keyPressed={zKeyPressed}
          />
          <PlayNextChordButton
            chordPositionList={props.chordPositionList}
            currentChordPosition={props.currentChordPosition}
            setCurrentChordPosition={props.setCurrentChordPosition}
            keyPressed={xKeyPressed}
          />
          <EditSongButton songData={props.songData} />
        </div>
      </div>
    </StyledDiv>
  );
};
