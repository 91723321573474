import React, { ChangeEvent, ReactElement, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { MessageBox } from "../../common/MessageBox";


// https://github.com/styled-components/styled-components-website/issues/392
const TextDialogGlobalStyle = createGlobalStyle`
  #textDialog{
    .foreground .animated {
      max-width: 75%;
      min-width: 50%;
      @media (max-width: ${props => props.theme.bp.mobile}){
        max-width: 90%;
        width: 90%;
      }
    }
    .messagebox{
      .text-dialog-message{
        .messagebox-title{
          background-repeat: no-repeat;
          background-position: -10px center;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-transform: uppercase;
          font-size: 1.2rem;
          font-weight: 700;
          margin-bottom: 2rem;
        }
        .messagebox-content{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          & > div{
            display: block;
            margin-top: .5rem;
            margin-bottom: .5rem;
          }

          textarea{
            width: 100%;
            flex-grow:1;
            border-width: 1px;
            border-radius: 10px;
            border: 1px solid darkgray;
            overflow: auto;
            //@ts-ignore
            background-color: ${props => props.theme.fgColor1};
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% - .4rem);
            height: calc(100% - .4rem);
            white-space: pre;
            resize: none;
            //font-family: 'Roboto Mono', monospace, sans-serif;
            color: black;
            font-size: 1rem;
            margin: 0;
            border: none;
          }
        }
      }
    }
  }
`

const StyledDiv = styled.div<Props>`
  width: 1.40rem;
  height: 1.40rem;
  border-radius: 1rem;
  background-color: ${props => props.theme.bgColor1};
  border: 2px solid ${props => props.theme.bgColor1};
  cursor: pointer;
  user-select: none;

  div{
    width: 100%;
    height: 100%;

    ${props => props.mask ? `
      mask: url(${props.mask});
      mask-size: cover;
      background: ${props.theme.fgColor1};
      mask-position:center;

      &:hover, &:focus {
        background: ${props.theme.fgColor2};
      }
    `:""}

    ${props => props.icon ? `
      background: url(${props.icon});
      background-size: cover;
      background-position:center;
    `:""}
  }
`;

type Props = {
  headerTitle: string;
  disabled?: boolean;
  setter: (val: string) => void;
  placeholderProp?: string;
  propertyProp: string;
  icon?: string;
  mask?: string;
  "data-for"?: string;
  "data-tip"?: unknown;
  "data-tip-disable"?: boolean;
}

export const TextEditorButton = (props: Props): ReactElement => {
  const [textDialogVisible, setTextDialogVisible] = useState(false);

  const onClickButtonHandler = () => {
    setTextDialogVisible(true);
  }

  const onChangeTextAreaHandler = (e:ChangeEvent<HTMLTextAreaElement>) => {
    props.setter(e.target.value);
  }

  return (
    <>
      <StyledDiv {...props} onClick={onClickButtonHandler}><div/></StyledDiv>

      <MessageBox id="textDialog" visible={textDialogVisible} closeButton onClose={()=>setTextDialogVisible(false)}>
        <TextDialogGlobalStyle/>
        <div className="text-dialog-message">
          <div className="messagebox-title">
            {props.headerTitle}
          </div>
          <div className="messagebox-content">
            <textarea
              name="text-textarea"
              id="text-textarea"
              rows={10}
              wrap="off"
              tabIndex={-1}
              placeholder={props.placeholderProp}
              spellCheck={false}
              defaultValue={props.propertyProp}
              onChange={onChangeTextAreaHandler}
              {...props.disabled===true ? {"disabled":true}:{}}
            />
          </div>
        </div>
      </MessageBox>
    </>
  );
}