import { AppStateContext } from "AppStateProvider";
import { getGuitarSoundFromStringAndFret } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import styled from "styled-components";

const StyledDiv = styled.div<Props>`
  display: inline;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 255, 0, 0.5);
  }
  span {
    &.empty {
    }
    &.dot {
      line-height: 0;
      font-size: 1rem;
      position: relative;
      text-shadow: 0 0 3px ${props => props.theme.fgColor1};
    }
    &.cross {
      line-height: 0;
      font-size: 0.8rem;
      position: relative;
      color: ${props => props.theme.fgColor1};
      text-shadow: 0 0 3px ${props => props.theme.fgColor1};
    }
  }
`;

type Props = {
  string: number;
  stringState: number | null;
  fret: number;
  fretState: number | null;
  buttonWidth: number;
  changeStringStateHandler: (string: number, state: number | null) => void;
};

export const GuitarFretboardStringFretButton = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const audio = getGuitarSoundFromStringAndFret(props.string, props.fret, appContext.transposition);

  const onClickHandler = () => {
    if (appContext.soundEnabled) {
      if (!audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }
      audio.play();
    }

    if (props.fret === 0) {
      if (props.fretState === null) {
        props.changeStringStateHandler(props.string, props.fret);
      } else if (props.fretState === 0) {
        props.changeStringStateHandler(props.string, -1);
      } else if (props.fretState === -1) {
        props.changeStringStateHandler(props.string, null);
      }
    } else {
      if (props.fretState === null) {
        props.changeStringStateHandler(props.string, props.fret);
      } else {
        props.changeStringStateHandler(props.string, null);
      }
    }
  };

  const getState = () => {
    if (props.fretState === null) {
      return <span className="empty"></span>;
    } else if (props.fretState === -1) {
      // return <span className="cross">&#10060;</span>;
      return (
        <span role="img" aria-label="cross" className="cross">
          ❌
        </span>
      );
    } else {
      // return <span className="dot">&#9673;</span>;
      return <span className="dot">◉</span>;
    }
  };

  return (
    <StyledDiv
      {...props}
      onClick={onClickHandler}
      style={{ width: props.buttonWidth + "%" }}
    >
      {getState()}
    </StyledDiv>
  );
};
