//import React, {useContext} from "react";
//import { AppStateContext } from "AppStateProvider";
import { Chord, ChordString, Environment } from "types/common";
import { isNil } from "lodash";
import { Config } from "./Config";

//////////////////////////////////////////////////////////////////////////////
// GENERAL USE                                                              //
//////////////////////////////////////////////////////////////////////////////
/**
 * Get the next sibling of an element that match a selector
 * https://gomakethings.com/finding-the-next-and-previous-sibling-elements-that-match-a-selector-with-vanilla-js/
 * @param {*} elem
 * @param {*} selector if a selector isn’t provided, return the first nextElementSibling.
 */
export const getNextSibling = (elem: Element, selector: string): Element | undefined => {
  // Get the next sibling element
  let sibling = elem.nextElementSibling;

  // If there's no selector, return the first sibling
  if (!selector) return sibling ?? undefined;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) return sibling ?? undefined;
    sibling = sibling.nextElementSibling;
  }
};
/**
 * Parse html from HTML string
 * Source: http://youmightnotneedjquery.com/
 * @param {string} str The html string
 * @returns {HTMLDocument} HTMLDocument parsed from the html string
 */
export const parseHTML = (str: string): HTMLCollection => {
  const tmp = document.implementation.createHTMLDocument();
  tmp.body.innerHTML = str;
  return tmp.body.children;
};

//////////////////////////////////////////////////////////////////////////////
// LOCALSTORAGE CACHE                                                       //
//////////////////////////////////////////////////////////////////////////////
export const saveResToCache = (resourceURL: string, response: string): void => {
  const resourceNameInLocalStorage = (Config.LOCALSTORAGE_CACHE__RESOURCE_PREFIX + resourceURL).toLowerCase();
  if (window.environment === Environment.DEVELOPMENT) console.log("Storing to cache: <" + resourceNameInLocalStorage + ">");
  window.localStorage.setItem(resourceNameInLocalStorage, JSON.stringify(response));
};
export const loadResFromCache = (resourceURL: string): unknown => {
  const resourceNameInLocalStorage = (Config.LOCALSTORAGE_CACHE__RESOURCE_PREFIX + resourceURL).toLowerCase();
  const data = window.localStorage.getItem(resourceNameInLocalStorage);
  if (data == null) {
    if (window.environment === Environment.DEVELOPMENT) console.log("Not found in cache: <" + resourceNameInLocalStorage + ">");
    return null;
  } else {
    if (window.environment === Environment.DEVELOPMENT) console.log("Loading from cache: <" + resourceNameInLocalStorage + ">");
    return JSON.parse(data);
  }
};
export const invalidateCache = (resourceURL: string): void => {
  const resourceNameInLocalStorage = (Config.LOCALSTORAGE_CACHE__RESOURCE_PREFIX + resourceURL).toLowerCase();
  if (window.environment === Environment.DEVELOPMENT) console.log("Removing from cache: <" + resourceNameInLocalStorage + ">");
  window.localStorage.removeItem(resourceNameInLocalStorage);
};
//////////////////////////////////////////////////////////////////////////////
// MEMORY CACHE                                                             //
//////////////////////////////////////////////////////////////////////////////
/**
 * Create a map in memory with a list of found chords in the BIG chordList
 * @param {array} chordNamesArray Array of chord names ["Bm", "A", ...]
 * @param {array} chordList Full chordList array (see ChordListLoader.js)
 */
export const addChordsToMemoryChordMap = (chordNamesArray: Array<string>, chordList: Array<Chord>): void => {
  if (isNil(chordList)) {
    console.warn("Empty chordList!");
  } else {
    if (isNil(window.chordMap)) {
      window.chordMap = new Map();
    }

    chordNamesArray.map(chordName => {
      if (!window.chordMap.has(chordName)) {
        let theChord: Chord | undefined = undefined;
        for (const chord of chordList) {
          if (chord.priority === 1 && chord.name === chordName) {
            theChord = chord;
            break;
          }
        }
        if (isNil(theChord)) {
          for (const chord of chordList) {
            if (chord.priority === 0 && chord.name === chordName) {
              theChord = chord;
              break;
            }
          }
        }
        if (!isNil(theChord)) {
          window.chordMap.set(chordName, theChord);
        }
      }
    });
  }
};

export const getChordFromMemoryChordMap = (chordName: string): Chord | undefined => {
  if (isNil(window.chordMap)) {
    return undefined;
  }
  const chord = window.chordMap.get(chordName);
  if (isNil(chord)) {
    return undefined;
  }
  return chord;
};

export const addChordToMemoryChordMap = (chordName: string, chordDataObj: Chord): void => {
  if (isNil(window.chordMap)) {
    window.chordMap = new Map();
  }
  window.chordMap.set(chordName, chordDataObj);
};

export const getChordFromChordList = (chordName: string, chordList: Array<Chord>): Chord | undefined => {
  if (!isNil(chordList)) {
    for (const chord of chordList) {
      if (chord.priority === 1 && chord.name === chordName) {
        return chord;
      }
    }
  }
  return undefined;
};

export const getChord = (chordName: string, chordList: Array<Chord>): Chord | undefined => {
  let myChord = getChordFromMemoryChordMap(chordName);
  if (!isNil(myChord)) {
    return myChord;
  }
  myChord = getChordFromChordList(chordName, chordList);
  if (myChord) {
    addChordToMemoryChordMap(chordName, myChord);
    return myChord;
  }
  return undefined;
};

//////////////////////////////////////////////////////////////////////////////
// SONG EDITOR UTILS                                                        //
//////////////////////////////////////////////////////////////////////////////
export const setCharAt = (str: string, chr: string, pos: number): string => {
  return str.substring(0, pos) + chr + str.substring(pos + 1);
};

export const insertCharAt = (str: string, chr: string, pos: number): string => {
  return str.substring(0, pos) + chr + str.substring(pos);
};

/**
 * Returns a string formed by chr repeated n times
 * i.e.
 * getStringFromChar("@", 4) returns "@@@@"
 *
 * @param {String} chr
 * @param {Int} n
 */
export const getStringFromChar = (chr: string, n: number): string => {
  let str = "";
  for (let i = 0; i < n; i++) {
    str += chr;
  }
  return str;
};

export const insertChordInLineAtPosition = (line: string, chordName: string, position: number): string => {
  let newLine = line;
  const spaceChr = " ";
  let pos = position;

  if (position > line.length - 1) {
    newLine += getStringFromChar(spaceChr, position - line.length + 1);
  }

  let prevChar = line[pos - 1];
  let nextChar = line[pos];
  let prevCharIsBlank = isNil(prevChar) || prevChar === spaceChr;
  let nextCharIsBlank = isNil(nextChar) || nextChar === spaceChr;

  // console.log("prevChar",prevChar);
  // console.log("nextChar",nextChar);
  // console.log("prevCharIsBlank",prevCharIsBlank);
  // console.log("nextCharIsBlank",nextCharIsBlank);

  if (prevCharIsBlank && nextCharIsBlank) {
    //Do nothing
  } else if (prevCharIsBlank) {
    //Do nothing
  } else if (nextCharIsBlank) {
    pos++;
    newLine = insertCharAt(newLine, spaceChr, pos);
  } else {
    //Pos in the middle of a chord
    while (!nextCharIsBlank) {
      pos++;
      prevChar = line[pos - 1];
      nextChar = line[pos];
      prevCharIsBlank = isNil(prevChar) || prevChar === spaceChr;
      nextCharIsBlank = isNil(nextChar) || nextChar === spaceChr;
    }
    pos++;
  }

  let i = 0;
  while (i < chordName.length) {
    if (newLine[pos + i] === spaceChr) {
      newLine = setCharAt(newLine, chordName[i], pos + i);
    } else {
      newLine = insertCharAt(newLine, spaceChr, pos + i);
      newLine = setCharAt(newLine, chordName[i], pos + i);
    }
    i++;
  }
  // console.log("i",i)
  // console.log("newLine[i]",newLine[i])
  if (pos + i < newLine.length && newLine[pos + i] !== spaceChr) {
    newLine = insertCharAt(newLine, spaceChr, pos + i);
  }

  return newLine;
};

export const getCursorColumn = (tab: string, cursorPosition: number): number => {
  let positionInLine = 0;
  if (cursorPosition === 0) {
    //console.log("positionInLine",positionInLine);
  } else {
    for (let i = cursorPosition - 1; i >= 0; i--) {
      const char = tab[i];
      if (char === "\n") {
        //console.log("positionInLine",positionInLine);
        break;
      } else if (i === 0) {
        positionInLine++;
        //console.log("positionInLine",positionInLine);
        break;
      } else {
        positionInLine++;
      }
    }
  }

  return positionInLine;
};
export const getCursorRow = (tab: string, cursorPosition: number): number => {
  const strBefore = tab.substring(0, cursorPosition);
  const lineBreakArray = strBefore.split("\n");
  return lineBreakArray.length - 1;
};

export const getCursorPositionFromRowAndColumn = (tab: string, row: number, column: number): number => {
  let cursorPosition = 0;
  const tabArray = tab.split("\n");
  for (let i = 0; i < row; i++) {
    cursorPosition += tabArray[i].length + 1;
  }
  return cursorPosition + column;
};

/**
 *  Returns the string line at an specific row
 *
 * @param {Int} row Row number, if row<0 then the function returns undefined
 */
export const getStringAtRow = (tab: string, row: number): string => {
  const lineBreakArray = tab.split("\n");
  return lineBreakArray[row];
};

/**
 * Returns the line tipe depending on the input line. Posible values are:
 * Config.LINE_TYPE__LYRICS
 * Config.LINE_TYPE__SECTION
 * Config.LINE_TYPE__LINEBREAK
 * Config.LINE_TYPE__CHORDS
 *
 * @param {String} line
 */
export const getLineType = (line: string): string | undefined => {
  if (isNil(line)) {
    return undefined;
  }

  const chordLineValidChars = " ABCDEFGm7suadijx123456890/b#-+()[]{}*|\t";
  let type = Config.LINE_TYPE__LYRICS;

  if (line.trim() === "") {
    type = Config.LINE_TYPE__LINEBREAK;
  } else if (line.startsWith("[") && line.endsWith("]")) {
    type = Config.LINE_TYPE__SECTION;
  } else {
    let lyricsCharFound = false;
    for (let i = 0; i < line.length; i++) {
      if (chordLineValidChars.indexOf(line[i]) === -1) {
        lyricsCharFound = true;
        break;
      }
    }
    if (!lyricsCharFound) {
      type = Config.LINE_TYPE__CHORDS;
    }
  }
  return type;
};

export const replaceRow = (str: string, line: string, pos: number): string => {
  const strArray = str.split("\n");
  let newStr = "";
  for (let i = 0; i < strArray.length; i++) {
    if (i === pos) {
      newStr += line + "\n";
    } else {
      newStr += strArray[i] + "\n";
    }
  }
  return newStr;
};

//////////////////////////////////////////////////////////////////////////////
// GUITAR SOUNDS UTILS                                                      //
//////////////////////////////////////////////////////////////////////////////
/**
 * Plays a chord
 * @param {int[]} chord Array of int that represents the chord, for example [3,2,0,0,3,3]
 *                      Note that values equal to -1 will be ignored
 * @param {int} transposition Transposition to be applied to the chord
 */
export const playChord = (chord: ChordString, transposition: number): void => {
  if (!chord) return undefined;

  const stopPrevChord = true;
  const riffSpeed = 300;
  if (stopPrevChord && !isNil(window.audioArray)) {
    //stop all ongoing sounds
    window.audioArray.map(audio => {
      if (!audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }
    });
  }

  const audioArray: Array<HTMLAudioElement> = [];
  chord.map((e, index) => {
    if (e !== null && e !== -1) {
      audioArray.push(getGuitarSoundFromStringAndFret(5 - index + 1, e, transposition));
    }
  });

  /* TODO Mirar si es necesario manejar el play como una Promise
  const audioPromise = audio.play();
  if (audioPromise !== undefined) {
    audioPromise
      .then(res => {
        // autoplay started
      })
      .catch(err => {
        // catch dom exception
        console.info(err)
      })
  }
  */

  if (stopPrevChord) window.audioArray = audioArray;
  // if (riffSpeed === 0){
  //   audioArray.map(audio => audio.play());
  // } else {
  const timeInc = riffSpeed / 6;
  audioArray.map((audio, index) => {
    setTimeout(function () {
      audio.play();
    }, timeInc * index);
  });
  // }
};

export const getGuitarSoundFromStringAndFret = (
  string: number,
  fret: number,
  transposition: number,
): HTMLAudioElement => {
  const key = `${string.toString()}_${fret.toString()}`;
  const arrayPos: number | undefined = window.guitarSoundsMap.get(key);
  const arrayPosTransp: number = (arrayPos ?? 0) + transposition + 4;
  return window.guitarSounds[arrayPosTransp];
};

//////////////////////////////////////////////////////////////////////////////
// i8n                                                                      //
//////////////////////////////////////////////////////////////////////////////
export const i8nText = (lang: string, key: string): string => {
  const availLang = Object.keys(window.availableLanguages);

  let text = "%" + key + "%";
  if (window.dictionary[availLang[0]][key] !== undefined) text = window.dictionary[availLang[0]][key];
  if (window.dictionary[lang][key] !== undefined) text = window.dictionary[lang][key];

  return text;
};
