//https://gist.github.com/joyrexus/7304146
/**
 * Behaves the same as setInterval except uses requestAnimationFrame() where possible for better performance
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
// window.requestInterval = function(fn, delay) {
//   var start = Date.now(),
//     handle = {};

import { isNil } from "lodash";
import { Environment } from "types/common";

//   function loop() {
//     var current = Date.now(),
//       delta = current - start;

//     if(delta >= delay) {
//       fn.call();
//       start = Date.now();
//     }

//     handle.value = requestAnimationFrame(loop);
//   }

//   handle.value = requestAnimationFrame(loop);
//   return handle;
// }

/**
 * Behaves the same as clearInterval except uses cancelRequestAnimationFrame() where possible for better performance
 * @param {int|object} fn The callback function
 */
// window.clearRequestInterval = function(handle) {
//   if (typeof handle !== 'undefined'){
//     window.cancelAnimationFrame(handle.value)
//   }
// }

/**
 * Behaves the same as setTimeout except uses requestAnimationFrame() where possible for better performance
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */
// window.requestTimeout = function(fn, delay) {
//   var start = Date.now(),
//     handle = {};

//   function loop(){
//     var current = Date.now(),
//       delta = current - start;

//     delta >= delay ? fn.call() : handle.value = requestAnimationFrame(loop);
//   }

//   handle.value = requestAnimationFrame(loop);
//   return handle;
// }

/**
 * Behaves the same as clearTimeout except uses cancelRequestAnimationFrame() where possible for better performance
 * @param {int|object} fn The callback function
 */
// window.clearRequestTimeout = function(handle) {
//   if (typeof handle !== 'undefined'){
//     window.cancelAnimationFrame(handle.value)
//   }
// }


const createGuitarSoundsMap = () => {
  if (isNil(window.guitarSoundsMap)){
    const myMap = new Map<string, number>();
    myMap.set("6_0",  0);
    myMap.set("6_1",  1);
    myMap.set("6_2",  2);
    myMap.set("6_3",  3);
    myMap.set("6_4",  4);
    myMap.set("6_5",  5);
    myMap.set("6_6",  6);
    myMap.set("6_7",  7);
    myMap.set("6_8",  8);
    myMap.set("6_9",  9);
    myMap.set("6_10", 10);
    myMap.set("6_11", 11);
    myMap.set("6_12", 12);

    myMap.set("5_0",  5);
    myMap.set("5_1",  6);
    myMap.set("5_2",  7);
    myMap.set("5_3",  8);
    myMap.set("5_4",  9);
    myMap.set("5_5",  10);
    myMap.set("5_6",  11);
    myMap.set("5_7",  12);
    myMap.set("5_8",  13);
    myMap.set("5_9",  14);
    myMap.set("5_10", 15);
    myMap.set("5_11", 16);
    myMap.set("5_12", 17);

    myMap.set("4_0",  10);
    myMap.set("4_1",  11);
    myMap.set("4_2",  12);
    myMap.set("4_3",  13);
    myMap.set("4_4",  14);
    myMap.set("4_5",  15);
    myMap.set("4_6",  16);
    myMap.set("4_7",  17);
    myMap.set("4_8",  18);
    myMap.set("4_9",  19);
    myMap.set("4_10", 20);
    myMap.set("4_11", 21);
    myMap.set("4_12", 22);

    myMap.set("3_0",  15);
    myMap.set("3_1",  16);
    myMap.set("3_2",  17);
    myMap.set("3_3",  18);
    myMap.set("3_4",  19);
    myMap.set("3_5",  20);
    myMap.set("3_6",  21);
    myMap.set("3_7",  22);
    myMap.set("3_8",  23);
    myMap.set("3_9",  24);
    myMap.set("3_10", 25);
    myMap.set("3_11", 26);
    myMap.set("3_12", 27);

    myMap.set("2_0",  19);
    myMap.set("2_1",  20);
    myMap.set("2_2",  21);
    myMap.set("2_3",  22);
    myMap.set("2_4",  23);
    myMap.set("2_5",  24);
    myMap.set("2_6",  25);
    myMap.set("2_7",  26);
    myMap.set("2_8",  27);
    myMap.set("2_9",  28);
    myMap.set("2_10", 29);
    myMap.set("2_11", 30);
    myMap.set("2_12", 31);

    myMap.set("1_0",  24);
    myMap.set("1_1",  25);
    myMap.set("1_2",  26);
    myMap.set("1_3",  27);
    myMap.set("1_4",  28);
    myMap.set("1_5",  29);
    myMap.set("1_6",  30);
    myMap.set("1_7",  31);
    myMap.set("1_8",  32);
    myMap.set("1_9",  33);
    myMap.set("1_10", 34);
    myMap.set("1_11", 35);
    myMap.set("1_12", 36);

    window.guitarSoundsMap = myMap;
  }
}

const loadGuitarSounds = () => {
  if (isNil(window.guitarSounds)){
    if (window.environment === Environment.DEVELOPMENT) console.log("Loading guitarSounds...");
    const guitarSoundsArray = [];
    for (let i = 0; i < 48; i++) {
      const filename = (i+96).toString().padStart(3, "0") + ".mp3";
      const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/${filename}`);
      audio.volume = 1/6;
      audio.load();
      guitarSoundsArray.push(audio);
    }
    window.guitarSounds = guitarSoundsArray;
    if (window.environment === Environment.DEVELOPMENT) console.log("guitarSounds loaded!");
  }
}

export const initializeApp = (): void => {
  createGuitarSoundsMap();
  loadGuitarSounds();
}
