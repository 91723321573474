import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import logo from "resources/images/logo.svg";
import styled /*, { keyframes }*/ from "styled-components";

// const blinkingText = keyframes`
//   0%   {color: #FFF}
//   49%  {color: #FFF}
//   60%  {color: transparent}
//   99%  {color:transparent}
//   100% {color: #FFF}
// `;

const StyledDiv = styled.div`
    text-align: center;
    color: ${props => props.theme.fgColor1};
    max-width: ${props => props.theme.bp.mobile};
    margin: 0 auto;

    h1{
      margin:0;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 100%;
    }
    .logo{
        display: flex;
        justify-content: center;
        width: 7.5rem;
        height: auto;
        margin: 0 auto;
        img{
          width: 100%;
          height: auto;
        }
    }
    .section-title{
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .help{
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 3rem;
      width: 60%;

      @media (max-width: ${props => props.theme.bp.mobile}){
        width: 95%;
      }

      & > div {
        width: 33.333%;
        max-width: 12rem;
        white-space: pre-line;
      }
    }
`;

export const AboutPage = (): ReactElement => {
  const appContext = useContext(AppStateContext);
  return (
    <StyledDiv>
      <h1>
        {i8nText(appContext.language, "header.about")} {i8nText(appContext.language, "global.appName")} v{Config.APP_VERSION}
      </h1>
      <div className="logo">
        <img alt="logo" src={logo}/>
      </div>
      <br/>
      <div className="help">
        <div className="songs">
          <div className="section-title">{i8nText(appContext.language, "homepage.songSelector")}</div>
          <div>{i8nText(appContext.language, "homepage.songSelector.info")}</div>
        </div>
        <div className="viewer">
          <div className="section-title">{i8nText(appContext.language, "homepage.songViewer")}</div>
          <div>{i8nText(appContext.language, "homepage.songViewer.info")}</div>
        </div>
        <div className="editor">
          <div className="section-title">{i8nText(appContext.language, "homepage.songEditor")}</div>
          <div>{i8nText(appContext.language, "homepage.songEditor.info")}</div>
        </div>
      </div>
    </StyledDiv>
  );
}