import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext } from "react";
import styled from "styled-components";
import { SongInfo, SongList } from "types/common";
import { DeleteSongButton } from "./DeleteSongButton";
import { FavoriteSongButton } from "./FavoriteSongButton";

const StyledTr = styled.tr`
  color: ${props => props.theme.fgColor1};
  background-color: ${props => props.theme.bgColor1};
  border-top: 1px solid #282828;

  @media (max-width: ${props => props.theme.bp.mobile}) {
    &:first-child {
      border-top: none;
    }
  }

  &:hover {
    background-color: #282828;
    color: gold;
    cursor: pointer;
    a {
      color: gold;
    }
  }

  td {
    text-align: left;
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 600;
    white-space: nowrap;

    @media (max-width: ${props => props.theme.bp.mobile}) {
      white-space: initial;
    }

    &.performer {
      padding: 0.625rem 0.625rem 0.625rem 0;
      @media (max-width: ${props => props.theme.bp.mobile}) {
        display: none;
      }

      &:hover {
        a {
          span {
            text-decoration: underline;
          }
        }
      }
    }
    &.title {
      padding: 0.625rem 0.625rem 0.625rem 0;
      @media (max-width: ${props => props.theme.bp.mobile}) {
        display: none;
      }

      &:hover {
        a {
          span {
            text-decoration: underline;
          }
        }
      }
    }
    &.last-update {
      text-align: center;
      @media (max-width: ${props => props.theme.bp.mobile}) {
        display: none;
      }
    }
    &.username {
      padding: 0.625rem 0.625rem 0.625rem 0;
      text-align: center;
      text-transform: none;
      span {
        left: 0;
      }
      @media (max-width: ${props => props.theme.bp.mobile}) {
        display: none;
      }
    }
    &.rating {
      text-align: center;
      @media (max-width: ${props => props.theme.bp.mobile}) {
        display: none;
      }
    }

    &.wastebin {
      padding: 0 0.625rem;
      /* > div{
        filter: grayscale(1) brightness(2);
      } */
    }

    &.mix {
      display: none;
      @media (max-width: ${props => props.theme.bp.mobile}) {
        display: table-cell;
        padding: 0.625rem;
      }

      .label {
        text-transform: uppercase;
        color: #afafaf;
        font-size: 0.7rem;
        font-weight: 400;
        white-space: nowrap;
      }
      .performer {
      }
      .title {
        font-size: 1.1rem;
      }
      .last-update {
      }
      .username {
        text-transform: none;
        padding-right: 0.625rem;
      }
      .rating {
      }
    }

    &.ellipsis {
      position: relative;
      &:before {
        content: "&nbsp;";
        visibility: hidden;
      }

      span {
        position: absolute;
        left: 0.625rem;
        right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

type Props = {
  songInfo: SongInfo;
  songList: SongList;
  //currentSongHandler?: () => void;
  setSongList: (songList: SongList) => void;
};

export const SongsTableRow = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  const getSongURL = () => {
    return `${Config.PAGE_ROUTE__SONGVIEWER}?v=${Config.APP_VERSION}&performer=${encodeURIComponent(
      props.songInfo.performer,
    )}&title=${encodeURIComponent(props.songInfo.title)}&userid=${props.songInfo.userId}`;
  };

  return (
    <StyledTr>
      {isNil(appContext.user) ? (
        <></>
      ) : (
        <td className="favorite">
          <FavoriteSongButton songInfo={props.songInfo} songList={props.songList} setSongList={props.setSongList} />
        </td>
      )}

      <td className="performer ellipsis">
        <a href={getSongURL()}>
          <span>{props.songInfo.performer}</span>
        </a>
      </td>

      <td className="title ellipsis">
        <a href={getSongURL()}>
          <span>{props.songInfo.title}</span>
        </a>
      </td>

      <td className="last-update">
        <a href={getSongURL()}>{props.songInfo.lastModified.split("T")[0]}</a>
      </td>

      <td className="username ellipsis">
        <a href={getSongURL()}>
          <span className="user">
            {props.songInfo.username}
            {appContext.user && props.songInfo.username === appContext.user.username
              ? " (" + i8nText(appContext.language, "global.me") + ")"
              : ""}
          </span>
        </a>
      </td>

      <td className="rating">&#9733;&#9733;&#9733;&#9733;&#9733;</td>

      <td className="mix">
        <a href={getSongURL()}>
          <div className="title">
            <span>{props.songInfo.title}</span>
          </div>
          <div className="performer">
            <span className="label">{i8nText(appContext.language, "global.performer")}</span>{" "}
            <span>{props.songInfo.performer}</span>
          </div>
          <div className="last-update">
            <span className="label">{i8nText(appContext.language, "songsViewer.lastUpdate")}</span>{" "}
            <span>{props.songInfo.lastModified.split("T")[0]}</span>
          </div>
          <div className="username">
            <span className="label">{i8nText(appContext.language, "songsViewer.tabBy")}</span>{" "}
            <span>{props.songInfo.username}</span>
          </div>
          <div className="rating">
            <span>&#9733;&#9733;&#9733;&#9733;&#9733;</span>
          </div>
        </a>
      </td>
      {isNil(appContext.user) ? (
        <></>
      ) : (
        <td className="wastebin">
          <DeleteSongButton songInfo={props.songInfo} songList={props.songList} setSongList={props.setSongList} />
        </td>
      )}
    </StyledTr>
  );
};
