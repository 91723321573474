import { AppStateContext } from "AppStateProvider";
import axios from "axios";
import { Config } from "components/common/Config";
import { ImagePreload } from "components/common/ImagePreload";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ChangeEvent, FormEvent, ReactElement, useContext, useState } from "react";
import iconSpinner from "resources/icons/animated_spinner_white.svg";
import styled from "styled-components";

const SpinnerDiv = styled.div`
  width: 100%;
  height: 2.75rem;
  box-sizing: border-box;
  background-color: rgb(245, 245, 255);
  border-radius: 12px;
  position: relative;
  text-align: center;
  margin: .2rem;
  background-color: ${props => props.theme.bgColor2};
  color: ${props => props.theme.fgColor1};
  border: 2px solid ${props => props.theme.bgColor2};
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 2rem;
  }
`;

const StyledDiv = styled.div`
  color: ${props => props.theme.fgColor1};
  text-align: center;
  max-width: ${props => props.theme.bp.mini};
  margin: 0 auto;

  p{
    white-space: break-spaces;
    span{
      color: ${props => props.theme.fgColor2};
    }
  }

  .form-wrapper{
    width: 100%;
    text-align: center;

    .body{
      form{
        margin: 0 auto;
        width: 18rem;
        max-width: 100%;

        input{
          display: block;
          width: 100%;
          padding: .7rem 0.5rem;
          box-sizing: border-box;
          background-color: rgb(245, 245, 255);
          border-radius: 12px;
          position: relative;
          text-align: center;
          margin: .2rem;
          min-height: initial;
          text-align: left;
          border: 2px solid lightgray;
          &:hover{
            border-color: gray;
          }
        }

        input[error="true"]{
          border: 2px solid red;
        }

        input[type="submit"]{
          text-align: center;
          background-color: ${props => props.theme.bgColor2};
          color: ${props => props.theme.fgColor1};
          font-weight: 700;
          font-size: 1rem;
          border: 2px solid ${props => props.theme.bgColor2};
          cursor: pointer;
          &:hover{
            color: ${props => props.theme.fgColor2};
          }
        }
        a{
          font-size: .7rem;
          font-weight: 700;
          color: ${props => props.theme.bgColor2};
        }
        .errorMessage{
          color: red;
          font-size: .65rem;
          font-weight: 700;
          margin: 0;
          margin-block-start: 0;
          margin-block-end: 0;
        }
      }
    }
  }
`;

export const ResetPasswordPage = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  //Validate token request param
  const token = new URLSearchParams(appContext.location.search).get("token");
  // console.log("token", token);

  let md5, emailLetters, emailDomain, num;
  let tokenTemplateIsOK = true;

  if (!token){
    tokenTemplateIsOK = false;
  } else {
    const tokenSplitted = token.split("|");


    if (tokenSplitted.length !== 4){
      tokenTemplateIsOK = false;
    } else {
      md5 = tokenSplitted[0];
      emailLetters = tokenSplitted[1];
      emailDomain = tokenSplitted[2];
      num = tokenSplitted[3];
      // console.log("md5", md5)
      // console.log("emailLetters", emailLetters)
      // console.log("emailDomain", emailDomain)

      if (md5.length !== 32 || emailLetters.length !== 2 || emailDomain.length === 0 || num.length === 0){
        tokenTemplateIsOK = false;
      }
    }
  }

  // console.log("tokenTemplateIsOK", tokenTemplateIsOK)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  // eslint-disable-next-line
  const [validToken, setValidToken] = useState(tokenTemplateIsOK);

  const changePassword = () => {
    setRequestInProgress(true);
    axios({
      url: `${Config.CHANGE_PASSWORD_SERVICE_URL}`,
      method: "POST",
      timeout: Config.REQUEST_TIMEOUT,
      headers: {
        "Content-Type": "application/json"
      },
      data: {
        email: email,
        password: password,
        token: token
      }
    })
    .then(() => {
      setRequestInProgress(false);
      window.location.replace(Config.PAGE_ROUTE__PASSWORDRESTORED);
    })
    .catch((error) => {
      console.log("Request error response:", error.response);
      setEmailError(true);
      setRequestInProgress(false);
    })
  }

  const handleEmailNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (emailError){
      setEmailError(false);
    }
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    changePassword();
  }
  return (
    <StyledDiv>

      <ImagePreload src={iconSpinner}/>

      <h1>{i8nText(appContext.language, "login.resetPassword")}</h1>

      {!validToken ? (
        <p>{i8nText(appContext.language, "login.resetPasswordBadToken")}</p>
      ):(
        <>
        <p>
          {`${i8nText(appContext.language, "login.resetPasswordMessage")} `}
          {!isNil(emailLetters) &&
            <span>{`${emailLetters[0]}***${emailLetters[1]}@${emailDomain}`}</span>
          }
        </p>
        <div className="form-wrapper">
          <div className="body">
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                // autoComplete="email"
                id="email"
                name="email"
                placeholder={i8nText(appContext.language, "login.enterEmail")}
                value={email}
                minLength={6}
                maxLength={40}
                required
                tabIndex={2}
                onChange={handleEmailNameChange}
                spellCheck={false}
                // error={`${emailError}`}
              />
              {!emailError ? "":(
                <div className="errorMessage">{i8nText(appContext.language, "login.emailNotValid")}</div>
              )}

              <input
                type="password"
                // autoComplete="password"
                id="password"
                name="password"
                placeholder={i8nText(appContext.language, "login.enterNewPassword")}
                value={password}
                minLength={6}
                maxLength={40}
                required
                tabIndex={3}
                onChange={handlePasswordChange}
              />
              {requestInProgress ? (
                <SpinnerDiv>
                  <img alt="spinner" src={iconSpinner}/>
                </SpinnerDiv>
              ):(
                <input
                  type="submit"
                  value={i8nText(appContext.language, "login.setNewPassword")}
                  tabIndex={4}
                />
              )}
            </form>
          </div>
        </div>
        </>
      )}

    </StyledDiv>
  );
}