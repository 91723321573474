import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
//import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_maximize_tab.svg";

const OUT_OF_RANGE_INIT_VALUE = 1000;
const INITIAL_SEARCH_VALUE = 5;
const MAX_FONT_SIZE = 50;

type Props = {
  setAutoFitFinished: (val: boolean) => void;
  setCalculatingMaxTab: (val: boolean) => void;
};

export const MaximizeTabView = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [count, setCount] = useState(OUT_OF_RANGE_INIT_VALUE);
  const [maxVisibleSizeFound, setMaxVisibleSizeFound] = useState(false);
  const [rightmostElement, setRightmostElement] = useState<HTMLElement>();
  const [autoFit, setAutoFit] = useState(appContext.autoFitEnabled);

  const getRightmostElementContainer = () => {
    const lyrics: NodeListOf<HTMLElement> = document.querySelectorAll(".song-tab__lyrics");
    const strings: NodeListOf<HTMLElement> = document.querySelectorAll(".song-tab__string");
    const chords: NodeListOf<HTMLElement> = document.querySelectorAll(".song-tab__chords .chord");
    const elements = [...lyrics, ...strings, ...chords];

    let maxRightValue = 0;
    let rightmostEl: HTMLElement | undefined = undefined;

    for (let i = 0; i < elements.length; i++) {
      //console.log("getRightmostElementContainer -> elements[i]", elements[i])
      const rect = elements[i].getBoundingClientRect();
      //console.log("getRightmostElementContainer -> rect", rect)
      //console.log("getRightmostElementContainer -> rect.right", rect.right)

      if (rect.right > maxRightValue) {
        maxRightValue = rect.right;
        rightmostEl = elements[i];
      }
    }
    if (rightmostEl?.classList.contains("chord")) {
      //console.log("getRightmostElementContainer -> rightmostElement.parentElement", rightmostElement.parentElement)
      return rightmostEl.parentElement ?? undefined;
    }
    //console.log("getRightmostElementContainer -> rightmostElement", rightmostEl)
    //console.log("getRightmostElementContainer -> maxRightValue", maxRightValue)
    return rightmostEl;
  };

  const isRightmostElementPartiallyHidden = () => {
    const rect = rightmostElement?.getBoundingClientRect();
    //console.log("isRightmostElementPartiallyHidden -> rect", rect)
    if (!isNil(rect) && rect.right > window.innerWidth /* -64 */) {
      return true;
    }
    return false;
  };

  const onClickHandler = () => {
    appContext.setNumberOfColumnsInSong(1);
    appContext.setViewerFontSize(INITIAL_SEARCH_VALUE);
    appContext.setViewerLineHeight(INITIAL_SEARCH_VALUE);
    setMaxVisibleSizeFound(false);
    setCount(INITIAL_SEARCH_VALUE);
    props.setCalculatingMaxTab(true);
  };

  //https://davidwalsh.name/orientation-change
  const onOrientationChangeHandler = () => {
    // console.log("orientationChangeHandler -> window.innerWidth", window.innerWidth)
    // console.log("orientationChangeHandler -> window.outerWidth", window.outerWidth)

    if (appContext.autoFitEnabled) {
      if (window.innerWidth !== window.width) {
        window.width = window.innerWidth;

        appContext.setViewerFontSize(INITIAL_SEARCH_VALUE);
        appContext.setViewerLineHeight(INITIAL_SEARCH_VALUE);
        setCount(INITIAL_SEARCH_VALUE);
        //setTimeout(onClickHandler, 1000);
        onClickHandler();
      }
    }
  };
  useEffect(() => {
    //window.addEventListener('orientationchange', onOrientationChangeHandler);
    // console.log("Adding resize event listener");
    if (appContext.autoFitEnabled) {
      window.width = window.innerWidth;
      window.addEventListener("resize", onOrientationChangeHandler);
    }
  }, [appContext.autoFitEnabled]);

  useEffect(() => {
    //console.log("appContext.autoFitEnabled", appContext.autoFitEnabled)
    //console.log("autoFit", autoFit)
    if (autoFit) {
      onClickHandler();
      setAutoFit(false);
    }

    if (appContext.numberOfColumnsInSong === 1) {
      if (!rightmostElement) {
        setRightmostElement(getRightmostElementContainer());
      } else {
        if (count !== OUT_OF_RANGE_INIT_VALUE) {
          if (!maxVisibleSizeFound) {
            if (count > MAX_FONT_SIZE) {
              // console.log("Maximum text size reached!")
              rightmostElement.style.fontSize = "";
              props.setCalculatingMaxTab(false);
              props.setAutoFitFinished(true);
              setMaxVisibleSizeFound(true);
              appContext.setViewerFontSize(count - 1);
              appContext.setViewerLineHeight(count - 1);
            } else if (isRightmostElementPartiallyHidden()) {
              // console.log("Max visible size found!")
              rightmostElement.style.fontSize = "";
              props.setCalculatingMaxTab(false);
              props.setAutoFitFinished(true);
              setMaxVisibleSizeFound(true);
              appContext.setViewerFontSize(count - 2);
              appContext.setViewerLineHeight(count - 2);
            } else {
              rightmostElement.style.fontSize = count + "px";
              // console.log("Setting count to ", count+1)
              setCount(count + 1);
            }
          }
        }
      }
    }
  }, [count, appContext, rightmostElement, autoFit, maxVisibleSizeFound]);

  return (
    <div className="maximize-tab-view">
      <ButtonIconText
        {...props}
        data-for="tooltipMaximizeTab"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        onClick={onClickHandler}
      />
      <ReactTooltip id="tooltipMaximizeTab" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.maximizeTab")}</span>
      </ReactTooltip>
    </div>
  );
};
