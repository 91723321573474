import { AppStateContext } from "AppStateProvider";
import { addChordsToMemoryChordMap, getChord, playChord } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import cursorFingerSpeaker from "resources/cursors/cursor_finger_speaker_gy.cur";
import imageSectionBg from "resources/images/text-highlight.webp";
import imageTitleBg from "resources/images/title-highlight.webp";
import styled from "styled-components";
import { FullSongInfo } from "types/common";
import { BottomDecoration } from "./BottomDecoration";
import { NotebookBottomSeparator } from "./NotebookBottomSeparator";
import { NotebookSpiralSeparator } from "./NotebookSpiralSeparator";
import { ViewerSongInfo } from "./ViewerSongInfo";
import { ViewerUsedChords } from "./ViewerUsedChords";
import { ViewerUtilitiesToolbar } from "./ViewerUtilitiesToolbar";

const StyledDiv = styled.div<Props>`
  .song-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .text {
      font-size: 0.9rem;
      font-weight: 600;
      color: ${props => props.theme.fgColor1};
    }
  }

  .song-body {
    transition: opacity 0.15s ease-out;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
    .hidden {
      display: none;
    }
  }

  .song-tab {
    padding: 1rem 0.8rem 1rem 0.8rem;
    columns: 2;
    letter-spacing: normal;
    letter-spacing: 0rem;
    background-color: #eeeeee;

    //column-count: 3;
    column-gap: 2rem;
    column-rule: 1px solid lightgray;

    & * {
      font-family: "Roboto Mono", monospace, sans-serif;
      font-weight: 400;
    }

    pre {
      //BEGIN <pre> default style
      display: block;
      /*
      //white-space: pre;
      //white-space: pre-line;
      //white-space: pre-wrap;
      */
      white-space: pre;
      margin: 1em 0px;
      //END <pre> default style

      margin: 0;

      &.song-tab__chords {
        color: red;
        /*
        //white-space: pre;
        //white-space: break-spaces;
        */
        width: fit-content;
        user-select: none;

        span.chord {
          position: relative;
          cursor: url(${cursorFingerSpeaker}), auto;

          &:hover {
            font-weight: 700;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0.15rem;
            bottom: 0.05rem;
            left: -0.2rem;
            right: -0.25rem;
            border-radius: 0.4rem;
            background: black;
            opacity: 0.075;
          }

          &.selected {
            color: #dd0000;
            font-weight: 700;

            &:after {
              content: "";
              display: block;
              position: absolute;
              top: -0.2rem;
              bottom: -0.3rem;
              left: -0.5rem;
              right: -0.5rem;
              border-radius: 0.9rem;
              background: rgba(255, 0, 0, 1);
            }
          }
        }

        span.space {
          opacity: 0.5;
        }
      }
      &.song-tab__lyrics {
        font-weight: 500;
        color: black;
        margin-bottom: 0.5rem;
        //display: inline-block;
        display: table;
      }

      &.song-tab__string {
        //letter-spacing: -2px;
        display: table;
      }
    }

    /* Margin top to all section except the first one */
    .song-tab__section ~ .song-tab__section {
      margin-top: 1rem;
    }

    .song-tab__section {
      position: relative;
      left: -0.6rem;
      color: #333;
      margin-top: 0;
      background-image: url(${imageSectionBg});
      width: fit-content;
      background-size: 100% 100%;
      padding: 0.4rem 0.6rem;
      background-repeat: no-repeat;
      background-position: center;

      .open,
      .close {
        opacity: 0;
      }
      .content {
        position: relative;
        top: 0.1rem;
        font-family: "Gochi Hand", cursive;
        //font-family: 'Nanum Pen Script', cursive;
        font-size: ${props => props.fontsize * 1.6}px;
      }
    }
    .song-tab__header {
      position: relative;
      left: -0.6rem;
      color: #333;
      margin-top: 0;
      margin-bottom: 1rem;
      background-image: url(${imageTitleBg});
      width: fit-content;
      background-size: 100% 100%;
      padding: 0.6rem 1rem;
      background-repeat: no-repeat;
      background-position: center;
      span {
        font-family: "Gochi Hand", cursive;
        font-size: ${props => props.fontsize * 1.6}px;
        text-transform: capitalize;
      }
    }
  }
`;

type Props = {
  songData: FullSongInfo;
  setSongData: (val: FullSongInfo) => void;
  columns: number;
  fontsize: number;
  lineheight: number;
  letterspacing: number;
  autoFitEnabled: boolean;
  calculatingMaxTab: boolean;
  setCalculatingMaxTab: (val: boolean) => void;
  autoFitFinished: boolean;
  setAutoFitFinished: (val: boolean) => void;
};

export const Song = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [currentChordPosition, setCurrentChordPosition] = useState(0);
  const [chordPositionList, setChordPositionList] = useState<Array<string>>([]);

  useEffect(() => {
    const chordClickHandler = (event: MouseEvent) => {
      const el = event.target as HTMLElement;
      setCurrentChordPosition(parseInt(el.dataset.chordPosition ?? "0"));
      const myChord = getChord(el.innerHTML, appContext.chordList);
      if (appContext.soundEnabled && myChord) {
        playChord(myChord.string, appContext.transposition);
      }
    };
    if (props.songData) {
      document.querySelectorAll(".chord").forEach(item => {
        (item as HTMLElement).addEventListener("click", chordClickHandler);
      });
    }

    return () => {
      document.querySelectorAll(".chord").forEach(item => {
        (item as HTMLElement).removeEventListener("click", chordClickHandler);
      });
    };
  }, [props.songData, appContext.transposition, appContext.chordList, appContext.soundEnabled]);

  useEffect(() => {
    if (props.songData) {
      document.querySelectorAll(".chord").forEach(item => {
        if (parseInt((item as HTMLElement).dataset.chordPosition ?? "0") === currentChordPosition) {
          item.classList.add("selected");
        } else {
          item.classList.remove("selected");
        }
      });
    }
  }, [currentChordPosition, props.songData]);

  useEffect(() => {
    if (!isNil(props.songData.tabHtml) && props.songData.tabHtml.length > 0 && appContext.chordList.length > 0) {
      const chordListArr = generateChordListArray(props.songData.tabHtml);
      if (chordListArr.length > 0) {
        addChordsToMemoryChordMap(chordListArr, appContext.chordList);
        setChordPositionList(chordListArr);
      }
    }
  }, [appContext.chordList, props.songData.tabHtml]);

  const generateChordListArray = (tabHtml: string | undefined): string[] => {
    if (isNil(tabHtml)) {
      return [];
    }

    const chordListArr: Array<string> = [];
    const htmlCollection: HTMLCollection = parseHTML(tabHtml);
    const chordHtmlCollections = [...htmlCollection]
      .filter(el => el.className === "song-tab__chords")
      .map(el => el.children);
    chordHtmlCollections.map(item => {
      [...item].filter(el => el.className === "chord").map(el => chordListArr.push((el as HTMLElement).innerText));
      return null;
    });

    return chordListArr;
  };

  const parseHTML = (str: string) => {
    const tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = str;
    return tmp.body.children;
  };

  const createMarkupTab = (): {
    __html: string;
  } => {
    return { __html: props.songData.tabHtml ?? "" };
  };

  const getSongBodyClass = () => {
    let baseClass = "song-body ";
    if (props.calculatingMaxTab) {
      return baseClass + "hidden";
    }
    if (appContext.autoFitEnabled) {
      if (props.autoFitFinished) {
        baseClass += "visible ";
      }
    } else {
      baseClass += "visible ";
    }

    return baseClass;
  };

  return props.songData === null ? (
    <></>
  ) : (
    <StyledDiv className="song" {...props}>
      <div className="song-header">
        <ViewerSongInfo songData={props.songData} setSongData={props.setSongData} />

        <ViewerUsedChords
          usedChords={[...new Set(chordPositionList)]}
          transposition={appContext.transposition}
          currentChordName={chordPositionList[currentChordPosition]}
        />

        <ViewerUtilitiesToolbar
          songData={props.songData}
          chordPositionList={chordPositionList}
          currentChordPosition={currentChordPosition}
          setCurrentChordPosition={setCurrentChordPosition}
          calculatingMaxTab={props.calculatingMaxTab}
          setCalculatingMaxTab={props.setCalculatingMaxTab}
          autoFitFinished={props.autoFitFinished}
          setAutoFitFinished={props.setAutoFitFinished}
        />
      </div>

      <div className={getSongBodyClass()}>
        <NotebookSpiralSeparator />
        <div
          className="song-tab"
          dangerouslySetInnerHTML={createMarkupTab() ?? ""}
          style={{
            columns: appContext.numberOfColumnsInSong,
            fontSize: appContext.viewerFontSize + "px",
            lineHeight: appContext.viewerLineHeight + "px",
            letterSpacing: appContext.viewerLetterSpacing + "rem",
          }}
        />
        <NotebookBottomSeparator />
        <BottomDecoration />
      </div>
    </StyledDiv>
  );
};
