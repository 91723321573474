import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import {
  getCursorColumn,
  getCursorPositionFromRowAndColumn,
  getCursorRow,
  getLineType,
  getStringAtRow,
  i8nText,
  insertChordInLineAtPosition,
  replaceRow
} from "components/common/Util";
import { isNil } from "lodash";
import React, { ChangeEvent, ReactElement, useContext, useEffect, useRef } from "react";
import "./SongTabEditor.scss";

type Props = {
  tab: string;
  setTab: (val: string) => void;
  currentChord: string;
  setCurrentChord: (val: string) => void;
  currentCursorPositionInTab: number;
  setCurrentCursorPositionInTab: (val: number) => void;
  addChord: boolean;
  setAddChord: (val: boolean) => void;
};

export const SongTabEditor = (props: Props): ReactElement => {
  const textareaElement = useRef<HTMLTextAreaElement>(null);
  const appContext = useContext(AppStateContext);

  useEffect(() => {
    if (textareaElement.current) {
      textareaElement.current.value = props.tab;
    }
  }, [props.tab]);

  useEffect(() => {
    if (textareaElement.current) {
      textareaElement.current.focus();
      textareaElement.current.selectionStart = props.currentCursorPositionInTab;
    }
  }, [props.currentCursorPositionInTab]);

  useEffect(() => {
    if (props.addChord === true) {
      props.setAddChord(false);
      addChord();
    }
  }, [props.addChord]);

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>, fun: (val: string) => void) => {
    fun(e.target.value);
  };

  const onClickHandler = () => {
    //console.log((new Date()).toISOString() + " Click!");
    updateCursorPosition();
  };

  const onKeyUpHandler = () => {
    //console.log((new Date()).toISOString() + " KeyUp!");
    updateCursorPosition();
  };

  const updateCursorPosition = () => {
    if (textareaElement.current) {
      props.setCurrentCursorPositionInTab(textareaElement.current.selectionStart);
    }
  };

  const addChord = () => {
    const cursorColumn = getCursorColumn(props.tab, props.currentCursorPositionInTab);
    const cursorRow = getCursorRow(props.tab, props.currentCursorPositionInTab);

    const line = getStringAtRow(props.tab, cursorRow);
    const lineType = getLineType(line);

    let strFinal = props.tab;

    if (lineType === Config.LINE_TYPE__LINEBREAK) {
      const newLine = insertChordInLineAtPosition(line, props.currentChord, cursorColumn);
      strFinal = replaceRow(strFinal, newLine, cursorRow);
    } else if (lineType === Config.LINE_TYPE__CHORDS) {
      const newLine = insertChordInLineAtPosition(line, props.currentChord, cursorColumn);
      strFinal = replaceRow(strFinal, newLine, cursorRow);
    } else if (lineType === Config.LINE_TYPE__LYRICS) {
      const prevLine = getStringAtRow(props.tab, cursorRow - 1);
      const prevLineType = getLineType(prevLine);

      if (isNil(prevLine)) {
        //We are at row 0, so insert a new line above
        const newLine = insertChordInLineAtPosition("", props.currentChord, cursorColumn);
        strFinal = replaceRow(strFinal, newLine + "\n" + line, 0);
      } else {
        if (prevLineType === Config.LINE_TYPE__CHORDS) {
          const newLine = insertChordInLineAtPosition(prevLine, props.currentChord, cursorColumn);
          strFinal = replaceRow(strFinal, newLine, cursorRow - 1);
        } else {
          const newLine = insertChordInLineAtPosition("", props.currentChord, cursorColumn);
          strFinal = replaceRow(strFinal, newLine + "\n" + line, cursorRow);
        }
      }
    }
    const scrollTop = textareaElement.current ? textareaElement.current.scrollTop : 0;
    const newCursorPosition = getCursorPositionFromRowAndColumn(strFinal, cursorRow, cursorColumn);

    if (textareaElement.current) {
      textareaElement.current.value = strFinal;
    }

    props.setTab(strFinal);
    props.setCurrentCursorPositionInTab(newCursorPosition);

    if (textareaElement.current) {
      textareaElement.current.focus();
      textareaElement.current.selectionEnd = newCursorPosition;
      textareaElement.current.selectionStart = newCursorPosition;
      textareaElement.current.scrollTop = scrollTop;
    }
  };

  return (
    <div className="song-tab-editor">
      <label htmlFor="tab">{i8nText(appContext.language, "global.tab")}</label>
      <div className="text-area-wrapper">
        <textarea
          ref={textareaElement}
          name="tab"
          id="tab"
          rows={10}
          wrap="off"
          tabIndex={10}
          autoFocus={false}
          placeholder={i8nText(appContext.language, "editor.tab.placeholder")}
          spellCheck={false}
          defaultValue={props.tab}
          onChange={e => onChangeHandler(e, props.setTab)}
          onClick={onClickHandler}
          onKeyUp={onKeyUpHandler}
        />
      </div>
    </div>
  );
};
