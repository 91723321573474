import { Config } from "components/common/Config";
import React, { ReactElement } from "react";
import logo from "resources/images/logo.svg";
import styled from "styled-components";


const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  img {
    width: 2.5rem;
    height: 2.5rem;
    display: block;
    margin: 0 0.6rem;
    cursor: pointer;
  }
`;

export const HeaderLogo = (): ReactElement => {
  return (
    <StyledDiv>
      <a href={`${Config.PAGE_ROUTE__HOMEPAGE}?v=${Config.APP_VERSION}`}>
        <img alt="header-logo" src={logo} />
      </a>
    </StyledDiv>
  );
};
