import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext, useEffect } from "react";
import iconClose from "resources/icons/icon_close.webp";
import bgImage from "resources/images/guitars-bg.webp";
import logo from "resources/images/logo.svg";
import styled from "styled-components";

const StyledDiv = styled.div`
  background-image: url(${bgImage});
  background-repeat: repeat;
  background-position: center;
  background-size: 20rem 20rem;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  opacity: 1;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  .close-button {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .form-wrapper {
    width: 25rem;
    max-width: 90%;
    height: 12rem;
    background-color: ${props => props.theme.fgColor2};
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 2rem;
    box-sizing: border-box;

    .header {
      .logo {
        width: 100%;
        text-align: center;

        img {
          width: 3rem;
          height: auto;
        }
      }
      .bye-message {
        font-size: 1.5rem;
        text-align: center;
        color: ${props => props.theme.bgColor2};
      }
    }
  }
`;

type Props = {
  setLogoutOptionClicked: (val: boolean) => void;
  setUserMenuVisible: (val: boolean) => void;
  setSideMenuVisible: (val: boolean) => void;
};

export const LogOutForm = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  useEffect(() => {
    props.setUserMenuVisible(false);
    props.setSideMenuVisible(false);
    sessionStorage.removeItem(Config.LOCALSTORAGE_USER__AUTH_TOKEN);
    sessionStorage.removeItem(Config.LOCALSTORAGE_USER__JWT_DATA);
    appContext.setUser(undefined);
  }, [appContext, props]);

  const handleClickCloseButton = ()  => {
    props.setLogoutOptionClicked(false);
  };

  return (
    <StyledDiv>
      <div className="close-button" onClick={handleClickCloseButton}>
        <ButtonIconText icon={iconClose} primary />
      </div>

      <div className="form-wrapper">
        <div className="header">
          <div className="logo">
            <img alt="logo" src={logo} />
          </div>

          <div className="bye-message">{i8nText(appContext.language, "login.sessionClosed")}</div>
        </div>

        <div className="body">
          <div className="message-wrapper">{i8nText(appContext.language, "login.bye")}</div>
        </div>
      </div>
    </StyledDiv>
  );
};
