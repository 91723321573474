import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { LanguageSelector } from "components/common/LanguageSelector";
import { SettingsButton } from "components/common/SettingsButton";
import { SettingsSlider } from "components/common/SettingsSlider";
import { SettingsSwitch } from "components/common/SettingsSwitch";
import { SoundSwitch } from "components/common/SoundSwitch";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  color: ${props => props.theme.fgColor1};
  text-align: center;
  max-width: ${props => props.theme.bp.mobile};
  margin: 0 auto;
  padding: 0 1rem;

  & > div {
    padding: 0 0 .7rem 0;
  }
`;
const SeparatorDiv = styled.div`
  &.separator{
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.fgColor1};
    padding: 0;
    margin: 0;
    margin: 1.5rem 0;
  }
`;

export const SettingsPage = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const clearCache = () => {
    Object.keys(localStorage).forEach(key => {
      if (key.substring(0,4)==="gtm-"){
        localStorage.removeItem(key);
      }
    })
    console.log("Cache cleaned!");
  }

  return (
    <StyledDiv>
      <h1>{i8nText(appContext.language, "global.settings")}</h1>

      <LanguageSelector/>
      <SoundSwitch/>
      <SettingsSwitch
        setting={appContext.metronomeEnabled}
        setSetting={appContext.setMetronomeEnabled}
        text={i8nText(appContext.language, "settings.metronomeEnabled")}
        localStorageKey={Config.LOCALSTORAGE_APP__METRONOME_ENABLED}
      />
      <SettingsSwitch
        setting={appContext.metronomeSoundEnabled}
        setSetting={appContext.setMetronomeSoundEnabled}
        text={i8nText(appContext.language, "settings.metronomeSoundEnabled")}
        localStorageKey={Config.LOCALSTORAGE_APP__METRONOME_SOUND_ENABLED}
        {...(appContext.metronomeEnabled ? {}:{disabled:true})}
      />
      <SettingsSlider
        text={i8nText(appContext.language, "settings.metronomeOpacity")}
        setting={appContext.metronomeOpacity}
        setSetting={appContext.setMetronomeOpacity}
        step={0.05}
        min={0.1}
        max={1}
        localStorageKey={Config.LOCALSTORAGE_APP__METRONOME_OPACITY}
      />
      <SettingsSlider
        text={i8nText(appContext.language, "settings.metronomeSize")}
        setting={appContext.metronomeSize}
        setSetting={appContext.setMetronomeSize}
        step={0.05}
        min={0.5}
        max={1}
        localStorageKey={Config.LOCALSTORAGE_APP__METRONOME_SIZE}
      />
      <SettingsSwitch
        setting={appContext.tooltipsEnabled}
        setSetting={appContext.setTooltipsEnabled}
        text={i8nText(appContext.language, "settings.tooltipsEnabled")}
        localStorageKey={Config.LOCALSTORAGE_APP__TOOLTIPS_ENABLED}
      />
      <SettingsSwitch
        setting={appContext.autoFitEnabled}
        setSetting={appContext.setAutoFitEnabled}
        text={i8nText(appContext.language, "settings.autoFitEnabled")}
        localStorageKey={Config.LOCALSTORAGE_APP__AUTOFIT_ENABLED}
      />
      <SettingsButton
        text={i8nText(appContext.language, "settings.clearCache")}
        buttonText={i8nText(appContext.language, "settings.clear")}
        action={clearCache}
      />

      {isNil(appContext.user) ? "":(
        <>
          <SeparatorDiv className="separator"/>
          <SettingsButton
            text={i8nText(appContext.language, "settings.saveAccountSettings")}
            buttonText={i8nText(appContext.language, "settings.save")}
            action={() => console.log("TODO: Save user settings")}
          />
        </>
      )}

    </StyledDiv>
  );
}