import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { isNil } from "lodash";
import { ReactElement, useContext, useEffect } from "react";

export const ChordListLoader = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  useEffect(() => {
    getChordList();
  }, []);

  const getChordList = () => {
    if (isNil(appContext.chordList)) {
      console.log("Chord list already loaded");
    } else {
      const url = `${Config.CHORD_SERVICE_URL}`;
      fetch(url)
        .then(res => {
          return res.json();
        })
        .then(res => {
          appContext.setChordList(res);
        })
        .catch(err => {
          console.error("Error:", err);
        });
    }
  };

  return <></>;
};
