export const getPreferredLanguage = (): string => {
  const availLang = Object.keys(window.availableLanguages);
  if (navigator.languages !== undefined){
    for (let i = 0; i < navigator.languages.length; i++) {
      if (availLang.includes(navigator.languages[i])){
        return navigator.languages[i];
      }
    }
    return availLang[0];
  } else {
    if (availLang.includes(navigator.language)){
      return navigator.language;
    }
    return availLang[0];
  }
}

window.availableLanguages = {"en":"English","es":"Español","gl":"Galego"};

window.preferredLanguage = getPreferredLanguage();

window.dictionary = {};

//Get emojis from:
//https://getemoji.com/
//https://emojipedia.org/rocket/

window.dictionary["en"] = {
  "global.appName":"Guitar Notebook",
  "global.appEmail":"info@guitarnotebook.com",
  "global.appURL":"guitarnotebook.com",

  "header.home":"home",
  "header.songList":"songs",
  "header.songViewer":"viewer",
  "header.songEditor":"editor",
  "header.about":"About",

  "homepage.songSelector":"SONG SELECTOR",
  "homepage.songSelector.info":"Click on a song to view it!",
  "homepage.songViewer":"SONG VIEWER",
  "homepage.songViewer.info": "Keys:\nZ: Play previous chord\nX: Play next chord",
  "homepage.songEditor":"SONG EDITOR",
  "homepage.songEditor.info":"Sign in or register for free in order to save songs!",
  "homepage.important":"IMPORTANT!",
  "homepage.minimumRequirements":"Minimum Requirements",
  "homepage.minimumRequirements.info":"A computer with sound card\nGoogle Chrome Desktop v81+\nScreen Resolution 1024x768px\nA guitar is optional 😁",
  "homepage.disclaimer":"This is a beta application, so for sure you will find things not working properly. We are working hard to create new functionalities and to improve the existing ones. In the meantime, feel free to let us know your suggestions for improvement. You can contact us on this email",

  "global.language":"Language",
  "global.settings":"Settings",
  "settings.soundEnabled":"Sounds enabled",
  "settings.saveDataBandwidthEnabled":"Save data bandwidth",
  "settings.metronomeEnabled":"Enable metronome",
  "settings.metronomeSoundEnabled":"Enable metronome sound",
  "settings.metronomeOpacity":"Metronome opacity",
  "settings.metronomeSize":"Metronome size",
  "settings.tooltipsEnabled":"Enable tooltips",
  "settings.autoFitEnabled":"Autofit tab to window",
  "settings.clearCache":"Clear local cache and reset settings to default",
  "settings.clear":"Clear",
  "settings.save":"Save",
  "settings.saveAccountSettings":"Save these settings in my account",

  "global.title":"title",
  "global.performer":"performer",
  "global.tuning":"tuning",
  "global.capo":"capo",
  "global.originalCapo":"original capo",
  "global.original":"original",
  "global.custom":"custom",
  "global.duration":"duration",
  "global.bpm":"bpm",
  "global.links":"links",
  "global.comments":"comments",
  "global.tabbedBy":"tabbed by",
  "global.tab":"tab",
  "global.date":"date",
  "global.views":"views",
  "global.total":"total",
  "global.username":"user name",

  "global.search":"Search",
  "global.signIn":"Sign In",
  "global.signUp":"Sign Up",

  "global.welcomeTo":"Welcome to",

  "global.yes":"Yes",
  "global.no":"No",
  "global.close":"Close",
  "global.me":"me",
  "global.pageNotFound":"Page not found 🙄",

  "summary.latestTabs":"Latest Tabs",
  "summary.mostPopular":"Most Popular",
  "summary.topUsers":"Top Users",

  "login.enterUsername":"Enter username",
  "login.enterEmail":"Enter email",
  "login.enterPassword":"Enter password",
  "login.forgotPassword":"Forgot password?",
  "login.notAMember":"Not a member? Sign up here",
  "login.alreadyAMember":"Already a member? Sign in here",
  "login.hello":"Hi,",
  "login.welcomeNewUser":"thank you for registering!\nIf you would like to view your registration details, you can login now pressing the Sign In button",
  "login.bye":"Thanks for coming!",
  "login.logOut":"Log Out",
  "login.loginFailed":"Login failed!",
  "login.resetPassword":"Reset password",
  "login.resetPasswordMessage":"Please, enter full email address and the new password for",
  "login.enterNewPassword":"Enter new password",
  "login.setNewPassword":"Set new password",
  "login.resetPasswordBadToken":"Oops! It's seems that the reset password token is not valid.\nIf you forgot your password please make a new reset password request from the login page",
  "login.newPasswordOk":"The new password has been set correctly! You can sign in now in the login page",
  "login.newPasswordKo":"There was a problem while setting your new password. Please try again later. You can contact us if this issue persist ",
  "login.forgottenPassword": "Forgotten password",
  "login.forgottenPasswordMessage": "Enter the email address you used to register your GuitarNotebook account.\nWe will send you an email with instructions to create a new password",
  "login.forgottenPasswordNote": "Please note that, in order to avoid security issues, if the email you entered is not in our database, no email will be sent regardless of the 'success' message",
  "login.sendEmail": "Send email",
  "login.passwordRestored": "Password restored successfully! :)",
  "login.emailSent": "Email sent successfully 🚀\nCheck your inbox ;)",
  "login.emailNotValid": "Email/Token not valid. If you are using an old 'Reset Password' link please request a new one",
  "login.sessionClosed":"Session closed",

  "chordFinder.searchByFretboard":"search by fretboard",
  "chordFinder.searchByName":"search by name",
  "chordFinder.chordsFound":"chords found!",

  "chordsUsed.chordsUsed":"chords used",
  "chordsUsed.add":"add",

  "editor.header.title.placeholder":"Enter title",
  "editor.header.performer.placeholder":"Enter performer",
  "editor.header.tuning.placeholder":"Enter tuning",
  "editor.header.comments.placeholder":"Enter comments",
  "editor.tab.placeholder":"Enter chords and lyrics here...",

  "editor.commentsEditor.placeholder":"Enter your comments about this song here...",
  "editor.linksEditor.placeholder":"Enter one link per row here...",

  "editorButton.saveSong":"Save song",
  "editorButton.newSong":"New song",
  "editorButton.removeBlankLines":"Remove blank lines",

  "songsViewer.title":"song title",
  "songsViewer.performer":"song performer",
  "songsViewer.lastUpdate":"last update",
  "songsViewer.tabBy":"tabbed by",
  "songsViewer.rating":"rating",
  "songsViewer.bin":"bin",
  "songsViewer.serverConnectionError":"There was a problem while connecting to the songs server. Try again later. Please contact us if this issue persist",
  "songsViewer.chords":"chords",
  "songsViewer.utilities":"utilities",

  "songList.deleteMessage":"Are you sure you want to delete",
  "songList.filter.title":"Songs Filter",
  "songList.filter.allSongs":"All Songs",
  "songList.filter.mySongs":"My Songs",
  "songList.filter.myFavorites":"My Favorite Songs",
  "songList.emptyList":"This list is empty.",

  "message.saveError":"Save error! The was some issue while trying to save your song. Please contact us if the problem persists.",
  "message.saveOnlyForRegisteredUsers":"Only registered users can save songs. Please sign in or register for free.",
  "message.selectSongFromList":"Please select a song from the Song List",

  "footer.disclaimer":"Disclaimer",
  "footer.disclaimerMessage":`This website contains transcripts for guitar and ukulele, which are the work of their transcribers and represent their personal interpretation of the songs. The transcripts contained on this website are exclusively for private use, therefore their reproduction, retransmission or use for commercial purposes is prohibited.\n
    The contents of this website can be modified or updated without prior notice. The user accepts that any of the services offered by this website may be discontinued because of technical or economic reasons.\n
    Reproduction, distribution, copying or any other use of the information contained on this website is strictly prohibited.`,
  "footer.contactUs":"Contact us",

  "tooltip.increaseFontSize":"Increase font size",
  "tooltip.decreaseFontSize":"Decrease font size",
  "tooltip.increaseLineHeight":"Increase line height",
  "tooltip.decreaseLineHeight":"Decrease line height",
  "tooltip.increaseColumns":"Increase columns",
  "tooltip.decreaseColumns":"Decrease columns",
  "tooltip.maximizeTab":"Auto fit tab on screen",
  "tooltip.increaseLetterSpacing":"Increase letter spacing",
  "tooltip.decreaseLetterSpacing":"Decrease letter spacing",
  "tooltip.resetVisualSettings":"Reset visual settings to defaults",
  "tooltip.playNextChord":"Play next chord",
  "tooltip.playPrevChord":"Play previous chord",
  "tooltip.editSong":"Edit song",
  "tooltip.duration":"Duration of the song.\nThe autoscroll function\nwill show the whole song\nin the specified time",
  "tooltip.bpm":"Beats Per Minute of the song.\nThe metronome will not work\nif this value is 0",
  "tooltip.calculateBpm":"Click the button at the rythm of\nthe music to calculate the BPM",
  "tooltip.editComments":"Edit song comments",
  "tooltip.editLinks":"Edit links",
  "tooltip.findVideos":"Find videos",

  "webShare.title":"GuitarNotebook.com",
  "webShare.text":"Take a look at this guitar tab from guitarnotebook.com! 🎸\n",
}

window.dictionary["es"] = {
  "header.home":"inicio",
  "header.songList":"canciones",
  "header.songViewer":"visor",
  "header.songEditor":"editor",
  "header.about":"Acerca de",

  "homepage.songSelector":"SELECTOR DE CANCIONES",
  "homepage.songSelector.info":"¡Haz click sobre una canción para visualizarla!",
  "homepage.songViewer":"VISOR DE CANCIONES",
  "homepage.songViewer.info": "Teclas:\nZ: Reproducir acorde anterior\nX: Reproducir siguiente acorde",
  "homepage.songEditor":"EDITOR DE CANCIONES",
  "homepage.songEditor.info":"¡Ingresa o regístrate gratis para guardar canciones!",
  "homepage.important":"¡IMPORTANTE!",
  "homepage.minimumRequirements":"Requisitos mínimos",
  "homepage.minimumRequirements.info":"Un ordenador con tarjeta de sonido\nGoogle Chrome Desktop v81+\nResolución de pantalla 1024x768px\nLa guitarra es opcional 😁",
  "homepage.disclaimer":"Esta es una aplicación beta, así que seguro que te encontrarás cosas que no funcionan como deberían. Estamos trabajando duro para crear nuevas funcionalidades y para mejorar las ya existentes. Mientras tanto, puedes enviarnos sugerencias para hacer mejoras en la aplicación. Puedes contactar con nosotros a través de este email",

  "global.language":"Idioma",
  "global.settings":"Ajustes",

  "settings.soundEnabled":"Activar sonido",
  "settings.saveDataBandwidthEnabled":"Activar ahorro de datos",
  "settings.metronomeEnabled":"Activar metrónomo",
  "settings.metronomeSoundEnabled":"Activar sonido del metrónomo",
  "settings.metronomeOpacity":"Opacidad del metrónomo",
  "settings.metronomeSize":"Tamaño del metrónomo",
  "settings.tooltipsEnabled":"Activar info sobre herramientas",
  "settings.autoFitEnabled":"Autoajustar tab a la ventana",
  "settings.clearCache":"Limpiar caché local y restaurar ajustes por defecto",
  "settings.clear":"Limpiar",
  "settings.save":"Guardar",
  "settings.saveAccountSettings":"Guardar estos ajustes en mi cuenta",


  "global.title":"título",
  "global.performer":"intérprete",
  "global.tuning":"afinación",
  "global.capo":"cejilla",
  "global.originalCapo":"cejilla original",
  "global.original":"original",
  "global.custom":"custom",
  "global.duration":"duración",
  "global.bpm":"bpm",
  "global.links":"enlaces",
  "global.comments":"comentarios",
  "global.tabbedBy":"tab por",
  "global.tab":"tab",
  "global.date":"fecha",
  "global.views":"visitas",
  "global.total":"total",
  "global.username":"usuario",



  "global.search":"Buscar",
  "global.signIn":"Ingresar",
  "global.signUp":"Regístrate",

  "global.welcomeTo":"Bienvenido a",

  "global.yes":"Sí",
  "global.no":"No",
  "global.close":"Cerrar",
  "global.me":"Yo",
  "global.pageNotFound":"Página no encontrada 🙄",

  "summary.latestTabs":"Últimos Tabs",
  "summary.mostPopular":"Los más populares",
  "summary.topUsers":"Usuarios top",


  "login.enterUsername":"Introduzce nombre de usuario",
  "login.enterEmail":"Introduce email",
  "login.enterPassword":"Introduce contraseña",
  "login.forgotPassword":"¿Has olvidado la contraseña?",
  "login.notAMember":"¿No eres miembro? Regístrate aquí",
  "login.alreadyAMember":"¿Ya eres miembro? Ingresa aquí",
  "login.hello":"¡Hola",
  "login.welcomeNewUser":"gracias por registrarte!\nSi quieres ver tus datos de registro puedes entrar ahora pulsando el botón Ingresar",
  "login.bye":"¡Gracias por venir!",
  "login.logOut":"Cerrar sesión",
  "login.loginFailed":"¡Error de acceso!",
  "login.resetPassword":"Restablecer contraseña",
  "login.resetPasswordMessage":"Por favor, introduce el email completo y la nueva contraseña para",
  "login.enterNewPassword":"Introduce nueva contraseña",
  "login.setNewPassword":"Establecer nueva contraseña",
  "login.newPasswordOk":"La nueva contraseña se ha establecido correctamente! Puedes ingresar ahora desde la página de acceso",
  "login.newPasswordKo":"Ha surgido un problema al establecer la nueva contraseña. Por favor, inténtalo de nuevo más tarde. Puedes contactar con nosotros si el problema persiste",
  "login.forgottenPassword": "Contraseña olvidada",
  "login.forgottenPasswordMessage": "Introduce la dirección de correo electrónico que utilizaste para registrar tu cuenta en GuitarNotebook.\nTe enviaremos un email con instrucciones para crear una nueva contraseña",
  "login.forgottenPasswordNote": "Por favor ten en cuenta que, para evitar problemas de seguridad, si la dirección de correo electrónico no existe en nuestra base de datos, no se enviará ningún email independientemente del mensaje de confirmación",
  "login.sendEmail": "Enviar email",
  "login.passwordRestored": "Contraseña restablecida correctamente! :)",
  "login.emailSent": "Email enviado correctamente.\nComprueba tu bandeja de entrada ;)",
  "login.emailNotValid": "Email/Token no válido. Si estás utilizando un enlace antiguo para restablecer la contraseña, por favor genera uno nuevo",
  "login.sessionClosed":"Sesión cerrada",

  "chordFinder.searchByFretboard":"Buscar en el mástil",
  "chordFinder.searchByName":"Buscar por nombre",
  "chordFinder.chordsFound":"acordes encontrados!",

  "chordsUsed.chordsUsed":"acordes utilizados",
  "chordsUsed.add":"añadir",

  "editor.header.title.placeholder":"Introduce título",
  "editor.header.performer.placeholder":"Introduce intérprete",
  "editor.header.tuning.placeholder":"Introduce afinación",
  "editor.header.comments.placeholder":"Introduce comentarios",
  "editor.tab.placeholder":"Introduce acordes y letra aquí...",

  "editor.commentsEditor.placeholder":"Introduce comentarios sobre esta canción aquí...",
  "editor.linksEditor.placeholder":"Introduce un enlace por línea aquí...",

  "editorButton.saveSong":"Guardar canción",
  "editorButton.newSong":"Nueva canción",
  "editorButton.removeBlankLines":"Quitar lineas vacías",

  "songsViewer.title":"título",
  "songsViewer.performer":"intérprete",
  "songsViewer.lastUpdate":"f.actualización",
  "songsViewer.tabBy":"tab por",
  "songsViewer.rating":"valoración",
  "songsViewer.bin":"papelera",
  "songsViewer.serverConnectionError":"Ha ocurrido un problema al conectar con el servidor de canciones. Inténtalo de nuevo más tarde. Por favor, contacta con nosotros si el problema persiste",
  "songsViewer.chords":"acordes",
  "songsViewer.utilities":"herramientas",

  "songList.deleteMessage":"¿Estás seguro de que quieres borrar",
  "songList.filter.title":"Filtro de Canciones",
  "songList.filter.allSongs":"Todas las canciones",
  "songList.filter.mySongs":"Mis canciones",
  "songList.filter.myFavorites":"Mis canciones favoritas",
  "songList.emptyList":"Esta lista está vacía.",

  "message.saveError":"Error al guardar! Hubo algún problema al guardar tu canción. Por favor contacta con nosotros si el problema persiste",
  "message.saveOnlyForRegisteredUsers":"Solamente los usuarios registrados pueden guardar canciones. Por favor, ingresa o regístrate gratis",
  "message.selectSongFromList":"Por favor, selecciona una canción de la lista de canciones",

  "footer.disclaimer":"Aviso legal",
  "footer.disclaimerMessage":`Esta web contiene transcripciones para guitarra y ukelele, las cuales son trabajo propio de sus transcriptores y representan su interpretación personal de las canciones. Las transcripciones contenidas en esta web son exclusivamente para uso privado, por lo tanto se prohibe su reproducción, retransmisión o su uso para fines comerciales.\n
    Los contenidos de esta web pueden modificarse o actualizarse sin previo aviso. El usuario acepta que cualquiera de los servicios ofrecidos por esta web podrá ser descontinuado por razones técnicas o económicas.\n
    Queda estrictamente prohibida la reproducción, distribución, copia, difusión o algún otro uso de la información contenida en esta web.`,
  "footer.contactUs":"Contáctanos",

  "tooltip.increaseFontSize":"Incrementar fuente",
  "tooltip.decreaseFontSize":"Decrementar fuente",
  "tooltip.increaseLineHeight":"Incrementar altura de línea",
  "tooltip.decreaseLineHeight":"Decrementar altura de línea",
  "tooltip.increaseColumns":"Incrementar columnas",
  "tooltip.decreaseColumns":"Decrementar columnas",
  "tooltip.maximizeTab":"Maximizar tab",
  "tooltip.increaseLetterSpacing":"Incrementar espacio entre letras",
  "tooltip.decreaseLetterSpacing":"Decrementar espacio entre letras",
  "tooltip.resetVisualSettings":"Restaurar ajustes visuales",
  "tooltip.playNextChord":"Reproducir siguiente acorde",
  "tooltip.playPrevChord":"Reproducir acorde anterior",
  "tooltip.editSong":"Editar canción",
  "tooltip.duration":"Duración de la canción.\nEl autoscroll mostrará\nla canción completa en\nel tiempo especificado",
  "tooltip.bpm":"Beats Por Minuto de la canción.\nEl metrónomo no funcionará si el valor es 0",
  "tooltip.calculateBpm":"Haz click al ritmo de la música\npara calcular el BPM",
  "tooltip.editComments":"Editar comentarios de la canción",
  "tooltip.editLinks":"Editar enlaces de la canción",
  "tooltip.findVideos":"Encontrar vídeos",

  "webShare.title":"GuitarNotebook.com",
  "webShare.text":"Échale un vistazo a esta tablatura de guitarra de guitarnotebook.com! 🎸\n",
}

window.dictionary["gl"] = {
  "header.home":"inicio",
  "header.songList":"cancións",
  "header.songViewer":"visor",
  "header.songEditor":"editor",
  "header.about":"Acerca de",

  "homepage.songSelector":"SELECTOR DE CANCIÓNS",
  "homepage.songSelector.info":"¡Fai click sobre unha canción para visualizala!",
  "homepage.songViewer":"VISOR DE CANCIÓNS",
  "homepage.songViewer.info": "Teclas:\nZ: Reproducir acorde anterior\nX: Reproducir seguinte acorde",
  "homepage.songEditor":"EDITOR DE CANCIÓNS",
  "homepage.songEditor.info":"¡Ingresa ou rexístrate de balde para gardar cancións!",
  "homepage.important":"IMPORTANTE!",
  "homepage.minimumRequirements":"Requisitos mínimos",
  "homepage.minimumRequirements.info":"Un ordenador con tarxeta de son\nGoogle Chrome Desktop v81+\nResolución de pantalla 1024x768px\nA guitarra é opcional 😁",
  "homepage.disclaimer":"Esta é una aplicación beta, polo que seguro que atoparás cousas que non funcionan como deberían. Estamos a traballar arreo para crear novas funcionalidades e para mellorar as que xa existen. Namentres, podesnos envia-las túas suxestións para facer melloras na aplicación. Podes contactar connosco a través de este email",

  "global.language":"Idioma",
  "global.settings":"Axustes",

  "settings.soundEnabled":"Activar son",
  "settings.saveDataBandwidthEnabled":"Activar aforro de datos",
  "settings.metronomeEnabled":"Activar metrónomo",
  "settings.metronomeSoundEnabled":"Activar son do metrónomo",
  "settings.metronomeOpacity":"Opacidade do metrónomo",
  "settings.metronomeSize":"Tamaño do metrónomo",
  "settings.tooltipsEnabled":"Activar info sobre ferramentas",
  "settings.autoFitEnabled":"Autoaxustar tab á ventá",
  "settings.clearCache":"Limpar caché local e restaurar axustes predeterminados",
  "settings.clear":"Limpar",
  "settings.save":"Gardar",
  "settings.saveAccountSettings":"Gardar estes axustes na miña conta",

  "global.title":"título",
  "global.performer":"intérprete",
  "global.tuning":"afinación",
  "global.capo":"capo",
  "global.originalCapo":"capo orixinal",
  "global.original":"orixinal",
  "global.custom":"custom",
  "global.duration":"duración",
  "global.bpm":"bpm",
  "global.links":"ligazóns",
  "global.comments":"comentarios",
  "global.tabbedBy":"tab por",
  "global.tab":"tab",
  "global.date":"data",
  "global.views":"visitas",
  "global.total":"total",
  "global.username":"usuario",

  "global.search":"Buscar",
  "global.signIn":"Ingresar",
  "global.signUp":"Rexístrate",

  "global.welcomeTo":"Benvido a",

  "global.yes":"Si",
  "global.no":"Non",
  "global.close":"Pechar",
  "global.me":"Eu",
  "global.pageNotFound":"Páxina no atopada 🙄",


  "summary.latestTabs":"Últimos Tabs",
  "summary.mostPopular":"Os mais populares",
  "summary.topUsers":"Usuarios top",

  "login.enterUsername":"Introduzce nome de usuario",
  "login.enterEmail":"Introduce email",
  "login.enterPassword":"Introduce contrasinal",
  "login.forgotPassword":"Esqueciche-lo contrasinal?",
  "login.notAMember":"Non eres membro? Rexístrate aquí",
  "login.alreadyAMember":"Xa eres membro? Ingresa aquí",
  "login.hello":"Ola",
  "login.welcomeNewUser":"grazas por te registrar!\nSe queres ve-los teus datos de rexistro podes entrar agora premendo no botón Ingresar",
  "login.bye":"Grazas por vir!",
  "login.logOut":"Pechar sesión",
  "login.loginFailed":"Erro de acceso!",
  "login.resetPassword":"Restablecer contrasinal",
  "login.resetPasswordMessage":"Por favor, introduce o email completo e o novo contrasinal para",
  "login.enterNewPassword":"Introduce novo contrasinal",
  "login.setNewPassword":"Establecer novo contrasinal",
  "login.newPasswordOk":"O novo contrasinal estableceuse correctamente! Podes ingrexar agora dende a páxina de acceso",
  "login.newPasswordKo":"Ocorréu un problema ó establece-lo novo contrasinal. Por favor, inténtao de novo máis tarde. Podes contactar connosco se o problema persiste",
  "login.forgottenPassword": "Contrasinal olvidado",
  "login.forgottenPasswordMessage": "Introduce a dirección de correo electrónico que utilizache para rexistra-la túa conta en GuitarNotebook.\nEnviarémoste un email con instrucións para crear un novo contrasinal",
  "login.forgottenPasswordNote": "Pregámoste te teñas en conta que, para evitar problemas de seguridade, se a dirección de correo electrónico non existe na nosa base de datos, non se enviará email ningún independientemente da mensaxe de confirmación",
  "login.sendEmail": "Enviar email",
  "login.passwordRestored": "Contrasinal restablecido correctamente! :)",
  "login.emailSent": "Email enviado correctamente.\nComproba a túa bandexa de entrada ;)",
  "login.emailNotValid": "Email/Token non válido. Se estás a utilizar unha ligazón antiga para restablecer-lo contrasinal, por favor xera unha nova",
  "login.sessionClosed":"Sesión pechada",

  "chordFinder.searchByFretboard":"Buscar no mastro",
  "chordFinder.searchByName":"Buscar por nome",
  "chordFinder.chordsFound":"acordes atopados!",

  "chordsUsed.chordsUsed":"acordes utilizados",
  "chordsUsed.add":"engadir",

  "editor.header.title.placeholder":"Introduce título",
  "editor.header.performer.placeholder":"Introduce intérprete",
  "editor.header.tuning.placeholder":"Introduce afinación",
  "editor.header.comments.placeholder":"Introduce comentarios",
  "editor.tab.placeholder":"Introduce acordes e letra aquí...",

  "editor.commentsEditor.placeholder":"Introduce comentarios sobre esta canción aquí...",
  "editor.linksEditor.placeholder":"Introduce unha ligazón por liña aquí...",

  "editorButton.saveSong":"Gardar canción",
  "editorButton.newSong":"Nova canción",
  "editorButton.removeBlankLines":"Eliminar liñas baleiras",

  "songsViewer.title":"título",
  "songsViewer.performer":"intérprete",
  "songsViewer.lastUpdate":"d.actualización",
  "songsViewer.tabBy":"tab por",
  "songsViewer.rating":"valoración",
  "songsViewer.bin":"papeleira",
  "songsViewer.serverConnectionError":"Houbo un problema ó conectar co servidor de cancións. Inténtao de novo mais tarde. Por favor, contacta connosco se a incidencia persiste",
  "songsViewer.chords":"acordes",
  "songsViewer.utilities":"ferramentas",

  "songList.deleteMessage":"Estás seguro de que queres borrar",
  "songList.filter.title":"Filtro de Cancións",
  "songList.filter.allSongs":"Todas as cancións",
  "songList.filter.mySongs":"As miñas cancións",
  "songList.filter.myFavorites":"As miñas cancións favoritas",
  "songList.emptyList":"Esta lista está valeira.",

  "message.saveError":"Error ó guardar! Houbo algún problema ó garda-la túa canción. Por favor contacta connosco se o problema persiste",
  "message.saveOnlyForRegisteredUsers":"Só os usuarios rexistrados poden gardar cancións. Por favor, ingresa ou regístrate gratis",
  "message.selectSongFromList":"Por favor, selecciona unha canción da lista de cancións",

  "footer.disclaimer":"Aviso legal",
  "footer.disclaimerMessage":`Esta web contén transcricións para guitarra e ukelele, que son o traballo propio dos seus transcritores e representan a súa interpretación personal das cancións. As transcricións existentes nesta web son exclusivamente para uso privado, e polo tanto prohíbese a súa reproducción, retransmisión ou o seu uso para fins comerciais.\n
    Os contidos desta web poden ser modificados ou actualizados sen previo aviso. O usuario acepta que calqueira dos servicios ofrecidos por esta web poderá ser interrompido por razóns técnicas ou económicas.\n
    Queda estritamente prohibida a reprodución, distribución, copia, difusión ou algún outro uso da información contida nesta web.`,
  "footer.contactUs":"Contáctanos",

  "tooltip.increaseFontSize":"Aumentar fonte",
  "tooltip.decreaseFontSize":"Diminuír fonte",
  "tooltip.increaseLineHeight":"Aumentar altura de liña",
  "tooltip.decreaseLineHeight":"Diminuír altura de liña",
  "tooltip.increaseColumns":"Aumentar columnas",
  "tooltip.decreaseColumns":"Diminuír columnas",
  "tooltip.maximizeTab":"Maximizar tab",
  "tooltip.increaseLetterSpacing":"Aumentar espazo entre letras",
  "tooltip.decreaseLetterSpacing":"Diminuír espazo entre letras",
  "tooltip.resetVisualSettings":"Restaurar axustes visuais",
  "tooltip.playNextChord":"Reproducir seguinte acorde",
  "tooltip.playPrevChord":"Reproducir acorde anterior",
  "tooltip.editSong":"Editar canción",
  "tooltip.duration":"Duración da canción.\nO autoscroll mostrará\na canción completa no\ntempo especificado",
  "tooltip.bpm":"Beats Por Minuto da canción.\nO metrónomo non funcionará si o valor é 0",
  "tooltip.calculateBpm":"Fai click ó ritmo da música\npara calcular os BPM",
  "tooltip.editComments":"Editar comentarios da canción",
  "tooltip.editLinks":"Editar ligazóns da canción",
  "tooltip.findVideos":"Atopar vídeos",

  "webShare.title":"GuitarNotebook.com",
  "webShare.text":"Vótalle un ollo a esta tablatura de guitarra de guitarnotebook.com! 🎸\n",
}



