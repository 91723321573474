import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import React, { ChangeEvent, ReactElement, useContext } from "react";
import styled from "styled-components";
import { i8nText } from "../../common/Util";

const StyledDiv = styled.div`
  padding-bottom: 1rem;
  background-color: rgba(24,27,31,1);
  box-shadow: 0px 12px 14px 14px rgba(24,27,31,1);
  position: relative;
  z-index: 100;
  max-width: 64rem;

  .title{
    text-align: left;
    padding: 8px;
    text-transform: uppercase;
    color: #afafaf;
    font-size: .7rem;
    font-weight: 400;
  }
  .form{
    display:flex;
    justify-content: space-evenly;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .radio{
      cursor: pointer;
      font-size: 1rem;
      border-radius: 13px;
      border:1px solid ${props => props.theme.fgColor1};
      padding: 0 .625rem;
      margin: 0 0.6rem 0.2rem 0;

      &:hover {
        color: ${props => props.theme.fgColor2};
        border: 1px solid ${props => props.theme.fgColor2};
      }

      &.active{
        background-color: ${props => props.theme.fgColor1};
        color: black;
        border: 1px solid ${props => props.theme.fgColor1};
      }

      label{
        cursor: pointer;

        input{
          display:none;
        }
      }
    }
  }
`;

export const SongsFilter = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    appContext.setSongListFilter(e.target.value);
  }

  return (
    <StyledDiv>
      <div className="title">
        {i8nText(appContext.language, "songList.filter.title")}
      </div>
      <div className="form">
        <div className={appContext.songListFilter === Config.SONGLIST_FILTER__ALL_SONGS ? "radio active":"radio"}>
          <label>
            <input
              type="radio"
              value={Config.SONGLIST_FILTER__ALL_SONGS}
              checked={appContext.songListFilter===Config.SONGLIST_FILTER__ALL_SONGS}
              onChange={onChangeHandler}
            />
            <span>{i8nText(appContext.language, "songList.filter.allSongs")}</span>
          </label>
        </div>
        <div className={appContext.songListFilter === Config.SONGLIST_FILTER__MY_SONGS ? "radio active":"radio"}>
          <label>
            <input
              type="radio"
              value={Config.SONGLIST_FILTER__MY_SONGS}
              checked={appContext.songListFilter===Config.SONGLIST_FILTER__MY_SONGS}
              onChange={onChangeHandler}
            />
            <span>{i8nText(appContext.language, "songList.filter.mySongs")}</span>
          </label>
        </div>

        <div className={appContext.songListFilter === Config.SONGLIST_FILTER__MY_FAVORITES ? "radio active":"radio"}>
          <label>
            <input
              type="radio"
              value={Config.SONGLIST_FILTER__MY_FAVORITES}
              checked={appContext.songListFilter===Config.SONGLIST_FILTER__MY_FAVORITES}
              onChange={onChangeHandler}
            />
            <span>{i8nText(appContext.language, "songList.filter.myFavorites")}</span>
          </label>
        </div>
      </div>
    </StyledDiv>
  );
}
