import { AppStateContext } from "AppStateProvider";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Chord, ChordString } from "types/common";
import { ChordFinderElement } from "./ChordFinderElement";

const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  overflow: hidden;

  .top-row{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .2rem;

    .title{
      display: inline-block;
      margin-left: .5rem;
      font-size: .75rem;
      font-weight: 700;
      text-transform: uppercase;
      color: ${props => props.theme.fgColor1};
    }
    .total{
      display: inline-block;
      font-size: .75rem;
      margin-left: .7rem;
      color: ${props => props.theme.fgColor1};
    }
  }
  .chords{
    display: flex;
    height: 64px;
    @media (max-width: ${props => props.theme.bp.mini}){
      height: 32px;
    }
  }
`;


type Props = {
  stringState: ChordString;
  changeChordHandler: (chord: ChordString) => void;
}


export const ChordFinder = (props:Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [filteredChords, setFilteredChords] = useState<Array<Chord>>([]);

  useEffect(() => {
    if (!isNil(appContext.chordList)){
      //At least 2 strings have to be selected in order to apply the filtering
      let searchChords = true;
      let nullCounter = 0;
      for (let i = 0; i <= 5; i++) {
        if (props.stringState[i] === null) nullCounter++;
        if (nullCounter > 4){
          searchChords = false;
          setFilteredChords([]);
          console.log("Search stopped!");
          break;
        }
      }
      if (searchChords){
        const chordFoundArray: Array<Chord> = [];
        appContext.chordList.forEach((chord: Chord) => {
          let chordFound = true;
          for (let i = 0; i <= 5; i++) {
            if (props.stringState[i] !== null && chord.string[i] !== props.stringState[i]){
              chordFound = false;
              break;
            }
          }
          if (chordFound){
            chordFoundArray.push(chord);
          }
        });
        setFilteredChords(chordFoundArray);
      }
    }
  }, [props.stringState, appContext.chordList]);

  return(
    <StyledDiv>
      <div className="top-row">
        <div className="title">{i8nText(appContext.language, "chordFinder.searchByFretboard")}</div>
        <div className="total">
          {`${filteredChords.length} ${i8nText(appContext.language, "chordFinder.chordsFound")}`}
        </div>
      </div>
      <div className="chords">
        {filteredChords.map((chord, index) => (
          <ChordFinderElement
            key={index}
            chordFileName={chord.fileName}
            chordName={chord.name}
            chordNotes={chord.string}
            transposition={appContext.transposition}
          />
        ))}
      </div>
    </StyledDiv>
  );
}
