import { AppStateContext } from "AppStateProvider";
import { playChord } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import cursorFingerSpeaker from "resources/cursors/cursor_finger_speaker_gy.cur";
import styled from "styled-components";
import { Chord } from "types/common";

const StyledDiv = styled.div<Props>`
  background-color: ${props => (props.selected === true ? "#555" : "transparent")};
  border: 1px solid ${props => (props.selected === true ? "lightgray" : "transparent")};
  cursor: url(${cursorFingerSpeaker}), auto;
  img {
    opacity: 0.9;
    display: block;
    width: 64px;
    height: 64px;
    border-top: 5px solid ${props => (props.selected === true ? "#555" : "transparent")};
  }
`;

type Props = {
  chord: Chord;
  transposition: number;
  selected: boolean;
};

export const ViewerUsedChordsElement = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const onClickHandler = () => {
    if (appContext.soundEnabled) playChord(props.chord.string, props.transposition);
  };

  return (
    <StyledDiv {...props} className="viewer-used-chords-element" onClick={onClickHandler}>
      <img
        alt={props.chord.fileName}
        src={`${process.env.PUBLIC_URL}/img/chords/${props.chord.name[0]}/chord_tw_${props.chord.fileName}.webp`}
      />
    </StyledDiv>
  );
};
