export enum Environment {
  DEVELOPMENT = "dev",
  PRODUCTION = "prod",
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
}

export type HttpHeaders = {
  "Content-Type"?: string;
  Authorization?: string;
};

export type HttpRequest = {
  url: string;
  method: HttpMethod;
  timeout: number;
  headers: HttpHeaders;
};

export type FavoriteRequest = HttpRequest & {
  data: {
    userId: number;
    title: string;
    performer: string;
    creatorId: number;
  };
};

export type User = {
  id: number;
  username: string;
  email: string;
  roles: Array<string>;
  accessToken: string;
  tokenType: string;
};

export type SongInfo = {
  performer: string;
  title: string;
  lastModified: string;
  userId: number;
  username: string;
  favorite: boolean;
};

export type FullSongInfo = {
  title: string;
  performer: string;
  userId: number;
  lovers?: Array<Partial<User>>;
  tuning: string;
  capo: number;
  originalCapo: number;
  comments: string;
  links: string;
  tabHtml?: string;
  username: string;
  favorite: boolean;
  tab: string;
  lastModified: string;
  duration: number;
  bpm: number;
};

export type EditedSong = {
  title: string;
  performer: string;
  tuning: string;
  capo: number;
  originalCapo: number;
  comments: string;
  duration: number;
  bpm: number;
  links: string;
  tab: string;
};

export type EditedSongWithSetters = EditedSong & {
  setTitle: (val: string) => void;
  setPerformer: (val: string) => void;
  setTuning: (val: string) => void;
  setCapo: (val: number) => void;
  setOriginalCapo: (val: number) => void;
  setComments: (val: string) => void;
  setDuration: (val: number) => void;
  setBpm: (val: number) => void;
  setLinks: (val: string) => void;
  setTab: (val: string) => void;
};

export type EditorErrors = {
  titleError: boolean;
  performerError: boolean;
  tuningError: boolean;
  capoError: boolean;
  originalCapoError: boolean;
};

export type EditorErrorsWithSetters = EditorErrors & {
  setTitleError: (val: boolean) => void;
  setPerformerError: (val: boolean) => void;
  setTuningError: (val: boolean) => void;
  setCapoError: (val: boolean) => void;
  setOriginalCapoError: (val: boolean) => void;
};

export type FullSongInfoWithSetters = FullSongInfo & {
  setBpm: (val: number) => void;
  setCapo: (val: number) => void;
  setCapoError: (val: boolean) => void;
  setComments: (val: string) => void;
  setDuration: (val: number) => void;
  setLinks: (val: string) => void;
  setOriginalCapo: (val: number) => void;
  setOriginalCapoError: (val: boolean) => void;
  setPerformer: (val: string) => void;
  setPerformerError: (val: boolean) => void;
  setTitle: (val: string) => void;
  setTab: (val: string) => void;
  setTitleError: (val: boolean) => void;
  setTuning: (val: string) => void;
  setTuningError: (val: boolean) => void;
};

export type SongList = Array<SongInfo>;

export type ChordString = Array<number | null>;

export type Chord = {
  name: string;
  secureName: string;
  fileName: string;
  priority: number;
  string: ChordString;
};

export enum LineType {
  LINE_TYPE__LYRICS = "LYRICS",
  LINE_TYPE__CHORDS = "CHORDS",
  LINE_TYPE__SECTION = "SECTION",
  LINE_TYPE__LINEBREAK = "LINEBREAK",
}

export const URL_SEARCH_PARAM_TITLE = "title";
export const URL_SEARCH_PARAM_PERFORMER = "performer";
export const URL_SEARCH_PARAM_USER_ID = "userid";
export const URL_SEARCH_PARAM_PLAYLIST_ID = "playlist";