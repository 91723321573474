// Allow custom HTML attributes https://stackoverflow.com/questions/46215614/property-does-not-exist-on-type-detailedhtmlprops-htmldivelement-with-react

import { AppStateContext } from "AppStateProvider";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ChangeEvent, ReactElement, useContext, useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import magnifierIcon from "resources/icons/icon_magnifier.svg";
import pencilIcon from "resources/icons/icon_pencil.svg";
import styled from "styled-components";
import { EditedSongWithSetters } from "types/common";
import { BpmCalculatorButton } from "./BpmCalculatorButton";
import { TextEditorButton } from "./TextEditorButton";

const StyledDiv = styled.div`
  display: flex;
  overflow: hidden;
  > .column {
    width: 100%;

    /* Chrome, Safari, Edge, Opera */
    input.hide-arrows::-webkit-outer-spin-button,
    input.hide-arrows::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"].hide-arrows {
      -moz-appearance: textfield;
    }

    .row {
      display: flex;
      justify-content: flex-start;

      &.fill {
        width: 100%;
        max-width: 100%;
        .field {
          width: 100%;
          input[type="text"] {
            flex-grow: 1;
            text-transform: capitalize;
          }
        }
      }

      &.flex {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        /* .field{
          width:100%;
          input[type=text]{
            flex-grow:1;
          }
        } */
      }

      .field {
        margin-bottom: 0.5rem;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tooltip {
          white-space: pre-line;
          text-align: center;
        }

        label,
        .label {
          font-size: 0.6rem;
          display: inline-block;
          color: gray;
          text-transform: uppercase;
          width: 3.5rem;
          text-align: right;
          margin-right: 0.3rem;
          margin-left: 0;
          flex-grow: 0;
          cursor: default;

          &[for="capo"],
          &[for="originalCapo"] {
            margin-left: 1rem;
            width: auto;
          }
          &[for="bpm"] {
            margin-left: 1rem;
            width: auto;
          }
        }

        &.comments,
        &.links {
          .label {
            margin-left: 1rem;
            width: auto;
          }
          .buttons {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;

            background-color: ${props => props.theme.bgColor1};
            border-radius: 1rem;
          }
        }

        input {
          font-family: "Roboto Mono", monospace, sans-serif;
          color: black;
          font-size: 1rem;
          border-radius: 1rem;
          border: 1px solid darkgray;
          padding-left: 0.5rem;
          line-height: 1.4;
        }
        input[type="number"][name="min"],
        input[type="number"][name="sec"] {
          width: 1.2rem;
          text-align: right;
        }
        input[type="number"][name="sec"] {
          padding-right: 0.5rem;
        }
        input[type="number"][name="capo"],
        input[type="number"][name="originalCapo"] {
          width: 2.2rem;
          text-align: center;
        }
        input[type="number"][name="bpm"] {
          width: 3rem;
        }
        input[type="text"][name="comments"],
        input[type="text"][name="links"] {
          width: 100%;
        }

        .timeInput {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          font-family: "Roboto Mono", monospace, sans-serif;
          color: black;
          border-radius: 1rem;
          border: 1px solid darkgray;
          background-color: ${props => props.theme.fgColor1};
          overflow: hidden;
          height: 1.5rem;

          input {
            border-radius: initial;
            border: none;
            background-color: transparent;
            text-align: right;
            padding: 0;
          }
          input[type="number"][name="min"] {
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
`;
const YoutubeSearchDiv = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  background-color: ${props => props.theme.bgColor1};
  background-size: auto;
  background-repeat: no-repeat;
  border-radius: 1rem;
  border: 2px solid ${props => props.theme.bgColor1};
  a {
    img {
      display: block;
    }
    div {
      width: 100%;
      height: 100%;
      mask: url(${magnifierIcon});
      mask-size: cover;
      background: ${props => props.theme.fgColor1};

      &:hover,
      &:focus {
        background: ${props => props.theme.fgColor2};
      }
    }
  }
`;

type Props = EditedSongWithSetters & {
  titleError: boolean;
  performerError: boolean;
  tuningError: boolean;
  capoError: boolean;
  originalCapoError: boolean;

  setTitleError: (val: boolean) => void;
  setPerformerError: (val: boolean) => void;
  setTuningError: (val: boolean) => void;
  setCapoError: (val: boolean) => void;
  setOriginalCapoError: (val: boolean) => void;
}


export const SongHeaderEditor = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const title = useRef<HTMLInputElement>(null);
  const performer = useRef<HTMLInputElement>(null);
  const tuning = useRef<HTMLInputElement>(null);
  const capo = useRef<HTMLInputElement>(null);
  const originalCapo = useRef<HTMLInputElement>(null);
  const min = useRef<HTMLInputElement>(null);
  const sec = useRef<HTMLInputElement>(null);
  const bpm = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (title.current != null) title.current.value = props.title;
  }, [props.title]);
  useEffect(() => {
    if (performer.current != null) performer.current.value = props.performer;
  }, [props.performer]);
  useEffect(() => {
    if (tuning.current != null) tuning.current.value = props.tuning;
  }, [props.tuning]);
  useEffect(() => {
    if (capo.current != null) capo.current.value = props.capo.toString();
  }, [props.capo]);
  useEffect(() => {
    if (originalCapo.current != null) originalCapo.current.value = props.originalCapo.toString();
  }, [props.originalCapo]);
  useEffect(() => {
    if (bpm.current != null) bpm.current.value = props.bpm.toString();
  }, [props.bpm]);
  useEffect(() => {
    if (min.current != null) min.current.value = Math.floor(props.duration / 60).toString();
  }, [props.duration]);
  useEffect(() => {
    if (sec.current != null)
      sec.current.value = (props.duration % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }, [props.duration]);

  const onChangeStringHandler = (
    e: ChangeEvent<HTMLInputElement>,
    fun: (val: string) => void,
    funError?: (val: boolean) => void,
  ) => {
    fun(e.target.value);
    if (funError) {
      funError(false);
    }
    if (e.target.name === "capo") {
      appContext.setTransposition(Number(e.target.value));
    }
  };

  const onChangeNumberHandler = (
    e: ChangeEvent<HTMLInputElement>,
    fun: (val: number) => void,
    funError?: (val: boolean) => void,
  ) => {
    fun(Number(e.target.value));
    if (funError) {
      funError(false);
    }
    if (e.target.name === "capo") {
      appContext.setTransposition(Number(e.target.value));
    }
  };

  const onChangeMinSecHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "min") {
      props.setDuration(Number(e.target.value) * 60 + Number(sec.current?.value));
    } else if (e.target.name === "sec") {
      props.setDuration(Number(min.current?.value) * 60 + Number(e.target.value));
    }
  };

  const getYoutubeSearchLink = () => {
    const url = "https://www.youtube.com/results?search_query=guitar+cover+";
    const title = props.title.split(" ").join("+");
    const performer = props.performer.split(" ").join("+");
    return url + title + (props.performer.trim() === "" ? "" : "+" + performer);
  };

  return (
    <StyledDiv>
      <div className="column">
        <div className="row fill">
          <div className="field">
            <label htmlFor="title">{i8nText(appContext.language, "global.title")}</label>
            <input
              type="text"
              name="title"
              id="title"
              ref={title}
              //autoFocus={true}
              tabIndex={1}
              placeholder={i8nText(appContext.language, "editor.header.title.placeholder")}
              size={Number(Config.SONG_EDITOR_PAGE__INPUT_SIZE)}
              spellCheck={false}
              defaultValue={props.title}
              onChange={e => onChangeStringHandler(e, props.setTitle, props.setTitleError)}
              data-error={props.titleError}
            />
          </div>
        </div>
        <div className="row fill">
          <div className="field">
            <label htmlFor="performer">{i8nText(appContext.language, "global.performer")}</label>
            <input
              type="text"
              name="performer"
              id="performer"
              ref={performer}
              tabIndex={2}
              placeholder={i8nText(appContext.language, "editor.header.performer.placeholder")}
              size={Number(Config.SONG_EDITOR_PAGE__INPUT_SIZE)}
              spellCheck={false}
              defaultValue={props.performer}
              onChange={e => onChangeStringHandler(e, props.setPerformer, props.setPerformerError)}
              data-error={props.performerError}
            />
          </div>
        </div>
        <div className="row flex">
          <div className="field">
            <label htmlFor="tuning">{i8nText(appContext.language, "global.tuning")}</label>
            <input
              type="text"
              name="tuning"
              id="tuning"
              ref={tuning}
              tabIndex={3}
              placeholder={i8nText(appContext.language, "editor.header.tuning.placeholder")}
              size={11}
              spellCheck={false}
              defaultValue={props.tuning}
              onChange={e => onChangeStringHandler(e, props.setTuning, props.setTuningError)}
              data-error={props.tuningError}
            />
          </div>
          <div className="field">
            <label htmlFor="capo">{i8nText(appContext.language, "global.capo")}</label>
            <input
              type="number"
              name="capo"
              id="capo"
              ref={capo}
              min={-4}
              max={7}
              tabIndex={4}
              size={2}
              spellCheck={false}
              defaultValue={props.capo}
              onChange={e => onChangeNumberHandler(e, props.setCapo, props.setCapoError)}
              data-error={props.capoError}
            />
          </div>
          <div className="field">
            <label htmlFor="originalCapo">{i8nText(appContext.language, "global.originalCapo")}</label>
            <input
              type="number"
              name="originalCapo"
              id="originalCapo"
              ref={originalCapo}
              min={-4}
              max={7}
              tabIndex={4}
              size={2}
              spellCheck={false}
              defaultValue={props.originalCapo}
              onChange={e => onChangeNumberHandler(e, props.setOriginalCapo, props.setOriginalCapoError)}
              data-error={props.originalCapoError}
            />
          </div>
        </div>
        <div className="row flex">
          <div className="field">
            <div className="label">{i8nText(appContext.language, "global.duration")}</div>
            <div
              className="timeInput"
              data-for="tooltipDuration"
              data-tip
              data-tip-disable={!appContext.tooltipsEnabled}
              // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
            >
              <input
                className="hide-arrows"
                type="number"
                name="min"
                id="min"
                ref={min}
                min={0}
                max={59}
                tabIndex={5}
                spellCheck={false}
                onChange={onChangeMinSecHandler}
              />
              :
              <input
                className="hide-arrows"
                type="number"
                name="sec"
                id="sec"
                ref={sec}
                min={0}
                max={59}
                tabIndex={6}
                spellCheck={false}
                onChange={onChangeMinSecHandler}
              />
            </div>
            <ReactTooltip className="tooltip" id="tooltipDuration" type="info" effect="float">
              <span>{i8nText(appContext.language, "tooltip.duration")}</span>
            </ReactTooltip>
          </div>

          <div className="field bpm">
            <label htmlFor="bpm">{i8nText(appContext.language, "global.bpm")}</label>
            <input
              data-for="tooltipBpm"
              data-tip
              data-tip-disable={!appContext.tooltipsEnabled}
              // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
              type="number"
              name="bpm"
              id="bpm"
              ref={bpm}
              min={0}
              max={300}
              tabIndex={7}
              size={3}
              spellCheck={false}
              defaultValue={props.bpm}
              onChange={e => onChangeNumberHandler(e, props.setBpm)}
            />
            <ReactTooltip className="tooltip" id="tooltipBpm" type="info" effect="float">
              <span>{i8nText(appContext.language, "tooltip.bpm")}</span>
            </ReactTooltip>
            <BpmCalculatorButton setBpm={props.setBpm} />
          </div>

          <div className="field comments">
            <div className="label">{i8nText(appContext.language, "global.comments")}</div>
            <TextEditorButton
              data-for="tooltipEditComments"
              data-tip
              data-tip-disable={!appContext.tooltipsEnabled}
              // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
              propertyProp={props.comments}
              setter={props.setComments}
              mask={pencilIcon}
              headerTitle={i8nText(appContext.language, "global.comments")}
              placeholderProp={i8nText(appContext.language, "editor.commentsEditor.placeholder")}
            />
            <ReactTooltip className="tooltip" id="tooltipEditComments" type="info" effect="float">
              <span>{i8nText(appContext.language, "tooltip.editComments")}</span>
            </ReactTooltip>
          </div>
          <div className="field links">
            <div className="label">{i8nText(appContext.language, "global.links")}</div>
            <div className="buttons">
              <TextEditorButton
                data-for="tooltipEditLinks"
                data-tip
                data-tip-disable={!appContext.tooltipsEnabled}
                // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
                propertyProp={props.links}
                setter={props.setLinks}
                mask={pencilIcon}
                headerTitle={i8nText(appContext.language, "global.links")}
                placeholderProp={i8nText(appContext.language, "editor.linksEditor.placeholder")}
              />
              <ReactTooltip id="tooltipEditLinks" type="info" effect="float">
                <span>{i8nText(appContext.language, "tooltip.editLinks")}</span>
              </ReactTooltip>

              {props.title.trim() !== "" ? (
                <>
                  <YoutubeSearchDiv
                    data-for="tooltipFindVideos"
                    data-tip
                    data-tip-disable={!appContext.tooltipsEnabled}
                    // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
                  >
                    <a target="_blank" rel="noopener noreferrer" href={getYoutubeSearchLink()}>
                      <div />
                    </a>
                  </YoutubeSearchDiv>
                  <ReactTooltip id="tooltipFindVideos" type="info" effect="float">
                    <span>{i8nText(appContext.language, "tooltip.findVideos")}</span>
                  </ReactTooltip>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};
