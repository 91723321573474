import { AppStateContext } from "AppStateProvider";
import { MessageBox } from "components/common/MessageBox";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext, useState } from "react";
import emoji from "resources/emojis/hipster.svg";
import styled, { createGlobalStyle } from "styled-components";

const StyledFooter = styled.footer`
  position: fixed;
  bottom:0;
  right:0;
  color: lightgray;
  font-size: .8rem;

  ul{
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    li{
      margin: 0;
      padding: 0 .4rem 0 0;
      text-decoration: underline;
      cursor: pointer;
      a{
        color: lightgray;
      }
    }
  }
`;

const DisclaimerMessageGlobalStyle = createGlobalStyle`
  #disclaimerMessage{
    .messagebox{
      .disclaimer-message{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .messagebox-image{
          width: 6rem;
          height: 6rem;

          @media (max-width: ${props => props.theme.bp.mobile}){
            display: none;
          }

          img{
            width: 6rem;
            height: 6rem;
            display:block;
          }
        }
        .messagebox-text{
          padding-left: 1rem;
          white-space: pre-line;
          @media (max-width: ${props => props.theme.bp.mobile}){
            font-size: .8rem;
          }
        }
      }
    }
  }
`

export const AppFooter = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const [disclaimerVisible, setDisclaimerVisible] = useState(false);

  return (
    <StyledFooter>
      <ul>
        <li onClick={()=>setDisclaimerVisible(true)}>{i8nText(appContext.language, "footer.disclaimer")}</li>
        <li><a href={`mailto:${i8nText(appContext.language, "global.appEmail")}`}>{i8nText(appContext.language, "footer.contactUs")}</a></li>

        <MessageBox id="disclaimerMessage" visible={disclaimerVisible} closeButton onClose={()=>setDisclaimerVisible(false)}>
          <DisclaimerMessageGlobalStyle/>
          <div className="disclaimer-message">
            <div className="messagebox-image">
              <img alt="error" src={emoji}/>
            </div>
            <div className="messagebox-text">
              {i8nText(appContext.language, "footer.disclaimerMessage")}
            </div>
          </div>
        </MessageBox>
      </ul>
    </StyledFooter>
  );
}