import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { i8nText } from "components/common/Util";
import React, { ReactElement, useContext } from "react";
import ReactTooltip from "react-tooltip";
import icon from "resources/icons/icon_reset_view_settings.svg";

export const ResetViewerSettingsButton = (): ReactElement => {
  const appContext = useContext(AppStateContext);

  const onClickHandler = () => {
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__NUMBER_OF_COLUMNS_IN_SONG, String(2));
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_FONT_SIZE, String(16));
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_LINE_HEIGHT, String(16));
    window.localStorage.setItem(Config.LOCALSTORAGE_ITEM_NAME__VIEWER_LETTER_SPACING, String(0));

    appContext.setNumberOfColumnsInSong(2);
    appContext.setViewerFontSize(16);
    appContext.setViewerLineHeight(16);
    appContext.setViewerLetterSpacing(0);
  }

  return (
    <div className="reset-view-settings-button">
      <ButtonIconText
        data-for="tooltipResetViewSettings"
        data-tip
        data-tip-disable={!appContext.tooltipsEnabled}
        // data-delay-show={Config.TOOLTIP__DELAY_SHOW}
        icon={icon}
        primary
        onClick={onClickHandler}
      />
      <ReactTooltip id="tooltipResetViewSettings" type="info" effect="float">
        <span>{i8nText(appContext.language, "tooltip.resetVisualSettings")}</span>
      </ReactTooltip>
    </div>
  );
}