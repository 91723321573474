import React, { ReactElement } from "react";
import styled from "styled-components";
import { ButtonIconText } from "./ButtonIconText";

const StyledDiv = styled.div`
  width: 100%;
  label{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      text-align: left;
    }
  }
`;

type Props = {
  buttonText: string;
  text: string;
  action: () => void;
}

export const SettingsButton = (props: Props): ReactElement => {
  return (
    <StyledDiv>
      <label>
        <span>{props.text}</span>
        <ButtonIconText text={props.buttonText} onClick={props.action}/>
      </label>
    </StyledDiv>
  );
}
