import { AppStateContext } from "AppStateProvider";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import metronomeBase from "resources/images/metronome-mini-fit-base.webp";
import metronomeTop from "resources/images/metronome-mini-fit-top.webp";
import metronomeClick from "resources/sounds/metronome.mp3";
import styled, { keyframes } from "styled-components";

//Pure CSS metronome: https://codepen.io/oaviv/pen/EydZOq
const metronome = keyframes`
  0%, 100% {
    transform: rotate(35deg);
  }
  50% {
    transform: rotate(-35deg);
  }
`;

const SCREEN_UPDATE_IN_MS = 50;

const Metronome = styled.div<Props>`
  position: fixed;

  right: 0.2rem;
  top: calc(50vh - 100px);
  width: 125px;
  height: 201px;

  transform: scale(${props => props.metronomeSize}); // Use this parameter to control overall scale of the metronome
  opacity: ${props => props.metronomeOpacity}; // Use this parameter to control overall opacity of the metronome

  .metronome {
    display: flex;
    flex-direction: column;

    .base,
    .top {
      img {
        height: auto;
        display: block;
      }
    }
    .base {
      width: 125px;
      position: relative;
      z-index: 1;

      &:after {
        content: "";
        background: radial-gradient(rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0));
        border-radius: 40%;
        z-index: -1;
        width: 160px;
        display: block;
        height: 20px;
        position: absolute;
        bottom: -8px;
        left: -16px;
        filter: blur(4px);
      }
    }
    .top {
      width: 99px;
      position: relative;
      left: 13px;
    }

    .stick {
      position: absolute;
      top: 30px;
      left: 49%;
      width: 2%;
      height: 60%;
      background: red;
      animation: ${props => (props.active ? metronome : "none")} ${props => props.speed * 2}ms ease-in-out infinite;
      transform-origin: 50% 100%;
      &:after {
        content: "";
        display: block;
        width: 400%;
        height: 15%;
        background: red;
        position: absolute;
        left: -150%;
        top: 15%;
        clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 15% 100%);
      }
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      polygon {
        cursor: pointer;
      }
    }
  }
`;

// const blink = keyframes`
//   0%   {opacity:0}
//   1%   {opacity:1}
//   10%  {opacity:1}
//   90%  {opacity:0}
//   100% {opacity:0}
// `;

// const Blinker = styled.div`
//   position:fixed;
//   pointer-events: none;
//   opacity:0;
//   animation:${blink} ${props => props.speed}ms infinite;
//   ${props => props.active === true ? "":"display:none; animation-play-state: paused;"};

//   background-color: #cee054;
//   background-color: red;
//   bottom: 1rem;
//   right: 1rem;
//   width: 3rem;
//   height: 3rem;
//   border-radius: 1.5rem;
// `;

type Props = {
  metronomeSize: number;
  metronomeOpacity: number;
  active: boolean;
  speed: number;
  duration: number;
  setMetronomeActive: (val: boolean) => void;
};

export const BpmPlayer = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [userTouchMoving, setUserTouchMoving] = useState(false);
  //Manage inertia/momentum scroll
  const [inertiaScrollActive, setInertiaScrollActive] = useState(false);

  useEffect(() => {
    const touchMoveHandler = () => {
      // if (window.environment === Environment.DEVELOPMENT) console.log("Touch moved")
      setUserTouchMoving(true);
      setInertiaScrollActive(true);
    };

    const touchEndHandler = () => {
      // if (window.environment === Environment.DEVELOPMENT) console.log("Touch finished")
      setUserTouchMoving(false);
    };

    const scrollHandler = () => {
      // if (window.environment === Environment.DEVELOPMENT) console.log("Scroll")
      // Clear our timeout throughout the scroll
      window.clearTimeout(window.isScrolling);

      // Set a timeout to run after scrolling ends
      window.isScrolling = setTimeout(function () {
        // Run the callback
        // if (window.environment === Environment.DEVELOPMENT) console.log("Scroll finished")
        setInertiaScrollActive(false);
      }, 100);
    };

    const wheelHandler = () => {
      // if (window.environment === Environment.DEVELOPMENT) console.log("Wheel used")
      if (document.documentElement.scrollTop > 0) {
        setInertiaScrollActive(true);
      }
    };

    window.addEventListener("touchmove", touchMoveHandler);
    window.addEventListener("touchend", touchEndHandler);
    window.addEventListener("scroll", scrollHandler, false);
    document.addEventListener("wheel", wheelHandler);

    return () => {
      window.removeEventListener("touchmove", touchMoveHandler);
      window.removeEventListener("touchend", touchEndHandler);
      window.removeEventListener("scroll", scrollHandler, false);
      document.removeEventListener("wheel", wheelHandler);
    };
  }, []);

  //Scroll functionality
  useEffect(() => {
    let myInterval: ReturnType<typeof setTimeout>;
    if (props.duration > 0) {
      const songDurationInSecs = props.duration;
      if (document.documentElement.scrollTop === 0) {
        setInertiaScrollActive(false);
      }
      if (props.active && !userTouchMoving && !inertiaScrollActive) {
        const scrollTotal = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const stepOffset = (scrollTotal / songDurationInSecs) * (SCREEN_UPDATE_IN_MS / 1000);
        let calculatedScrollTop = document.documentElement.scrollTop;

        myInterval = setInterval(() => {
          if (appContext.metronomeEnabled) {
            calculatedScrollTop += stepOffset;
            document.documentElement.scrollTop = calculatedScrollTop;
            if (document.documentElement.scrollTop >= scrollTotal) {
              clearInterval(myInterval);
            }
          } else {
            clearInterval(myInterval);
            props.setMetronomeActive(false);
          }
        }, SCREEN_UPDATE_IN_MS);
      }
    }

    return () => {
      clearInterval(myInterval);
    };
  }, [props, appContext.metronomeEnabled, userTouchMoving, inertiaScrollActive]);

  //Metronome functionality
  useEffect(() => {
    const playClick = (audio: HTMLAudioElement) => {
      if (appContext.metronomeEnabled && appContext.metronomeSoundEnabled) audio.play();
    };
    let myInterval: ReturnType<typeof setTimeout>;
    if (props.active && props.speed > 0) {
      const audio = new Audio(metronomeClick);
      audio.volume = 1;
      audio.load();
      setTimeout(() => {
        playClick(audio);
        myInterval = setInterval(() => {
          playClick(audio);
        }, props.speed);
      }, props.speed / 2);
    }
    return () => {
      clearInterval(myInterval);
    };
  }, [props.speed, props.active, appContext.song, appContext.metronomeEnabled, appContext.metronomeSoundEnabled]);

  const onClickHandler = () => {
    props.setMetronomeActive(!props.active);
  };

  return (
    <>
      {appContext.metronomeEnabled ? (
        <Metronome {...props}>
          <div className="metronome">
            <div className="top">
              <img alt="metronome" src={metronomeTop} />
            </div>
            <div className="base">
              <img alt="metronome" src={metronomeBase} />
            </div>
            <div className="stick"></div>
            <svg viewBox="0 0 12.5 20.1">
              <polygon
                points="0 20.1 4.52 0.86 6.26 0 7.92 0.8 12.5 20.1 0 20.1"
                fill="#fff"
                fillOpacity="0"
                // strokeMiterlimit="10"
                stroke="#000"
                strokeWidth=".01"
                strokeOpacity="0.1"
                onClick={onClickHandler}
              />
            </svg>
          </div>
        </Metronome>
      ) : (
        /* <Blinker {...props}></Blinker> */
        ""
      )}
    </>
  );
};
