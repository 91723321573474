import React, { ReactElement } from "react";
import styled from "styled-components";
import { GuitarFretboard } from "./GuitarFretboard";
import { MostUsedChordsSelector } from "./MostUsedChordsSelector";

const StyledDiv = styled.div`
  width: 100%;
  .separator{
    height: .8rem;
  }
`;

type Props = {
  currentChord: string,
  tab: string;
  setAddChord: (val: boolean) => void;
  setCurrentChord: (chord: string) => void;
};

export const ChordUtilityComponent = (props: Props): ReactElement => {
  return(
    <StyledDiv>
      <GuitarFretboard/>
      <div className="separator"></div>
      <MostUsedChordsSelector {...props}/>
    </StyledDiv>
  );
}
