import { AppStateContext } from "AppStateProvider";
import { ButtonIconText } from "components/common/ButtonIconText";
import { Config } from "components/common/Config";
import { ImagePreload } from "components/common/ImagePreload";
import { i8nText } from "components/common/Util";
import { HamburgerMenuButton } from "components/header/HamburgerMenuButton";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useState } from "react";
import iconSettings from "resources/icons/icon_settings.svg";
import userIcon from "resources/icons/user.svg";
import bgImage from "resources/images/guitars-bg.webp";
import styled from "styled-components";
import { User } from "types/common";
import { LogOutForm } from "./LogOutForm";
import { SignInForm } from "./SignInForm";
import { SignUpForm } from "./SignUpForm";

const WrapperDiv = styled.div`
  .desktop {
    display: block;
    @media (max-width: ${props => props.theme.bp.mobile}) {
      display: none;
    }
  }

  .mobile {
    display: none;
    @media (max-width: ${props => props.theme.bp.mobile}) {
      display: block;
      margin-right: 0.5rem;
    }
  }
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.fgColor1};

  .user-image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.3rem;
    cursor: pointer;
    border: 1px solid ${props => props.theme.bgColor2};
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 100%;

      &.unregistered {
        filter: grayscale();
      }
    }
    .border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 1.3rem;
      border: 1px solid ${props => props.theme.fgColor1};
      transition: all 0.3s ease-out;

      &:hover {
        border: 3px solid ${props => props.theme.fgColor1};
      }
    }
  }
`;

const BackgroundShader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  background: #000;
  opacity: 0;
`;

const UserMenu = styled.div`
  position: fixed;
  z-index: 100;
  top: 3rem;
  right: -20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.fgColor2};
  padding: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  color: ${props => props.theme.fgColor1};
  box-shadow: rgb(0, 0, 0) 0px 5px 20px -10px;
  transition: all 0.3s ease-in-out;

  &.visible {
    top: 3rem;
    right: 0rem;
  }
`;

const SideMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.fgColor2};
  margin-top: 3rem;
  height: 100vh;
  text-align: center;
  z-index: 20;
  box-shadow: rgb(0, 0, 0) 0px 5px 20px -10px;
  transform: translateX(100%);
  transition: all 0.3s ease-in-out;

  &.visible {
    transform: translateX(0%);
  }

  .side-menu__items {
    overflow: auto;
  }

  ul {
    list-style-type: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0px;
    margin: 1rem;
  }
`;

type Props = {
  user: User | undefined;
};

export const UserInfo = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [signInOptionClicked, setSignInOptionClicked] = useState(false);
  const [signUpOptionClicked, setSignUpOptionClicked] = useState(false);
  const [logoutOptionClicked, setLogoutOptionClicked] = useState(false);
  const [userMenuVisible, setUserMenuVisible] = useState(false);
  const [sideMenuVisible, setSideMenuVisible] = useState(false);

  const showSignInForm = () => {
    setSignInOptionClicked(true);
    setSignUpOptionClicked(false);
    setUserMenuVisible(false);
    setSideMenuVisible(false);
  };
  const showSignUpForm = () => {
    setSignInOptionClicked(false);
    setSignUpOptionClicked(true);
    setUserMenuVisible(false);
    setSideMenuVisible(false);
  };
  const showUserMenu = () => {
    setUserMenuVisible(!userMenuVisible);
  };

  const goToSettingsPage = () => {
    window.location.href = Config.PAGE_ROUTE__SETTINGS;
  };

  return (
    <WrapperDiv>
      <ImagePreload src={bgImage} />

      <div className="desktop">
        {isNil(props.user) ? (
          <StyledDiv className="user-info">
            <div className="user-image" onClick={showUserMenu}>
              <img className="unregistered" alt="user" src={userIcon} />
              <div className="border"></div>
            </div>
            <ButtonIconText icon={iconSettings} onClick={goToSettingsPage} />
          </StyledDiv>
        ) : (
          <StyledDiv>
            <div className="user-image" onClick={showUserMenu}>
              <img alt="user" src={userIcon} />
              <div className="border"></div>
            </div>
            <ButtonIconText icon={iconSettings} onClick={goToSettingsPage} />
          </StyledDiv>
        )}

        {userMenuVisible ? <BackgroundShader onClick={() => setUserMenuVisible(false)} /> : ""}
        {sideMenuVisible ? <BackgroundShader onClick={() => setSideMenuVisible(false)} /> : ""}

        <UserMenu className={userMenuVisible ? "visible" : ""}>
          {isNil(props.user) ? (
            <>
              <ButtonIconText text={i8nText(appContext.language, "global.signIn")} onClick={showSignInForm} />
              <ButtonIconText text={i8nText(appContext.language, "global.signUp")} onClick={showSignUpForm} />
            </>
          ) : (
            <>
              <span className="user-info__text">
                {i8nText(appContext.language, "login.hello")} {props.user?.username}!
              </span>
              <ButtonIconText
                text={i8nText(appContext.language, "login.logOut")}
                onClick={() => setLogoutOptionClicked(true)}
              />
            </>
          )}
        </UserMenu>
      </div>

      <div className="mobile">
        <HamburgerMenuButton checked={sideMenuVisible} setChecked={setSideMenuVisible} />
        <SideMenu {...props} className={sideMenuVisible ? "visible" : ""}>
          <div className="side-menu__items">
            <ul>
              {isNil(props.user) ? (
                <>
                  <li>
                    <ButtonIconText text={i8nText(appContext.language, "global.signIn")} onClick={showSignInForm} />
                  </li>
                  <li>
                    <ButtonIconText text={i8nText(appContext.language, "global.signUp")} onClick={showSignUpForm} />
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <span className="user-info__text">
                      {i8nText(appContext.language, "login.hello")} {props.user?.username}!
                    </span>
                  </li>
                  <li>
                    <ButtonIconText
                      text={i8nText(appContext.language, "login.logOut")}
                      onClick={() => setLogoutOptionClicked(true)}
                    />
                  </li>
                </>
              )}

              <li>
                <ButtonIconText text={i8nText(appContext.language, "global.settings")} onClick={goToSettingsPage} />
              </li>
            </ul>
          </div>
        </SideMenu>
        {userMenuVisible ? <BackgroundShader onClick={() => setUserMenuVisible(false)} /> : ""}
        {sideMenuVisible ? <BackgroundShader onClick={() => setSideMenuVisible(false)} /> : ""}
      </div>

      {signInOptionClicked ? (
        <SignInForm setSignInOptionClicked={setSignInOptionClicked} setSignUpOptionClicked={setSignUpOptionClicked} />
      ) : (
        ""
      )}
      {signUpOptionClicked ? (
        <SignUpForm setSignUpOptionClicked={setSignUpOptionClicked} setSignInOptionClicked={setSignInOptionClicked} />
      ) : (
        ""
      )}
      {logoutOptionClicked ? (
        <LogOutForm
          setLogoutOptionClicked={setLogoutOptionClicked}
          setSideMenuVisible={setSideMenuVisible}
          setUserMenuVisible={setUserMenuVisible}
        />
      ) : (
        ""
      )}
    </WrapperDiv>
  );
};
