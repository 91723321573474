import { AppStateContext } from "AppStateProvider";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext } from "react";
import emoji from "resources/emojis/dead.svg";
import styled, { createGlobalStyle } from "styled-components";
import { SongList } from "types/common";
import { MessageBox } from "../../common/MessageBox";
import { SongsTableRow } from "./SongsTableRow";
// import wastebinOpenIcon from 'resources/icons/icon_waste_bin_open.svg';
// import wastebinClosedIcon from 'resources/icons/icon_waste_bin_closed.svg';
// import heartEmpty from 'resources/icons/heart_empty.svg';

//http://jsfiddle.net/maruxa1j/

const ServerErrorMessageGlobalStyle = createGlobalStyle`
  #serverErrorMessage{
    .messagebox{
      .server-error-message{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .messagebox-image{
          width: 6rem;
          height: 6rem;
          img{
            width: 6rem;
            height: 6rem;
            display:block;
          }
        }
        .messagebox-text{
          padding-left: 1rem;
        }
      }
    }
  }
`;

const EmptyMsgDiv = styled.div`
  font-size: 2.5rem;
  width: 100%;
  font-weight: 700;
  opacity: 0;
  transition: opacity 0.5s ease-out;

  &.visible {
    opacity: 1;
  }
`;

const StyledDiv = styled.div`
  margin: 0.5rem 0.5rem 1rem 0;
  opacity: 0;
  transition: opacity 0.5s ease-out;

  @media (max-width: ${props => props.theme.bp.mobile}) {
    margin-top: 0;
    margin-right: 0;
  }

  &.visible {
    opacity: 1;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 64rem;

    thead {
      tr {
        th {
          text-align: left;

          text-transform: uppercase;
          color: #afafaf;
          font-size: 0.7rem;
          font-weight: 400;
          white-space: nowrap;

          &.absorbing-column {
            width: 50%;
          }

          &.performer {
            padding-left: 0.625rem;
            min-width: 10rem;
            @media (max-width: ${props => props.theme.bp.mobile}) {
              display: none;
            }
          }
          &.title {
            padding-left: 0.625rem;
            min-width: 15rem;
            @media (max-width: ${props => props.theme.bp.mobile}) {
              display: none;
            }
          }
          &.last-update {
            text-align: center;
            @media (max-width: ${props => props.theme.bp.mobile}) {
              display: none;
            }
          }
          &.username {
            text-align: center;
            min-width: 10rem;
            @media (max-width: ${props => props.theme.bp.mobile}) {
              display: none;
            }
          }
          &.rating {
            text-align: center;
            @media (max-width: ${props => props.theme.bp.mobile}) {
              display: none;
            }
          }
          &.bin {
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            text-align: center;
            width: 15px;
            height: 20px;

            img {
              filter: grayscale(1);
              width: 100%;
              height: 100%;
              display: block;
            }
            @media (max-width: ${props => props.theme.bp.mobile}) {
              display: none;
            }
          }
          &.mix {
            display: none;
            @media (max-width: ${props => props.theme.bp.mobile}) {
              display: none;
            }
          }
        }
      }
    }

    /* tbody{
      @media (max-width: ${props => props.theme.bp.mobile}){
        tr:first-child{
          border-top: none;
        }
      }
    } */
  }
`;

type Props = {
  loading: boolean;
  serverError: boolean;
  songList: SongList;
  setSongList: (songList: SongList) => void;
  currentSongHandler?: () => void;
};

export const SongsTable = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);

  return props.serverError ? (
    <MessageBox id="serverErrorMessage" visible={!props.loading}>
      <ServerErrorMessageGlobalStyle />
      <div className="server-error-message">
        <div className="messagebox-image">
          <img alt="error" src={emoji} />
        </div>
        <div className="messagebox-text">{i8nText(appContext.language, "songsViewer.serverConnectionError")}</div>
      </div>
    </MessageBox>
  ) : props.songList.length === 0 ? (
    <EmptyMsgDiv className={props.loading ? "" : "visible"}>
      {i8nText(appContext.language, "songList.emptyList")}
    </EmptyMsgDiv>
  ) : (
    <StyledDiv className={props.loading ? "" : "visible"}>
      <table>
        <thead>
          <tr>
            {isNil(appContext.user) ? (
              <></>
            ) : (
              <th className="favorite">{/* <img alt="heart" src={heartEmpty}/> */}</th>
            )}
            <th className="performer absorbing-column">{i8nText(appContext.language, "songsViewer.performer")}</th>
            <th className="title absorbing-column">{i8nText(appContext.language, "songsViewer.title")}</th>
            <th className="last-update">{i8nText(appContext.language, "songsViewer.lastUpdate")}</th>
            <th className="username">{i8nText(appContext.language, "songsViewer.tabBy")}</th>
            <th className="rating">{i8nText(appContext.language, "songsViewer.rating")}</th>
            <th className="mix">Mix</th>
            {isNil(appContext.user) ? (
              <></>
            ) : (
              <th className="bin">{/* <img alt="bin" src={wastebinClosedIcon}/> */}</th>
            )}
          </tr>
        </thead>
        <tbody>
          {props.songList.map((songInfo, index) => (
            <SongsTableRow
              key={index}
              songInfo={songInfo}
              //currentSongHandler={props.currentSongHandler}
              songList={props.songList}
              setSongList={props.setSongList}
            />
          ))}
        </tbody>
      </table>
    </StyledDiv>
  );
};
