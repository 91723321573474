import { AppStateContext } from "AppStateProvider";
import { i8nText } from "components/common/Util";
import { isNil } from "lodash";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Chord } from "types/common";
import { ViewerUsedChordsElement } from "./ViewerUsedChordsElement";

const StyledDiv = styled.div`
  flex-grow: 1;
  .title {
    font-size: 0.6rem;
    display: inline-block;
    color: gray;
    text-transform: uppercase;
    margin-left: 0.6rem;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
  }
`;

type Props = {
  usedChords: Array<string>;
  transposition: number;
  currentChordName: string;
};

export const ViewerUsedChords = (props: Props): ReactElement => {
  const appContext = useContext(AppStateContext);
  const [usedChordArr, setUsedChordArr] = useState<Array<Chord>>([]);

  useEffect(() => {
    const myArray: Array<Chord> = [];
    props.usedChords.map(chord => {
      if (!isNil(window.chordMap)) {
        const chordInfo: Chord = window.chordMap.get(chord);
        if (!isNil(chordInfo)) {
          myArray.push(chordInfo);
        }
      }
    });

    setUsedChordArr(myArray);
  }, [props.usedChords]);

  return (
    <StyledDiv>
      <div className="title">{i8nText(appContext.language, "songsViewer.chords")}</div>
      <div className="container">
        {usedChordArr.map((chord, index) => (
          <ViewerUsedChordsElement
            key={index}
            chord={chord}
            transposition={props.transposition}
            selected={props.currentChordName === chord.name}
          />
        ))}
      </div>
    </StyledDiv>
  );
};
